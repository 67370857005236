import React, { useEffect } from "react";
import { Col, Row, Card, CardBody, Button } from "reactstrap";
import { ArrowLeftCircle, ArrowRightCircle } from "react-feather"; // Importa le icone che vuoi utilizzare
import { useNavigate, useParams } from "react-router-dom";
import RightSidebar from "../../commonLayout/RightSideBar";
import P2 from "../../../CommonElements/Headings/P2Element";
import P6 from "../../../CommonElements/Headings/P6Element";
import { useDispatch, useSelector } from "react-redux";
import RenderingStep from "./RenderingStep";
import {
	getServiceAction,
	getServiceDetailsAction,
	getServicesAction,
} from "../../../features/service/serviceSlice";
import CustomStepper from "../../Application/Service/Stepper";
import HeaderPlatform from "../../../CommonElements/HeaderPlatform/";
import { resetPaymentStore } from "../../../features/payment/paymentSlice";

// Componente per le Card dei servizi

const HomePage = () => {
	const navigator = useNavigate();
	const { id } = useParams();
	const dispatch = useDispatch();
	const serviceData = useSelector((state) => state.service.services);
	const consultantData = useSelector((state) => state.service.consultant);
	const [currentStep, setCurrentStep] = React.useState(0);

	useEffect(() => {
		if (serviceData?.length) {
			dispatch(
				getServiceAction({ id_consultant: consultantData?.id, id_service: id })
			);
		}
	}, [serviceData]);

	useEffect(() => {
		dispatch(resetPaymentStore());
	}, []);

	useEffect(() => {
		dispatch(getServiceDetailsAction(id));
	}, []);

	useEffect(() => {
		if (!serviceData?.length) {
			dispatch(getServicesAction());
		}
	}, []);

	const formService = useSelector((state) => state.service.formService);
	return (
		<>
			<Row style={{ backgroundColor: "#fff" }}>
				<HeaderPlatform title='Servizi' />
			</Row>
			<Row
				style={{
					display: "flex",
					width: "100%",
					flexDirection: "row",
					backgroundColor: "#fff",
					justifyContent: "space-between",
				}}
				className='mx-2'
			>
				<Col
					lg='9'
					sm='12'
					md='12'
				>
					<Row>
						<CustomStepper
							stepsData={formService}
							currentStep={currentStep}
						/>
					</Row>
					<Row>
						<RenderingStep
							step={formService[currentStep]}
							currentStep={currentStep}
							setCurrentStep={setCurrentStep}
						/>
					</Row>
				</Col>
				<RightSidebar />
			</Row>
		</>
	);
};

export default HomePage;
