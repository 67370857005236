import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
	Elements,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";
import api from "../../../api"; // Assicurati di importare la tua API correttamente
import { ReactSVG } from "react-svg";
import paymentCard from "../../../assets/images/icon/card-tick.svg";
import P2 from "../../../CommonElements/Headings/P5Element";
import { Grid, Input, Radio } from "antd";
import { useDispatch } from "react-redux";
import { Row } from "antd";
import clickboardThik from "../../../assets/images/clipboard-tick.svg";
import clicpboardClose from "../../../assets/images/clipboard-close.svg";
import {
	createPaymentMethodAction,
	getPaymentMethodAction,
} from "../../../features/payment/paymentSlice";
import { Button } from "antd";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PaymentForm = ({
	finishAdd,
	setAdd,
	setReload,
	setShowAdd,
	showRadio,
	valueAdd,
	handleChange,
}) => {
	const stripe = useStripe();
	const elements = useElements();
	const dispatch = useDispatch();
	const { useBreakpoint } = Grid;
	const screens = useBreakpoint();
	const breakpoint = Grid.useBreakpoint();
	const [email, setEmail] = useState("");
	const [cardholderName, setCardholderName] = useState("");
	const [loading, setLoading] = useState(false);
	const [paymentMethodId, setPaymentMethodId] = useState(null);

	const handleSubmit = async (e) => {
		console.log(e, valueAdd);
		e.preventDefault();
		if (valueAdd === "card") handleAddCardPayment();
		if (valueAdd === "google_pay" || valueAdd === "apple_pay")
			handleAddPaymentMethod();
	};

	const handleAddPaymentMethod = async () => {
		const stripe = await stripePromise;
		const paymentRequest = stripe.paymentRequest({
			country: "IT",
			currency: "eur",
			total: {
				label: "Aggiungi Metodo di Pagamento",
				amount: 0, // Importo zero per aggiungere il metodo senza fare una transazione
			},
			requestPayerName: true,
			requestPayerEmail: true,
		});

		const canMakePayment = await paymentRequest.canMakePayment();
		if (canMakePayment) {
			paymentRequest.show();
			paymentRequest.on("paymentmethod", async (event) => {
				const { paymentMethod, error } = event;

				if (error) {
					console.error(
						"Errore durante la creazione del metodo di pagamento:",
						error
					);
					event.complete("fail");
				} else {
					// Ottieni l'ID del metodo di pagamento
					setPaymentMethodId(paymentMethod.id);
					console.log("ID Metodo di Pagamento:", paymentMethod.id);
					event.complete("success");

					// Potresti inviare l'ID al backend per salvarlo nel database
					// dispatch(savePaymentMethodAction(paymentMethod.id));
				}
			});
		} else {
			alert(
				"Il metodo di pagamento selezionato non è supportato su questo dispositivo."
			);
		}
	};

	useEffect(() => {
		if (!paymentMethodId) return;
		dispatch(createPaymentMethodAction({ paymentMethodId: paymentMethodId }));
		finishAdd();
	}, [paymentMethodId]);

	const handleAddCardPayment = async () => {
		if (!stripe || !elements) {
			return;
		}

		setLoading(true);
		// Ottieni i singoli elementi per la carta
		const cardElement = elements.getElement(CardNumberElement);
		const { paymentMethod, error } = await stripe.createPaymentMethod({
			type: "card",
			card: cardElement,
			billing_details: {
				name: cardholderName,
				email: email,
			},
		});

		if (error) {
			console.error(error);
			alert("Errore nella creazione del metodo di pagamento");
			setLoading(false);
			return;
		}
		setPaymentMethodId(paymentMethod.id);
	};

	return (
		<form style={{ ...formStyles, width: breakpoint.lg ? "67%" : "100%" }}>
			<div style={inputGroupStyles}>
				<div
					style={{
						display: "flex",
						alignItems: "center",
					}}
				>
					<Radio
						checked={valueAdd === "card"}
						value={"card"}
						onClick={(e) => {
							handleChange(e.target.value);
							console.log(e.target.value);
						}}
						style={{ marginLeft: -20 }}
					/>
					<ReactSVG src={paymentCard} />
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						<P2 style={{ margin: 0, marginLeft: 10 }}>Carta</P2>

						<div onClick={() => setShowAdd(false)}>
							<P2 style={{ margin: 0, marginLeft: 10 }}>X</P2>
						</div>
					</div>
				</div>
				<div style={{ marginTop: 20 }}>
					<P2 style={{ margin: 0, marginLeft: 10 }}>
						Intestatario della carta
					</P2>

					<Input
						type='text'
						disabled={valueAdd !== "card"}
						placeholder='Nome e cognome sulla carta'
						value={cardholderName}
						onChange={(e) => setCardholderName(e.target.value)}
						required
						style={inputStyles}
					/>
				</div>
			</div>
			<P2 style={{ margin: 0, marginLeft: 10 }}>Numero della carta</P2>

			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						width: "300px",
						marginLeft: "13px",
						marginTop: "6px",
						fontSize: "12px",
					}}
				>
					<CardNumberElement options={cardElementStyles} />
				</div>
				<div style={{ width: 350, display: "flex", flexDirection: "column" }}>
					<CardExpiryElement options={cardElementStyles} />
					<CardCvcElement options={cardElementStyles} />
				</div>
			</div>
			{!screens.lg && (
				<Row>
					<div
						className='d-flex justify-content-end'
						style={{
							width: "100%",
							gap: 40,
							marginTop: 20,
						}}
					>
						<Button
							style={{
								backgroundColor: "#fff",
								color: "#477673",
								border: "1px solid #8E6B00",
								borderRadius: 10,
								height: 50,
							}}
							onClick={() => setShowAdd(false)}
							icon={<ReactSVG src={clicpboardClose} />}
						>
							Annulla
						</Button>

						<Button
							style={{
								backgroundColor: "#477673",
								color: "#fff",

								height: 50,
								borderRadius: 10,
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
							}}
							onClick={handleSubmit}
							disabled={!stripe || loading}
							loading={loading}
						>
							Aggiungi <ReactSVG src={clickboardThik} />
						</Button>
					</div>
				</Row>
			)}
			{screens.lg && (
				<Row>
					<div
						className='d-flex justify-content-end'
						style={{
							width: "100%",
							gap: 40,
							position: "fixed",
							right: 20,
							bottom: 20,
						}}
					>
						<Button
							style={{
								backgroundColor: "#fff",
								color: "#477673",
								border: "1px solid #8E6B00",
								borderRadius: 10,
								height: 50,
							}}
							onClick={() => setShowAdd(false)}
							icon={<ReactSVG src={clicpboardClose} />}
						>
							Annulla
						</Button>

						<Button
							style={{
								backgroundColor: "#477673",
								color: "#fff",

								height: 50,
								borderRadius: 10,
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
							}}
							onClick={handleSubmit}
							/* 		disabled={!stripe || loading} */
							loading={loading}
						>
							Aggiungi <ReactSVG src={clickboardThik} />
						</Button>
					</div>
				</Row>
			)}
		</form>
	);
};

// Stili personalizzati
const formStyles = {
	display: "flex",
	flexDirection: "column",
	gap: "0px",
	padding: "20px",
	border: "1px solid #000",
	borderRadius: "10px",
	boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
	backgroundColor: "#fff",
	fontFamily: "Arial, sans-serif",
};

const headerStyle = {
	marginBottom: "15px",
	color: "#004080",
	fontSize: "18px",
	fontWeight: "bold",
};

const inputGroupStyles = {
	width: "100%",
	display: "flex",
	marginLeft: "10px",
	flexDirection: "column",
};

const labelStyles = {
	marginBottom: "5px",
	color: "#333",
	fontWeight: "bold",
};

const inputStyles = {
	marginBottom: "10px",
	fontSize: "12px",
	border: "none",
};

const cardElementStyles = {
	style: {
		base: {
			fontSize: "12px",
			fontWeight: "500",
			"::placeholder": { color: "#737679" },
		},
		invalid: {
			color: "#e74c3c",
		},
	},
};

const buttonStyles = {
	padding: "12px",
	backgroundColor: "#003f49",
	color: "#fff",

	cursor: "pointer",
	fontSize: "10px",
	fontWeight: "bold",
	transition: "background-color 0.3s ease",
	width: "fit-content",
	textAlign: "right",
	border: "none",
	marginTop: "20px",
};

// App con Stripe Elements
const App = ({ finishAdd, setShowAdd, handleChange, valueAdd, setReload }) => (
	<Elements stripe={stripePromise}>
		<PaymentForm
			finishAdd={finishAdd}
			setShowAdd={setShowAdd}
			handleChange={handleChange}
			setReload={setReload}
			valueAdd={valueAdd}
		/>
	</Elements>
);

export default App;
