import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import "./style.css";
import AvailableSlots from "../Components/Pages/AvailableSlots";
import { DatePicker, Space, Button, ConfigProvider } from "antd";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import localeData from "dayjs/plugin/localeData";
import "dayjs/locale/it"; // Importa la localizzazione italiana
import "./style.css";
import { ReactSVG } from "react-svg";
import clipboardTick from "../assets/images/clipboard-tick.svg";
import clickBoardDelete from "../assets/images/clipboard-close.svg";
import { useDispatch, useSelector } from "react-redux";
import {
	saveMineAvailabilityAction,
	selectSlotsDataSession,
	selectSlotsData,
	getMineAvailabilityAction,
} from "../features/availableSlots/availableSlotsSlices";
import itIT from "antd/es/locale/it_IT"; // Locale italiano per Ant Design

dayjs.extend(isoWeek);
dayjs.extend(localeData);
dayjs.locale("it"); // Imposta il locale su italiano

const AvailableSlot = () => {
	const [currentWeek, setCurrentWeek] = useState(dayjs().startOf("isoWeek"));
	const eventsSession = useSelector(selectSlotsDataSession);
	const dataEvent = useSelector(selectSlotsData);
	const dispatch = useDispatch();

	const handleSaveEvent = () => {
		dispatch(saveMineAvailabilityAction({ events: eventsSession }));
	};

	useEffect(() => {
		setCurrentWeek(dayjs().startOf("isoWeek"));
	}, []);

	useEffect(() => {
		if (!dataEvent?.length) {
			dispatch(getMineAvailabilityAction());
		}
	}, []);

	const handleWeekChange = (date) => {
		if (date) {
			setCurrentWeek(date);
		}
	};

	const weekFormat = (value) => {
		const startOfWeek = value.startOf("isoWeek").format("D");
		const endOfWeek = value.endOf("isoWeek").format("D MMMM YYYY");
		return `${startOfWeek}-${endOfWeek}`;
	};

	return (
		<ConfigProvider locale={itIT}>
			<Row>
				<Col sm='12'>
					<div className='d-flex justify-content-between align-content-center align-items-center'>
						<p
							style={{
								fontSize: 24,
								fontWeight: 600,
								marginTop: 32,
								marginBottom: 32,
								marginLeft: 36,
							}}
						>
							Disponibilità
						</p>
						<Space style={{ marginBottom: "10px", width: 200 }}>
							<DatePicker
								value={currentWeek} // Imposta la settimana corrente come valore iniziale
								onChange={handleWeekChange}
								picker='week'
								style={{ width: 200 }}
								format={weekFormat}
								placeholder='Seleziona la settimana'
							/>
						</Space>
					</div>
					<hr style={{ marginLeft: -20, width: "110%" }} />
				</Col>
			</Row>
			<Row>
				<AvailableSlots currentWeek={currentWeek} />
			</Row>
			<Row
				className='mt-4 mr-5'
				style={{
					display: "flex",
					paddingRight: 20,
					paddingBottom: 20,
					justifyContent: "flex-end",
					alignItems: "center",
					flexDirection: "row",
					gap: 20,
				}}
			>
				<Button
					style={{
						height: 50,
						width: "fit-content",
						display: "flex",
						alignItems: "center",
						color: "#003F49",
						borderColor: "#8E6B00",
						backgroundColor: "#fff",
						gap: 10,
						justifyContent: "center",
					}}
					onClick={() => dispatch(getMineAvailabilityAction())}
				>
					<ReactSVG src={clickBoardDelete} />
					Annulla
				</Button>
				<Button
					color='primary'
					style={{
						height: 50,
						width: "fit-content",
						backgroundColor: "#477673",
						color: "#fff",
						display: "flex",
						alignItems: "center",
						paddingLeft: 20,
						paddingRight: 20,
						gap: 10,
						justifyContent: "center",
					}}
					onClick={handleSaveEvent}
				>
					Salva modifiche
					<ReactSVG src={clipboardTick} />
				</Button>
			</Row>
		</ConfigProvider>
	);
};

export default AvailableSlot;
