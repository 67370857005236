import React from "react";
import { Col, Row, Button } from "reactstrap";
import P2 from "../../../CommonElements/Headings/P2Element";
import P4 from "../../../CommonElements/Headings/P5Element";
import { ReactSVG } from "react-svg";
import infoCircle from "../../../assets/images/icon/info-circle.svg";
import documentText from "../../../assets/images/icon/document-text.svg";

const Invoice = () => {
	return (
		<Row>
			<Col md='6'>
				<P2 style={{ fontWeight: 400 }}>
					Lorem ipsum dolor sit amet consectetur. Feugiat pellentesque
					adipiscing sed pharetra dui ut. Quis vivamus pretium placerat turpis
					tempus massa nibh auctor. Duis aliquam massa sed feugiat. Sapien
					aliquam amet a proin viverra. Non egestas nisl praesent bibendum.
				</P2>
				<div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
					<ReactSVG
						src={infoCircle}
						style={{ width: 50, height: 50 }}
					/>
					<P4
						style={{
							color: "#737679",
							fontWeight: 400,
						}}
					>
						Lorem ipsum dolor sit amet consectetur. Quis consequat nulla
						tincidunt nunc convallis orci platea. Sem varius pulvinar cursus
						adipiscing eget. Sodales sem proin feugiat netus tellus nisi
						viverra. Nec mauris lectus eu varius sed volutpat ut. Faucibus.
					</P4>
				</div>
			</Col>
			<Row
				style={{
					margin: "32px",
				}}
			>
				<Button
					color='primary'
					style={{
						width: "fit-content",
						height: "48px",

						border: "none",
						borderRadius: "10px",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",

						bottom: "24px",
					}}
					/* 		onClick={() => navigation(link)} */
				>
					<span style={{ fontSize: 12, fontWeight: 600 }}>
						Visualizza il contratto con Tao s.r.l
					</span>
					<ReactSVG
						src={documentText}
						style={{
							width: "24px",
							height: "24px",
							color: "#fff",
							marginRight: 10,
						}} // Imposta larghezza e altezza
						color='#fff'
						fill='#fff'
						colorRendering={true}
					/>
				</Button>
			</Row>
		</Row>
	);
};

export default Invoice;
