import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import P6 from "../CommonElements/Headings/P6Element";
import { Grid, Input } from "antd";
import DataTableUser from "../Components/Pages/Contacts/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { getConsultantServiceAction } from "../features/summary-service/summaryServiceSlices";

const AvailableSlot = () => {
	// Correzione: aggiungi le parentesi tonde a `useDispatch()`
	const dispatch = useDispatch();
	const [search, setSearch] = useState("");
	const dataUser = useSelector((state) => state.summaryService.data);

	useEffect(() => {
		if (search) {
			dispatch(getConsultantServiceAction(search));
		} else {
			dispatch(getConsultantServiceAction());
		}
	}, [search, dispatch]);

	return (
		<>
			<Row>
				<Col sm='12'>
					<div className='d-flex flex-row justify-content-between align-items-center'>
						<div>
							<p
								style={{
									fontSize: 24,
									fontWeight: 600,
									marginTop: 32,
									marginLeft: 36,
								}}
							>
								Elenco utenti
							</p>

							<P6 style={{ marginLeft: 36 }}>
								{" "}
								{dataUser?.length}{" "}
								{dataUser?.length === 1 ? "utente" : "utenti"} trovato
							</P6>
						</div>
						<Input
							onChange={(val) => setSearch(val.target.value)}
							className='search-user'
							placeholder='Cerca utente'
							style={{ width: "30%", marginRight: 36, height: 40 }}
							suffix={<i className='fa fa-search'></i>}
						/>
					</div>
					<hr style={{ marginLeft: -20, width: "110%" }} />
				</Col>
			</Row>
			<Row>
				<DataTableUser />
			</Row>
		</>
	);
};

export default AvailableSlot;
