import axios from "axios";
import SweetAlert from "sweetalert2";
// Crea un'istanza di Axios
const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

// Interceptor per le richieste (puoi aggiungere token o altre informazioni alla richiesta)
api.interceptors.request.use(
	(config) => {
		// Aggiungi token di autenticazione se necessario
		const token = localStorage.getItem("taoToken"); // Esempio per ottenere il token dal localStorage
		if (token) {
			config.headers["Authorization"] = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		// Gestisci l'errore della richiesta
		return Promise.reject(error);
	}
);

// Interceptor per le risposte (gestisci gli errori)
api.interceptors.response.use(
	(response) => {
		// Gestisci la risposta
		if (response.status === 401) {
			// Esempio di gestione dell'errore 401 (non autorizzato)
			/* 			window.location.href = "http://example.com/login";
			 */
		}
		return response;
	},
	(error) => {
		/* 		window.location.href = "/login"; */
		return Promise.reject(error);
	}
);

export default api;
