import React from "react";
import { Col, Row, Button } from "reactstrap";
import P2 from "../../../CommonElements/Headings/P2Element";
import P4 from "../../../CommonElements/Headings/P5Element";
import { ReactSVG } from "react-svg";
import infoCircle from "../../../assets/images/icon/info-circle.svg";
import userEdit from "../../../assets/images/icon/user-edit.svg";

const Invoice = () => {
	return (
		<Row>
			<Col md='6'>
				<P2 style={{ fontWeight: 400 }}>
					Per compilare il tuo profilo così come appare sul sito Tao.com accedi
					al link Tao.com\wp-admin con le credenziali che ti sono state fornite
					da Tao s.r.l
				</P2>
				<div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
					<ReactSVG
						src={infoCircle}
						style={{ width: 50, height: 50 }}
					/>
					<P4
						style={{
							color: "#737679",
							fontWeight: 400,
						}}
					>
						Lorem ipsum dolor sit amet consectetur. Diam amet orci enim morbi
						risus est. Lorem sed dis aliquet turpis enim tincidunt tempor. Sem
						placerat tincidunt sit et. Habitasse sapien risus ac sit id lorem
						sem. In mi diam tristique id feugiat vel. Et orci morbi feugiat.
					</P4>
				</div>
			</Col>
			<Row
				style={{
					margin: "32px",
				}}
			>
				<Button
					color='primary'
					style={{
						width: "fit-content",
						height: "48px",

						border: "none",
						borderRadius: "10px",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",

						bottom: "24px",
					}}
					/* 		onClick={() => navigation(link)} */
				>
					<span style={{ fontSize: 12, fontWeight: 600 }}>
						Vai al pannello di editing del tuo profilo{" "}
					</span>
					<ReactSVG
						src={userEdit}
						style={{
							width: "24px",
							height: "24px",
							color: "#fff",
							marginRight: 10,
						}} // Imposta larghezza e altezza
						color='#fff'
						fill='#fff'
						colorRendering={true}
					/>
				</Button>
			</Row>
		</Row>
	);
};

export default Invoice;
