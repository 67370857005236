import { configureStore } from "@reduxjs/toolkit";
import chatReducer from "../features/chat/chatSlice";
import userReducer from "../features/user/userSlices";
import surveySlices from "../features/survey/surveySlices";
import serviceSlice from "../features/service/serviceSlice";
import profileSlices from "../features/profile/profileSlices";
import paymentSlice from "../features/payment/paymentSlice";
import availableSlotsSlice from "../features/availableSlots/availableSlotsSlices";
import summaryServiceSlices from "../features/summary-service/summaryServiceSlices";
const store = configureStore({
	reducer: {
		chat: chatReducer,
		service: serviceSlice,
		user: userReducer,
		survey: surveySlices,
		profile: profileSlices,
		payment: paymentSlice,
		availableSlots: availableSlotsSlice,
		summaryService: summaryServiceSlices,
	},
});

export default store;
