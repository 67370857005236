import React, { useEffect, useState } from "react";
import { Drawer, Divider, Button } from "antd";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next"; // Importazione di `useTranslation`
import logo from "../../assets/images/logo/logo-it.svg";
import profilePic from "../../assets/images/logo/menu-mobile.svg";
import homeIcon from "../../assets/images/icon/menu/hone.svg";
import menuIcon from "../../assets/images/logo/menu-mobile.svg";
import chatIcon from "../../assets/images/icon/menu/chat.svg";
import appointmentsIcon from "../../assets/images/icon/menu/appointment.svg";
import settingsIcon from "../../assets/images/icon/menu/settings.svg";
import logoutIcon from "../../assets/images/icon/menu/logout.svg";
import helpIcon from "../../assets/images/icon/menu/Question.svg";
import serviceIcon from "../../assets/images/icon/menu/service.svg";
import calendarIcon from "../../assets/images/icon/menu/calendar.svg";
import user2Icon from "../../assets/images/icon/menu/user_2.svg";
import clickboardThik from "../../assets/images/clipboard-tick.svg";
import clicpboardClose from "../../assets/images/clipboard-close.svg";
import P5 from "../../CommonElements/Headings/P5Element";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import P1 from "../Headings/P1Element";
import { Modal } from "reactstrap";

const HeaderPlatform = ({ title, CustomTitle }) => {
	const { t } = useTranslation();
	const [isDrawerVisible, setIsDrawerVisible] = useState(false);
	const userData = useSelector((state) => state.user.data);
	const [showModal, setShowModal] = useState(false);
	const toggleDrawer = () => {
		setIsDrawerVisible(!isDrawerVisible);
	};

	useEffect(() => {
		console.log(showModal);
	}, [showModal]);
	return (
		<div style={{ paddingLeft: 16, paddingRight: 16 }}>
			{/* Header con Logo e Icona Menu */}
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					paddingLeft: "16px",
					paddingRight: "16px",
				}}
			>
				<ReactSVG
					src={logo}
					style={{ width: "160px", height: "fit-content" }}
				/>
				<button
					onClick={toggleDrawer}
					style={{ background: "none", border: "none", cursor: "pointer" }}
				>
					<ReactSVG
						src={menuIcon}
						style={{ width: "48px", height: "48px" }}
					/>
				</button>
			</div>
			<hr
				style={{
					width: "100%",
					margin: 0,
					marginTop: 16,
					backgroundColor: "#e6edef",
					height: 0.5,
				}}
			/>

			{/* Condizionale per Title e CustomTitle */}
			{CustomTitle ? (
				<div style={{ marginBottom: 20 }}>
					<div style={{ padding: 0 }}>
						<CustomTitle />
					</div>
					{/* 	<hr style={{ width: "100%", margin: 0 }} /> */}
				</div>
			) : (
				title && (
					<>
						<p
							style={{
								fontSize: 24,
								margin: 0,
								fontWeight: 600,
								padding: "16px",
							}}
						>
							{title}
						</p>
						<hr style={{ width: "100%", margin: 0 }} />
					</>
				)
			)}

			{/* Drawer per il menu */}
			<Drawer
				placement='top'
				closable={false}
				onClose={toggleDrawer}
				open={isDrawerVisible}
				height='100vh'
				bodyStyle={{ padding: 0 }}
			>
				<div
					style={{
						padding: "16px",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<ReactSVG
						src={logo}
						style={{ width: "160px", height: "fit-content" }}
					/>
					<button
						onClick={toggleDrawer}
						style={{
							background: "none",
							border: "none",
							fontSize: "24px",
							cursor: "pointer",
						}}
					>
						✕
					</button>
				</div>

				<Divider />

				<div
					style={{ padding: "16px", display: "flex", flexDirection: "column" }}
				>
					{userData?.cod_type === "1" ? (
						<>
							<MenuItem
								icon={homeIcon}
								label={t("menu.menu_home")}
								link='/home'
							/>
							<MenuItem
								icon={chatIcon}
								label={t("menu.menu_chat")}
								link='/chat'
							/>
							<MenuItem
								icon={appointmentsIcon}
								label={t("menu.menu_appointments")}
								link='/appointment'
							/>
							<MenuItem
								icon={settingsIcon}
								label={t("menu.menu_settings")}
								link='/settings'
							/>
						</>
					) : (
						<>
							<MenuItem
								icon={chatIcon}
								label={t("menu.menu_chat")}
								link='/chat'
							/>
							<MenuItem
								icon={appointmentsIcon}
								label={t("menu.menu_appointments")}
								link='/appointment'
							/>
							<MenuItem
								icon={calendarIcon}
								label='Disponibilità'
								link='/available-slots'
							/>
							<MenuItem
								icon={user2Icon}
								label='Elenco Clienti'
								link='/clients'
							/>
							<MenuItem
								icon={serviceIcon}
								label='Servizi erogati'
								link='/my-service'
							/>
							<MenuItem
								icon={settingsIcon}
								label={t("menu.menu_settings")}
								link='/settings'
							/>
						</>
					)}
				</div>

				<Divider />

				<div
					style={{ padding: "16px", display: "flex", flexDirection: "column" }}
				>
					<MenuItem
						icon={logoutIcon}
						label={t("menu.menu_logout")}
						action={() => setShowModal(true)}
					/>
				</div>

				<Divider />

				<div style={{ padding: "16px", fontSize: "12px", color: "grey" }}>
					<p style={{ marginBottom: "8px" }}>{t("menu.privacy_policy")}</p>
					<p>{t("menu.terms_and_conditions")}</p>
				</div>

				<div
					style={{
						padding: "16px",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						position: "fixed",
						bottom: 0,
						left: 0,
						right: 0,
						gap: "32px",
						width: "100%",
					}}
				>
					<div style={{ fontSize: "14px", display: "flex", gap: "32px" }}>
						<span>{t("menu.language_italian")}</span> |{" "}
						<span>{t("menu.language_chinese")}</span>
					</div>
					<ReactSVG
						src={helpIcon}
						style={{ position: "fixed", bottom: 30, right: 10 }}
					/>
				</div>
			</Drawer>
			<Modal
				backdropClassName='modal-backdrop'
				isOpen={showModal}
				style={{
					width: "100%",
					height: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					padding: 20,
				}}
				toggle={() => setShowModal(!showModal)}
			>
				<div
					style={{
						backgroundColor: "#FCF8F0",
						padding: 20,
						borderRadius: 10,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<P1>Confermi di volere abbandonare la piattaforma?</P1>
					<div style={{ display: "flex", justifyContent: "center", gap: 60 }}>
						<Button
							style={{
								backgroundColor: "#fff",
								color: "#477673",
								border: "1px solid #8E6B00",
								borderRadius: 10,
								height: 50,
							}}
							icon={<ReactSVG src={clicpboardClose} />}
							onClick={() => setShowModal(!showModal)}
						>
							Annulla
						</Button>

						<Button
							style={{
								backgroundColor: "#477673",
								color: "#fff",
								height: 50,
								borderRadius: 10,
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
							}}
							onClick={() => {
								localStorage.clear();
								window.location.href = "/login";
							}}
						>
							Conferma <ReactSVG src={clickboardThik} />
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

const MenuItem = ({ icon, label, link = "/", action }) => {
	const navigate = useNavigate();
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				marginBottom: "16px",
				cursor: "pointer",
			}}
			onClick={() => (!!action ? action() : navigate(link))}
		>
			<ReactSVG
				src={icon}
				style={{ width: "32px", height: "40px", marginRight: "24px" }}
			/>
			<P5 style={{ fontSize: "16px" }}>{label}</P5>
		</div>
	);
};

export default HeaderPlatform;
