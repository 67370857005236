import React from "react";
import { Card, CardBody, Button, Modal } from "reactstrap";
import { ArrowRightCircle } from "react-feather"; // Importa le icone che vuoi utilizzare
import { useNavigate } from "react-router-dom";
import jessica_li from "../../assets/images/avtar/jessica_li.png";
import P3 from "../../CommonElements/Headings/P5Element";
import P2 from "../../CommonElements/Headings/P2Element";
import P1 from "../../CommonElements/Headings/P1Element";
import P6 from "../../CommonElements/Headings/P5Element";
import calendar_remove from "../../assets/images/icon/calendar-remove.svg";
import video_time from "../../assets/images/icon/video-time.svg";

import { ReactSVG } from "react-svg";
import { useDispatch } from "react-redux";
import { deleteAppointmentAction } from "../../features/availableSlots/availableSlotsSlices";
import { getMineAppointmentAction } from "../../features/profile/profileSlices";
import { Avatar, Tooltip } from "antd";
import ModalAlert from "../../CommonElements/ModalAlert";
import dayjs from "dayjs";
const CardContainer = ({ item }) => {
	const dispatch = useDispatch();
	const [showModal, setShowModal] = React.useState(false);
	const handleDeleteMeeting = (id) => {
		dispatch(deleteAppointmentAction(id));
		dispatch(getMineAppointmentAction());
	};
	const {
		name,
		description,
		image,
		surname,
		start_time,
		hourStart,
		hourEnd,
		serviceTitle,
		serviceDescription,
		link,
		directLink = false,
	} = item;

	const canDeleteMeeting = dayjs(start_time)
		.subtract(48, "hour")
		.isAfter(dayjs());

	const [meetingId, setMeetingId] = React.useState(null);

	console.log(image);
	return (
		<Card
			style={{
				backgroundColor: "#FCF8F0",
				borderRadius: "10px",

				width: "100%",
			}}
		>
			<CardBody>
				<div className='d-flex '>
					{!!image ? (
						<img
							src={process.env.REACT_APP_API_URL_MEDIA + image}
							alt=''
							style={{
								width: 50,
								height: 50,
								borderRadius: "50%",
								marginRight: 10,
							}}
						/>
					) : (
						<Avatar size={50}>
							{" "}
							{name.charAt(0).toUpperCase()} {surname?.chartAt(0).toUpperCase()}
						</Avatar>
					)}
					<div className='d-flex flex-column'>
						<p style={{ fontSize: 13, fontFamily: "Exo 2" }}>{name}</p>
					</div>
				</div>
				<P2 style={{ marginTop: 32, fontSize: 16 }}>
					Ore: {hourStart} - {hourEnd}
				</P2>
				<P1 style={{ marginTop: 12 }}>{serviceTitle}</P1>
				<P6 style={{ marginTop: 8, color: "#003F49" }}>{serviceDescription}</P6>
				<div className='d-flex justify-content-end mt-5'>
					<div style={{ display: "flex", flexDirection: "column" }}>
						<Button
							color='white'
							style={{
								height: "48px",
								border: "none",
								borderRadius: "10px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								bottom: "24px",
							}}
							disabled={!canDeleteMeeting}
							onClick={() => {
								setShowModal(true);
								setMeetingId(item.id);
							}}
						>
							{" "}
							<ReactSVG
								src={calendar_remove}
								style={{
									width: "30px",
								}} // Imposta larghezza e altezza
							/>
							<div>
								<span style={{ fontSize: 12, fontWeight: 600 }}>
									Annulla appuntamento
								</span>
							</div>
						</Button>

						<span
							style={{
								fontSize: 9,
								textAlign: "center",
								width: "100%",
								display: "flex",
								justifyContent: "center",
							}}
						>
							Appuntamento non annullabile
						</span>
					</div>
					<Tooltip
						title={
							link === "#"
								? "Non ancora disponibile"
								: "Collegati alla videocall"
						}
					>
						<Button
							color='primary'
							disabled={link === "#"}
							style={{
								height: "48px",
								border: "none",
								borderRadius: "10px",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",

								bottom: "24px",
							}}
							onClick={() => window.open(link, "_blank")}
						>
							<ReactSVG
								src={video_time}
								style={{
									color: "#fff",
									marginRight: 10,
								}}
								color='#fff'
								fill='#fff'
								colorRendering={true}
							/>
							<span style={{ fontSize: 12, fontWeight: 600 }}>
								Collegati alla videocall
							</span>
						</Button>
						<span
							style={{
								fontSize: 10,
								textAlign: "center",
								width: "100%",
								display: "flex",
								justifyContent: "center",
							}}
						>
							Link non ancora disponibile
						</span>
					</Tooltip>
				</div>
			</CardBody>
			<ModalAlert
				BodyComponent={() => (
					<div>Sei sicuro di voler annullare l'appuntamento?</div>
				)}
				okButtonTitle={"Annulla appuntamento"}
				deleteButtonTitle={"Cancella"}
				showModal={showModal}
				setShowModal={setShowModal}
				onOk={() => {
					handleDeleteMeeting(item.id);
					setShowModal(false);
				}}
				onDelete={() => {
					setShowModal(false);
				}}
			/>
		</Card>
	);
};

export default CardContainer;
