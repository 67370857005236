import React from "react";
import Routers from "./Route";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/Customizer/CustomizerProvider";
import { Provider } from "react-redux";
import store from "./store";
import { ConfigProvider } from "antd";
import itIT from "antd/es/locale/it_IT";
const App = () => (
	<div className='App'>
		<ConfigProvider
			locale={itIT}
			theme={{
				token: {
					colorPrimary: "#4e7e7c", // Colore primario personalizzato
				},
			}}
		>
			<Provider store={store}>
				<CustomizerProvider>
					<AnimationThemeProvider>
						<Routers />
					</AnimationThemeProvider>
				</CustomizerProvider>
			</Provider>
		</ConfigProvider>
	</div>
);

export default App;
