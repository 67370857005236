import React, { Fragment, useEffect, useState, useContext } from "react";
import SidebarMenu from "./SidebarMenu";
import { Modal } from "reactstrap";
import CustomizerContext from "../../_helper/Customizer";
import { MENUITEMSCONSULTANT, MENUITEMUSER } from "./Menu";
import Profile from "./Profile";
import HelpCenterCard from "./HelpCenter";
import { useSelector } from "react-redux";
import { Button } from "antd";
import { ReactSVG } from "react-svg";
import backsquare from "../../assets/images/icon/back-square.svg";
import { H2 } from "../../AbstractElements";
import P1 from "../../CommonElements/Headings/P1Element";
import clickboardThik from "../../assets/images/clipboard-tick.svg";
import clicpboardClose from "../../assets/images/clipboard-close.svg";

const SideBarLayout = (props) => {
	const [showModal, setShowModal] = useState(false);

	const { toggleIcon } = useContext(CustomizerContext);
	const [menuItems, setMenuItems] = useState(MENUITEMUSER);
	const userType = useSelector((state) => state.user.userType);

	useEffect(() => {
		if (userType === "consultant") {
			setMenuItems(MENUITEMSCONSULTANT);
		} else {
			setMenuItems(MENUITEMUSER);
		}
	}, [userType]);

	const [currentUrl] = useState(window.location.pathname);
	const id = window.location.pathname.split("/").pop();
	// eslint-disable-next-line
	const [leftArrow, setLeftArrow] = useState(false);
	const layout = id;
	const [width, setWidth] = useState(0);
	const handleResize = () => {
		setWidth(window.innerWidth - 500);
	}; // eslint-disable-next-line
	const [mainmenu, setMainMenu] = useState(MENUITEMUSER);
	const handleScroll = () => {
		if (window.scrollY > 400) {
			document.querySelector(".main-navbar").className = "main-navbar hovered";
		} else {
			if (document.getElementById("main-navbar"))
				document.querySelector(".main-navbar").className = "main-navbar";
		}
	};

	const setNavActive = (item) => {
		menuItems.map((menuItems) => {
			menuItems.Items.filter((Items) => {
				if (Items !== item) {
					Items.active = false;
					document.getElementById("bg-overlay1").classList.remove("active");
				}
				if (Items.children && Items.children.includes(item)) {
					Items.active = true;
				}
				if (Items.children) {
					Items.children.filter((submenuItems) => {
						if (submenuItems.children && submenuItems.children.includes(item)) {
							Items.active = true;
							submenuItems.active = true;
							return true;
						} else {
							return false;
						}
					});
				}
				return Items;
			});
			return menuItems;
		});
		item.active = !item.active;
		setMainMenu({ mainmenu: menuItems });
	};
	useEffect(() => {
		setLeftArrow(true);
		window.addEventListener("resize", handleResize);
		handleResize();
		const currentUrl = window.location.pathname;
		menuItems.map((items) => {
			items.Items.filter((Items) => {
				if (Items.path === currentUrl) setNavActive(Items);
				if (!Items.children) return false;
				Items.children.filter((subItems) => {
					if (subItems.path === currentUrl) setNavActive(subItems);
					if (!subItems.children) return false;
					subItems.children.filter((subSubItems) => {
						if (subSubItems.path === currentUrl) {
							setNavActive(subSubItems);
							return true;
						} else {
							return false;
						}
					});
					return subItems;
				});
				return Items;
			});
			return items;
		});
		window.addEventListener("scroll", handleScroll);
		handleScroll();
		return () => {
			window.removeEventListener("scroll", handleScroll);
			window.removeEventListener("resize", handleResize);
		};
	}, [layout, currentUrl]);

	const closeOverlay = () => {
		document.getElementById("bg-overlay1").classList.remove("active");
		document.getElementById("nav-link").classList.remove("active");
	};

	return (
		<Fragment>
			<div
				id='bg-overlay1'
				style={{ overflowY: "scroll" }}
				onClick={() => {
					closeOverlay();
				}}
			></div>
			<header
				className={`main-nav ${toggleIcon ? "close_icon" : ""}`}
				style={{ border: "solid 1px", overflowY: "scroll" }}
			>
				<SidebarMenu
					setMainMenu={setMainMenu}
					props={props}
					sidebartoogle={true}
					setNavActive={setNavActive}
					width={width}
				/>
				<div
					className='sidebar-layout-overlay '
					style={{
						display: "flex",
						padding: 36,
						flexDirection: "column",
						position: "absolute",
						bottom: -50,
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							gap: 10,
							marginBottom: 20,
						}}
					>
						<Button
							style={{ border: "none" }}
							/* onClick={() => {
								localStorage.clear();
								window.location.href = "/login";
							}} */
							onClick={() => setShowModal(!showModal)}
							icon={<ReactSVG src={backsquare} />}
						>
							Esci
						</Button>
					</div>
					<HelpCenterCard />
				</div>
				<div
					className='sidebar-layout-overlay '
					style={{
						display: "flex",
						padding: 36,
						position: "absolute",
						justifyContent: "flex-start",
						width: "100%",
						bottom: 10,
					}}
				></div>
			</header>
			<Modal
				backdropClassName='modal-backdrop'
				isOpen={showModal}
				style={{
					width: "100%",
					height: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					padding: 20,
				}}
				toggle={() => setShowModal(!showModal)}
			>
				<div
					style={{
						backgroundColor: "#FCF8F0",
						padding: 20,
						borderRadius: 10,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<P1>Confermi di volere abbandonare la piattaforma?</P1>
					<div style={{ display: "flex", justifyContent: "center", gap: 60 }}>
						<Button
							style={{
								backgroundColor: "#fff",
								color: "#477673",
								border: "1px solid #8E6B00",
								borderRadius: 10,
								height: 50,
							}}
							icon={<ReactSVG src={clicpboardClose} />}
							onClick={() => setShowModal(!showModal)}
						>
							Annulla
						</Button>

						<Button
							style={{
								backgroundColor: "#477673",
								color: "#fff",
								height: 50,
								borderRadius: 10,
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
							}}
							onClick={() => {
								localStorage.clear();
								window.location.href = "/login";
							}}
						>
							Conferma <ReactSVG src={clickboardThik} />
						</Button>
					</div>
				</div>
			</Modal>
		</Fragment>
	);
};
export default SideBarLayout;
