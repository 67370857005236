import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import expandIconOpen from "../../../assets/images/icon/arrow-up.svg";
import expandIconClosed from "../../../assets/images/icon/arrow-down.svg";
import { getConsultantReportUserAction } from "../../../features/summary-service/summaryServiceSlices";

const DataTableUser = () => {
	const dispatch = useDispatch();
	const [expandedRowKeys, setExpandedRowKeys] = useState([]); // Stato per gestire le righe espanse

	const columns = [
		{
			title: "Servizi erogati",
			dataIndex: "short_name_it",
			key: "short_name_it",
		},
		{
			title: "Numero prestazioni",
			dataIndex: "numero_prestazioni",
			key: "numero_prestazioni",
		},
		{
			title: "Prezzo unitario al pubblico di Tao",
			dataIndex: "prezzo_unitario_pubblico",

			key: "prezzo_unitario_pubblico",
			render: (text) => `${text} €`,
		},
		{
			title: "Ricavo unitario netto di Tao",
			dataIndex: "prezzo_netto_unitario_tao",
			key: "prezzo_netto_unitario_tao",
			render: (text) => `${text} €`,
		},
		{
			title: "Prezzo netto unitario spettante (%)",
			dataIndex: "netto_unitario_consulente",
			key: "netto_unitario_consulente",
			render: (text) => `${text} €`,
		},
		{
			title: "Ricavo netto spettante (%)",
			dataIndex: "totale_ricavo_netto_consulente",
			key: "totale_ricavo_netto_consulente",
			render: (text) => `${text} €`,
		},
	];

	useEffect(() => {
		dispatch(getConsultantReportUserAction());
	}, []);

	const data = useSelector((state) => state.summaryService.reportUser);

	// Funzione per gestire l'espansione e la chiusura delle righe
	const handleExpand = (expanded, record) => {
		// Imposta la riga attualmente espansa o chiudi tutte le righe
		setExpandedRowKeys(expanded ? [record.cod_service] : []);
	};

	return (
		<div style={{ padding: "20px" }}>
			<Table
				columns={columns}
				dataSource={data}
				pagination={{
					pageSize: 10,
				}}
				rowKey='cod_service' // Usa un ID univoco per rowKey
				expandedRowKeys={expandedRowKeys}
				onExpand={handleExpand}
				expandable={{
					expandedRowRender: (record) => (
						<div>
							<Table
								style={{
									backgroundColor: "#f5f5f5",
								}}
								columns={[
									{
										title: "Nome utente",
										dataIndex: "nome_utente",
										key: "nome_utente",
									},
									{
										title: "Numero prestazioni",
										dataIndex: "numero_prestazioni",
										key: "numero_prestazioni",
									},
									{
										title: "Ricavo netto spettante",
										dataIndex: "ricavo_netto",
										key: "ricavo_netto",
										render: (text) => `${text} €`,
									},
								]}
								dataSource={record.dettagli_utenti}
								pagination={false}
								rowKey='nome_utente'
							/>
						</div>
					),
					expandIcon: ({ expanded, onExpand, record }) =>
						expanded ? (
							<ReactSVG
								src={expandIconOpen}
								onClick={(e) => onExpand(record, e)}
								style={{ cursor: "pointer" }}
							/>
						) : (
							<ReactSVG
								src={expandIconClosed}
								onClick={(e) => onExpand(record, e)}
								style={{ cursor: "pointer" }}
							/>
						),
					expandIconColumnIndex: 6, // Posiziona l'icona a destra
				}}
			/>
		</div>
	);
};

export default DataTableUser;
