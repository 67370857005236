import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
	getPaymentMethod,
	savePaymentMethod,
	setDefaultPaymentMethod,
	deletePaymentMethod,
	doPayment,
	createInvoice,
} from "../../api/payment";
import SweetAlert from "sweetalert2";

export const getPaymentMethodAction = createAsyncThunk(
	"payment/getPaymentMethodAction",
	async (_, { rejectWithValue }) => {
		try {
			const response = await getPaymentMethod(); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const createPaymentMethodAction = createAsyncThunk(
	"payment/createPaymentMethod",
	async (body, { rejectWithValue }) => {
		try {
			const response = await savePaymentMethod(body); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const createPaymentTransaction = createAsyncThunk(
	"payment/createPaymentTransaction",
	async (body, { rejectWithValue }) => {
		try {
			const response = await doPayment(body); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const createInvoiceAction = createAsyncThunk(
	"payment/createInvoiceAction",
	async (body, { rejectWithValue }) => {
		try {
			const response = await createInvoice(body); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			return rejectWithValue(error.message);
		}
	}
);

export const setDefaultPaymentMethodAction = createAsyncThunk(
	"payment/setDefaultPaymentMethodAction",
	async (id, { rejectWithValue }) => {
		try {
			const response = await setDefaultPaymentMethod(id); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const deletePaymentMethodAction = createAsyncThunk(
	"payment/deletePaymentMethodAction",
	async (id, { rejectWithValue }) => {
		try {
			const response = await deletePaymentMethod(id); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const serviceSlice = createSlice({
	name: "payment",
	initialState: {
		loading: false,
		paymentMethods: [],
		confirmID: null,
		status: "doTo",
		invoiceData: {},
		stateCreation: null,
		invoiceID: null,
		error: null,
	},
	reducers: {
		resetPaymentStore: (state, action) => {
			state.loading = "test";
			state.status = "doTo";
			state.invoiceData = {};
			state.invoiceID = null;
			state.error = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getPaymentMethodAction.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.paymentMethods = [];
			})
			.addCase(getPaymentMethodAction.fulfilled, (state, action) => {
				state.paymentMethods = action.payload.data;
				state.loading = false;
				state.error = null;
			})
			.addCase(getPaymentMethodAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				state.paymentMethods = [];
				SweetAlert.fire({
					icon: "error",
					title: "Errore",
					text: "Errore nel caricamento dei metodi di pagamento",
				});
			})

			.addCase(createPaymentMethodAction.pending, (state) => {
				state.loading = true;
				state.stateCreation = "loading";
				state.error = null;
			})
			.addCase(createPaymentMethodAction.fulfilled, (state, action) => {
				state.data = action.payload.user;
				state.stateCreation = "success";

				state.loading = false;
				state.error = null;
			})
			.addCase(createPaymentMethodAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				SweetAlert.fire({
					icon: "error",
					title: "Errore",
					text: "Errore nella creazione del metodo di pagamento",
				});
			})

			.addCase(createInvoiceAction.pending, (state) => {
				state.loading = true;
				state.status = "loading";
			})
			.addCase(createInvoiceAction.fulfilled, (state, action) => {
				state.loading = false;
				console.log(action.payload, "action.payload");
				state.invoiceID = action.payload.id;
				state.error = null;
				state.status = "success";
			})
			.addCase(createInvoiceAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				SweetAlert.fire({
					icon: "error",
					title: "Errore",
					text: "Errore nella creazione della fattura",
				});
			})
			.addCase(createPaymentTransaction.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.status = null;
			})
			.addCase(createPaymentTransaction.fulfilled, (state, action) => {
				state.loading = false;
				state.status = "success";
				state.confirmID = action.payload.id;
				console.log(action.payload, "action.payload");
				SweetAlert.fire({
					icon: "success",
					title: "Confermato",
					text: "Il pagamento è stato confermato",
				});
			})
			.addCase(createPaymentTransaction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				state.status = "error";
				SweetAlert.fire({
					icon: "error",
					title: "Errore",
					text: "Errore nell'impostazione del metodo di pagamento predefinito",
				});
			})
			.addCase(setDefaultPaymentMethodAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(setDefaultPaymentMethodAction.fulfilled, (state, action) => {
				state.data = action.payload.user;
				state.loading = false;
				state.error = null;
			})
			.addCase(setDefaultPaymentMethodAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				SweetAlert.fire({
					icon: "error",
					title: "Errore",
					text: "Errore nell'impostazione del metodo di pagamento predefinito",
				});
			})

			.addCase(deletePaymentMethodAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(deletePaymentMethodAction.fulfilled, (state, action) => {
				state.data = action.payload.user;
				state.loading = false;
				state.error = null;
			})
			.addCase(deletePaymentMethodAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				SweetAlert.fire({
					icon: "error",
					title: "Errore",
					text: "Errore nell'eliminazione del metodo di pagamento",
				});
			});
	},
});
/* export const { setUser, saveAnswer, saveUserData, setChangeEmail } =
	surveySlices.actions; */
export const { resetPaymentStore } = serviceSlice.actions;
export const selectServiceData = (state) => state.services;
export const selectSingleServiceData = (state) => state.singleService;
export const selectServiceStep = (state) => state.stepService;
export const selectPaymentMethod = (state) => state.paymentMethod;
export const selectServiceLoading = (state) => state.loading;
export const selectServiceError = (state) => state.error;
export const selectStatusPayment = (state) => state.status;
export const { setInvoiceData } = serviceSlice.actions;

export default serviceSlice.reducer;
