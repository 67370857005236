import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import CardContainer from "./Card";
import { useDispatch, useSelector } from "react-redux";
import { getMineAppointmentAction } from "../../features/profile/profileSlices";
import dayjs from "dayjs"; // per la gestione delle date
import HeaderPlatform from "../../CommonElements/HeaderPlatform/index";
import { useNavigate } from "react-router-dom";

const Appointment = () => {
	const dispatch = useDispatch();
	const appointmentData = useSelector((state) => state.profile.appointment);
	const userData = useSelector((state) => state.user.data);
	const navigate = useNavigate();
	const [appointmentGrouped, setAppointmentGrouped] = useState({});
	useEffect(() => {
		dispatch(getMineAppointmentAction());
		if (!userData?.cod_consultant) navigate("/home");
	}, [dispatch]);

	// Raggruppa appuntamenti per data

	useEffect(() => {
		const groupedAppointments = appointmentData.reduce((acc, item) => {
			const date = dayjs(item.start_time).format("D MMMM YYYY");
			console.log(item);
			if (!acc[date]) acc[date] = [];
			acc[date].push(item);
			return acc;
		}, {});
		setAppointmentGrouped(groupedAppointments);
	}, [appointmentData]);

	return (
		<Row>
			<HeaderPlatform title='Appuntamenti' />
			{Object.keys(appointmentGrouped).length === 0 && (
				<Col sm='12'>
					<p
						style={{
							fontSize: 20,
							fontWeight: 600,
							marginTop: 32,
							marginBottom: 16,
						}}
					>
						Nessun appuntamento
					</p>
				</Col>
			)}

			{Object.keys(appointmentGrouped).map((date) => (
				<Row
					key={date}
					style={{ padding: 24 }}
				>
					<Col sm='12'>
						<p
							style={{
								fontSize: 20,
								fontWeight: 600,
								marginTop: 32,
								marginBottom: 16,
							}}
						>
							{date}
						</p>
					</Col>
					{appointmentGrouped[date].map((item, index) => (
						<Col
							md='6'
							key={index}
							className='mb-4'
						>
							<CardContainer
								item={{
									name: item.consultant,
									hourStart: dayjs(item.start_time).format("HH:mm"),
									hourEnd: dayjs(item.end_time).format("HH:mm"),
									serviceTitle: item.service,
									serviceDescription: item.service_description,
									link: item?.link,
									image: item?.consultantImage,
									start_time: item.start_time,
									id: item.id,
								}}
								directLink={true}
							/>
						</Col>
					))}
				</Row>
			))}
		</Row>
	);
};

export default Appointment;
