import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import P6 from "../CommonElements/Headings/P6Element";
import { Grid, Input } from "antd";
import DataTableService from "../Components/Pages/Service/DataTableService";
import { useDispatch, useSelector } from "react-redux";
import { getConsultantServiceAction } from "../features/summary-service/summaryServiceSlices";
import HeaderPlatform from "../CommonElements/HeaderPlatform/index";

const MyService = () => {
	// Correzione: aggiungi le parentesi tonde a `useDispatch()`

	return (
		<>
			<Row>
				<Col sm='12'>
					<HeaderPlatform title='Servizi erogati' />
				</Col>
			</Row>
			<Row>
				<DataTableService />
			</Row>
		</>
	);
};

export default MyService;
