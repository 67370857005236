import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getQuestionnaireById, saveUserAnswers } from "../../api/survey";
import SweetAlert from "sweetalert2";

export const getQuestionnaire = createAsyncThunk(
	"survey/getQuestionnaire",
	async (_, { rejectWithValue }) => {
		try {
			const response = await getQuestionnaireById(); // Assumiamo che questa funzione restituisca una promessa
			console.log(response.data);
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const saveAnswerApi = createAsyncThunk(
	"survey/saveAnswerApi",
	async (body, { rejectWithValue }) => {
		try {
			const response = await saveUserAnswers(body); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.response.data);
		}
	}
);

export const surveySlices = createSlice({
	name: "survey",
	initialState: {
		changeEmail: false,
		success: false,
		data: {},
		userData: {
			name: "",
			email: "",
			password: "",
		},
		loading: false,
		error: null,
		answers: {}, // Nuovo stato per memorizzare le risposte
	},
	reducers: {
		saveAnswer: (state, action) => {
			const { stepId, questionId, answer } = action.payload;
			if (!state.answers[stepId]) {
				state.answers[stepId] = {};
			}
			state.answers[stepId][questionId] = answer;
		},
		saveUserData: (state, action) => {
			state.userData = action.payload;
		},
		setChangeEmail: (state, action) => {
			state.changeEmail = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getQuestionnaire.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getQuestionnaire.fulfilled, (state, action) => {
				state.data = action.payload.data;
			})
			.addCase(getQuestionnaire.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
			});
		builder
			.addCase(saveAnswerApi.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = null;
			})
			.addCase(saveAnswerApi.fulfilled, (state, action) => {
				state.success = true;
			})
			.addCase(saveAnswerApi.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				console.log(action.payload);
				SweetAlert.fire({
					icon: "error",
					text: action.payload.message.it || "Errore sconosciuto",
				});
			});
	},
});
export const { setUser, saveAnswer, saveUserData, setChangeEmail } =
	surveySlices.actions;
export const selectQuestionaire = (state) => state.survey.data;
export const selectStatus = (state) => state.survey.success;
export default surveySlices.reducer;
