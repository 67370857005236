import React from "react";
import { Steps, Grid } from "antd";
import "./CustomStepper.css"; // Assicurati di collegare il tuo file CSS personalizzato

const { Step } = Steps;
const { useBreakpoint } = Grid;

const CustomStepper = ({ stepsData, currentStep }) => {
	const screens = useBreakpoint();

	return (
		screens.md && (
			<Steps
				progressDot
				current={currentStep}
				style={{ marginTop: 30 }}
				className='custom-stepper'
			>
				{stepsData.map((step, index) => (
					<Step key={index} />
				))}
				<Step key={"index8"} />
				<Step key={"index9"} />
			</Steps>
		)
	);
};

export default CustomStepper;
