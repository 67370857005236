import React, { useState } from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";
import * as chatSlice from "../../features/chat/chatSlice";
import P7 from "../../CommonElements/Headings/P7Element";
import {
	FilePdfOutlined,
	FileImageOutlined,
	VideoCameraOutlined,
	DownloadOutlined,
} from "@ant-design/icons";
import { Button } from "reactstrap";
import { Loader } from "react-feather";

const ChatData = () => {
	const chatData = useSelector(chatSlice.selectChatData);
	const userl = useSelector((state) => state.user?.data);
	const id = useSelector((state) => state.user?.data?.id_user);

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [selectedContent, setSelectedContent] = useState({ type: "", url: "" });

	const handleDownload = () => {
		const { url } = selectedContent;
		if (!url) {
			console.error("URL non valido");
			return;
		}
		const link = document.createElement("a");
		link.href = url;
		link.target = "_blank"; // Apre il link in una nuova scheda
		link.download = url.split("/").pop(); // Usa il nome file dall'URL
		link.style.display = "none"; // Nasconde l'elemento dal layout della pagina
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	// Funzione per aprire il modale
	const openModal = (type, url) => {
		setSelectedContent({ type, url });
		setIsModalVisible(true);
	};

	// Funzione per chiudere il modale
	const closeModal = () => {
		setIsModalVisible(false);
		setSelectedContent({ type: "", url: "" });
	};

	// Funzione per formattare la data
	const formatDate = (dateString) => {
		const date = new Date(dateString);
		return date.toLocaleDateString("it-IT", {
			weekday: "short", // Giorno abbreviato
			hour: "2-digit",
			minute: "2-digit",
		});
	};

	return (
		<>
			{chatData
				?.filter((el) => {
					if (userl.cod_type === "1") return el?.system_message !== -1;
					if (userl.cod_type === "2") return el?.system_message !== 1;
					return el;
				})
				?.map((chat, index) => (
					<div
						key={index}
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems:
								id?.toString() === chat.cod_receiver?.toString()
									? "flex-end"
									: chat?.system_message === 1 || chat?.system_message === -1
									? "center"
									: "flex-start",
						}}
					>
						<div
							style={{
								width: 380,
								backgroundColor:
									id?.toString() === chat.cod_receiver?.toString()
										? "#284F4C"
										: chat?.system_message === 1 || chat?.system_message === -1
										? "#737879"
										: "#DEDED1",
								borderRadius: 10,
								display: "flex",
								flexDirection: "column",
								padding: "12px 24px",

								color:
									id?.toString() === chat.cod_receiver?.toString() ||
									chat?.system_message === 1 ||
									chat?.system_message === -1
										? "#fff"
										: "#000",
							}}
						>
							{chat?.url ? (
								<div
									style={{
										marginBottom: 10,
										padding: "5px",
										cursor: "pointer",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: 100,
									}}
									onClick={() =>
										openModal(
											chat.url.endsWith(".pdf") ? "pdf" : "media",
											`${process.env.REACT_APP_API_URL_MEDIA}${chat.url}`
										)
									}
								>
									{chat.url.endsWith(".jpg") || chat.url.endsWith(".png") ? (
										<img
											src={`${process.env.REACT_APP_API_URL_MEDIA}${chat.url}`}
											alt='Preview'
											style={{
												height: "100%",
												width: "auto",
												borderRadius: 5,
												maxWidth: "100%", // Imposta la larghezza massima
												maxHeight: "100%", // Imposta l'altezza massima
												objectFit: "contain", // Assicura che l'immagine sia contenuta nel box
											}}
										/>
									) : chat.url.endsWith(".mp4") ? (
										<video
											src={`${process.env.REACT_APP_API_URL_MEDIA}${chat.url}`}
											style={{ height: "100%", width: "auto", borderRadius: 5 }}
											controls
										/>
									) : chat.url.endsWith(".pdf") ? (
										<FilePdfOutlined
											style={{ fontSize: 48, color: "#ff4d4f" }}
										/>
									) : (
										<FileImageOutlined
											style={{ fontSize: 48, color: "#d9d9d9" }}
										/>
									)}
								</div>
							) : (
								<p style={{ fontSize: 14, fontWeight: 300 }}>{chat.message}</p>
							)}
						</div>
						<P7 style={{ marginTop: 10, color: "#737679", marginBottom: 15 }}>
							{formatDate(chat.date_send)}
						</P7>
					</div>
				))}

			{/* Modale per visualizzare immagini, video o PDF ingranditi */}
			<Modal
				visible={isModalVisible}
				footer={null}
				onCancel={closeModal}
				centered
				width='auto'
				bodyStyle={{
					padding: 0,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column", // Per posizionare il contenuto e il bottone verticalmente
				}}
				style={{
					maxWidth: "90vw",
				}}
			>
				{selectedContent.type === "media" &&
				selectedContent.url.endsWith(".mp4") ? (
					<>
						<video
							src={selectedContent.url}
							controls
							style={{
								width: "100%",
								maxWidth: "80vw",
								maxHeight: "80vh",
							}}
						/>
						<Button
							type='primary'
							icon={<DownloadOutlined />}
							style={{ marginTop: 16 }}
							onClick={handleDownload}
						>
							Scarica Video
						</Button>
					</>
				) : selectedContent.type === "media" ? (
					<>
						<img
							src={selectedContent.url}
							alt='Enlarged chat'
							style={{
								width: "100%",
								height: "auto",
								maxWidth: "80vw",
								maxHeight: "80vh",
								objectFit: "contain",
							}}
						/>
						<Button
							type='primary'
							icon={<DownloadOutlined />}
							style={{ marginTop: 16 }}
							onClick={handleDownload}
						>
							Scarica Immagine
						</Button>
					</>
				) : selectedContent.type === "pdf" ? (
					<>
						<iframe
							src={selectedContent.url}
							title='PDF Viewer'
							style={{
								width: "100%",
								height: "80vh",
								border: "none",
							}}
						></iframe>
						<Button
							type='primary'
							icon={<DownloadOutlined />}
							style={{ marginTop: 16 }}
							onClick={handleDownload}
						>
							Scarica PDF
						</Button>
					</>
				) : null}
			</Modal>
		</>
	);
};

export default ChatData;
