import React from "react";

const H5 = (props) => {
	return (
		<h5
			style={{
				fontSize: 18,
				fontWeight: 500,
				color: "#001f24",
				fontFamily: "Exo 2",
				...props.style,
			}}
			{...props}
		>
			{props.children}
		</h5>
	);
};

export default H5;
