import React from "react";
import { Route, Routes } from "react-router-dom";
import RoutesConfig from "./Routes"; // Importa le rotte come default
import AppLayout from "../Layout/Layout";

const LayoutRoutes = () => {
	const routes = RoutesConfig();

	return (
		<Routes>
			{routes.map(({ path, Component }, i) => (
				<Route
					key={i}
					element={<AppLayout />}
				>
					<Route
						path={path}
						element={Component}
					/>
				</Route>
			))}
		</Routes>
	);
};

export default LayoutRoutes;
