import React, { useEffect } from "react";
import { Col, Row, Card, CardBody, Button } from "reactstrap";
import { ArrowRightCircle } from "react-feather"; // Importa le icone che vuoi utilizzare
import { useNavigate } from "react-router-dom";
import RightSidebar from "../commonLayout/RightSideBar";
import RightSidebarMobile from "../commonLayout/RightSideBarMobile";
import cardService from "../../mocks/cardService.json";
import {
	getServiceAction,
	getServicesAction,
	selectServiceData,
} from "../../features/service/serviceSlice";
import { useDispatch, useSelector } from "react-redux";
import { use } from "i18next";
import { selectUserData } from "../../features/user/userSlices";
import HeaderPlatform from "../../CommonElements/HeaderPlatform";
import closeIcon from "../../assets/images/icon/close.svg";
import { Grid } from "antd";
import { ReactSVG } from "react-svg";
// Componente per le Card dei servizi
const CardContainer = ({
	title,
	description,
	buttonText,
	link = "/",
	primaryAction = true,
}) => {
	const navigation = useNavigate();

	return (
		<Card
			style={{
				backgroundColor: "#FCF8F0",
				borderRadius: "10px",
				height: "fit-content",
				width: "100%",
				minHeight: "200px",
			}}
		>
			<CardBody>
				<p style={{ fontSize: 16, fontFamily: "Exo 2", fontWeight: 600 }}>
					{title}
				</p>
				<p style={{ fontSize: 13, fontFamily: "Exo 2" }}>{description}</p>
				<div className='d-flex justify-content-end mt-5'>
					{primaryAction ? (
						<Button
							color='primary'
							style={{
								height: "48px",
								border: "none",
								padding: "0 24px",
								borderRadius: "10px",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								position: "absolute",
								bottom: "15px",
							}}
							onClick={() => navigation(link)}
						>
							<span style={{ fontSize: 12, fontWeight: 600 }}>
								{buttonText}
							</span>
							<ArrowRightCircle
								size={20}
								className='ml-4'
								style={{ marginLeft: "8px" }}
							/>
						</Button>
					) : (
						<Button
							color='white'
							style={{
								height: "48px",
								borderRadius: "10px",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								border: "1px solid #8E6B00",
								position: "absolute",
								bottom: "24px",
							}}
							onClick={() => navigation(link)}
						>
							<ArrowRightCircle
								size={20}
								className='ml-4'
								style={{ marginRight: "8px" }}
								color='#003F49'
							/>
							<span style={{ fontSize: 12, fontWeight: 600 }}>
								{buttonText}
							</span>
						</Button>
					)}
				</div>
			</CardBody>
		</Card>
	);
};

const HomePage = () => {
	const dispatch = useDispatch();
	const serviceData = useSelector((state) => state.service.services);
	const userData = useSelector(selectUserData);
	const { useBreakpoint } = Grid;
	const breakpoint = useBreakpoint();
	useEffect(() => {
		if (!serviceData?.length) {
			dispatch(getServicesAction());
		}
	}, []);
	const [showDetails, setShowDetails] = React.useState(false);

	return (
		<>
			<Row>
				{showDetails ? (
					<HeaderPlatform
						CustomTitle={() => (
							<div>
								<div
									style={{
										display: "flex",
										padding: 16,
										margin: 0,
										gap: 10,
										flexDirection: "row",

										alignItems: "center",
									}}
								>
									<ReactSVG
										src={closeIcon}
										onClick={() => setShowDetails(false)}
									/>
									<p
										style={{
											fontSize: 24,
											fontWeight: 600,
											margin: 0,
										}}
									>
										Informazioni consulente
									</p>
								</div>
								<hr style={{ width: "100%", margin: 0 }} />
							</div>
						)}
					/>
				) : (
					<HeaderPlatform title={"Home"} />
				)}
			</Row>
			{userData?.cod_consultant ? (
				<Row
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}
				>
					<Col lg='9'>
						{!showDetails && (
							<Row>
								<Col
									sm='12'
									className='mb-4'
								>
									<p style={{ fontSize: 16, fontFamily: "", padding: 24 }}>
										Seleziona il servizio ed effettua il pagamento per ricevere
										la consulenza da parte del nostro esperto
									</p>
								</Col>
							</Row>
						)}
						{!breakpoint?.lg && (
							<Row onClick={() => setShowDetails(true)}>
								<RightSidebarMobile showAccordion={showDetails} />
							</Row>
						)}
						{!showDetails && (
							<Row>
								{serviceData.map((item, index) => (
									<Col
										md='6'
										className='mb-0'
										key={index}
									>
										<CardContainer
											title={item?.title_it}
											description={item?.description_it}
											buttonText={item?.button_it}
											link={"/service/" + item?.id}
											primaryAction={item.primary_button}
										/>
									</Col>
								))}
							</Row>
						)}
					</Col>

					<RightSidebar />
				</Row>
			) : (
				<Row
					style={{
						display: "flex",
						height: "80vh",
						textAlign: "center",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<Col
						md='8'
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							padding: 40,
							paddingBottom: 0,
							paddingTop: 0,
						}}
					>
						<span style={{ width: breakpoint?.lg ? "60%" : "100%" }}>
							A breve riceverai una mail che ti confermerà l’associazione con
							uno dei nostri consulenti dandoti modo di selezionare il servizio
							che meglio risponde ai tuoi bisogni.
						</span>
					</Col>
				</Row>
			)}
		</>
	);
};

export default HomePage;
