import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./customStyles.css";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import {
	selectSlotsData,
	setAvailabelSlotsSession,
} from "../../../features/availableSlots/availableSlotsSlices";
import ModalAlert from "../../../CommonElements/ModalAlert";
import { useNavigate } from "react-router-dom";

const localizer = momentLocalizer(moment);

const CustomDayHeader = ({ date }) => {
	const dayName = moment(date).format("dddd");
	const dayDate = moment(date).format("D MMMM");
	return (
		<div style={{ textAlign: "center", padding: "5px" }}>
			<div style={{ fontWeight: "normal" }}>{dayName}</div>
			<div style={{ fontWeight: "600", fontSize: "14px" }}>{dayDate}</div>
		</div>
	);
};

const MyCalendar = ({ currentWeek }) => {
	const dispatch = useDispatch();
	const dataEvent = useSelector(selectSlotsData);
	const [events, setEvents] = useState([]);
	const now = moment();
	const nextHour = now.clone().add(1, "hour");
	const meeting = useSelector((state) => state.availableSlots.meetingData);
	const [showModal, setShowModal] = useState(false);
	const navigate = useNavigate();

	const minTime = moment(currentWeek).hour(8).minute(0).toDate(); // Orario di inizio fisso alle 8:00
	const maxTime = moment(currentWeek).hour(18).minute(0).toDate(); // Orario di fine fisso alle 18:00

	const CustomEvent = ({ event }) => {
		const isOccupied = meeting?.includes(event.id);

		return (
			<div
				style={{
					backgroundColor: isOccupied ? "#ffe391" : "#ade9e5", // Colore diverso per gli eventi occupati
					height: "100%",
				}}
			></div>
		);
	};

	useEffect(() => {
		if (dataEvent) {
			const formattedEvents = dataEvent.map((event) => ({
				id: event.id,
				title: event.title,
				start: new Date(event.start_time || event.start),
				end: new Date(event.end_time || event.end),
			}));
			setEvents(formattedEvents);
		}
	}, [dataEvent]);

	const handleSelectSlot = ({ start, end }) => {
		const startTime = moment(start);
		const isToday = startTime.isSame(moment(), "day");

		// Impedisce la selezione di slot nel passato o per oggi prima dell'ora corrente +1 ora
		if (isToday && startTime.isBefore(nextHour)) {
			return; // Blocca l’aggiunta di uno slot se è oggi e prima di un’ora rispetto all’ora corrente
		}

		const newEvents = [];
		let current = new Date(start);

		while (current < end) {
			((current) => {
				const nextHour = new Date(current);
				nextHour.setHours(current.getHours() + 1);

				const isOverlapping = events.some((event) => {
					const eventStart = event.start.getTime();
					const eventEnd = event.end.getTime();
					const currentStart = current.getTime();
					const currentEnd = nextHour.getTime();

					return (
						(currentStart >= eventStart && currentStart < eventEnd) ||
						(currentEnd > eventStart && currentEnd <= eventEnd) ||
						(currentStart <= eventStart && currentEnd >= eventEnd)
					);
				});

				if (!isOverlapping) {
					newEvents.push({
						id: uuidv4(),
						title: "Disponibilità",
						start: new Date(current),
						end: nextHour,
					});
				}
			})(new Date(current));

			current.setHours(current.getHours() + 1);
		}

		const eventMerge = [...events, ...newEvents];
		setEvents(eventMerge);
		dispatch(setAvailabelSlotsSession(eventMerge));
	};

	const handleSelectEvent = (eventToDelete) => {
		if (meeting.includes(eventToDelete.id)) {
			setShowModal(true);
			return;
		}

		const newEvents = events.filter((event) => event.id !== eventToDelete.id);
		setEvents(newEvents);
		dispatch(setAvailabelSlotsSession(newEvents));
	};

	return (
		<>
			<Calendar
				localizer={localizer}
				events={events}
				startAccessor='start'
				endAccessor='end'
				style={{ height: 500 }}
				views={["week"]}
				defaultView='week'
				step={60} // Lunghezza di ogni slot in minuti
				timeslots={1} // Numero di slot per ciascuna ora
				date={currentWeek.toDate()}
				min={minTime} // Inizia sempre alle 8:00
				max={maxTime} // Termina sempre alle 18:00
				selectable={true}
				toolbar={false}
				onSelectSlot={handleSelectSlot}
				onSelectEvent={handleSelectEvent}
				components={{
					dayColumnHeader: CustomDayHeader,
					header: CustomDayHeader,
					event: CustomEvent,
				}}
			/>
			<ModalAlert
				showModal={showModal}
				okButtonTitle={"Vai all'appuntamento"}
				deleteButtonTitle={"Annulla"}
				onDelete={() => setShowModal(false)}
				onOk={() => navigate("/appointment")}
				setShowModal={setShowModal}
				BodyComponent={() => (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							textAlign: "center",
							gap: 5,
						}}
					>
						<p style={{ fontWeight: 600 }}>
							Lo slot selezionato è occupato da un meeting
						</p>
						<p>
							Prima di procedere alla rimozione della disponibilità dovrai
							annullare l'appuntamento
						</p>
					</div>
				)}
			/>
		</>
	);
};

export default MyCalendar;
