import React, { useState, useEffect } from "react";
import { Select } from "antd";
import provinceData from "./province.json"; // Assumi che il file JSON si trovi nella stessa cartella

const { Option } = Select;

const ProvinceSelect = ({
	language = "it",
	onChange,
	value,
	disabled,
	state,
}) => {
	const [options, setOptions] = useState([]);
	useEffect(() => {
		console.log(state);
	}, [state]);

	useEffect(() => {
		// Popola il Select in base alla lingua
		const formattedOptions = provinceData.map((province) => ({
			value: province.prefix,
			label: language === "it" ? province.name_it : province.name_chi,
		}));
		setOptions(formattedOptions);
	}, [language]);

	return (
		<Select
			style={{ height: 50, width: "100%" }}
			disabled={disabled}
			value={value}
			className='select'
			placeholder={language === "it" ? "Seleziona provincia" : "选择省份"}
			onChange={onChange}
		>
			{options.map((option) => (
				<Option
					key={option.value}
					value={option.value}
				>
					{option.label}
				</Option>
			))}
		</Select>
	);
};

export default ProvinceSelect;
