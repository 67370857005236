import client from "../index";

export const getServicesData = async () => {
	return client.get("/service");
};

export const getServiceData = async ({ id_consultant, id_service }) => {
	return client.get(`/service/${id_consultant}/${id_service}`);
};

export const getServiceDetails = async (cod_service) => {
	return client.get(`/service/${cod_service}`);
};

export const getConsultantAvailability = async (id_consultant) => {
	return client.get(`/slots/get-consultant/${id_consultant}`);
};
