import React from "react";
import { Card, CardBody, CardTitle, CardText, Button } from "reactstrap";
import questionIcon from "../../assets/images/sidebar/question.png";
const HelpCenterCard = () => {
	return (
		<Card
			className='text-center flex-column justify-content-center align-items-center'
			style={{
				borderRadius: "10px",
				backgroundColor: "#477673",
				marginTop: "20px",
				padding: "0 30px",
			}}
		>
			<div style={{}}>
				<img
					src={questionIcon}
					style={{ marginTop: -50 }}
					alt='question'
				/>
			</div>
			<CardTitle
				tag='h5'
				className='text-center flex-column justify-content-center align-items-center'
				style={{
					color: "white",
					marginBottom: "0.5px",
					fontSize: "16px",
					fontWeight: "500",
				}}
			>
				Help Center
			</CardTitle>
			<CardText
				style={{ color: "white", fontSize: "12px", marginBottom: "20px" }}
			>
				Hai difficoltà con la piattaforma? Contattaci e proveremo a risolvere i
				tuoi problemi.
			</CardText>
			<Button
				color='light'
				style={{
					color: "#4A6868",
					borderRadius: "5px",
					borderColor: "#8E6B00",
					width: "100%",
					height: "40px",
					marginBottom: "20px",
					cursor: "pointer",
					fontSize: "12px",
					zIndex: 9999,
				}}
				onClick={() => {
					window.open("mailto:info@taoista.eu", "_blank");
				}}
			>
				Contattaci
			</Button>
		</Card>
	);
};

export default HelpCenterCard;
