import React from "react";
import Setting from "../Components/Settings";
import SettingConsultant from "../Components/Settings Consultant";
import { useSelector } from "react-redux";
const SettingsPage = () => {
	const userType = useSelector((state) => state.user.type);

	return userType === "consultant" ? <SettingConsultant /> : <Setting />;
};

export default SettingsPage;
