import React, { useEffect } from "react";
import { H5 } from "../../AbstractElements";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Tabs } from "antd";

import P6 from "../../CommonElements/Headings/P5Element";
import General from "./Tab/General";
import PublicProfile from "./Tab/PublicProfile";
import Contract from "./Tab/Contract";

import Password from "./Tab/Password";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../features/profile/profileSlices";
import HeaderPlatform from "../../CommonElements/HeaderPlatform/index";
const SettingsIndex = () => {
	const dispatch = useDispatch();
	const userProfile = useSelector((state) => state.profile.data);

	const { TabPane } = Tabs;
	useEffect(() => {
		if (!userProfile?.name) dispatch(getProfile());
	}, []);

	return (
		<Card style={{ padding: 0 }}>
			<CardBody style={{ padding: 0 }}>
				<Row>
					<HeaderPlatform title='Impostazioni del profilo' />
				</Row>
			</CardBody>
			<Tabs defaultActiveKey='1'>
				<TabPane
					tab='Generali'
					key='1'
				>
					<General />
					{/* Inserisci qui il contenuto per la tab "Generali" */}
				</TabPane>
				<TabPane
					tab='Profilo Pubblico'
					key='2'
				>
					<PublicProfile />{" "}
					{/* Inserisci qui il contenuto per la tab "Dati di fatturazione" */}
				</TabPane>
				{/* 				<TabPane
					tab='Consenso informato'
					key='3'
				>
					<Accord />
				</TabPane> */}
				<TabPane
					tab='Condizioni contrattuali'
					key='4'
				>
					<Contract />
					{/* Inserisci qui il contenuto per la tab "Metodi di pagamento" */}
				</TabPane>
				<TabPane
					tab='Cambio password'
					key='5'
				>
					<Password />{" "}
					{/* Inserisci qui il contenuto per la tab "Cambio password" */}
				</TabPane>
			</Tabs>
		</Card>
	);
};

export default SettingsIndex;
