import React from "react";

const P6 = (props) => {
	return (
		<p
			style={{
				fontSize: 14,
				fontWeight: 500,
				color: "#001F24",
				...props.style,
			}}
			{...props.attrH1}
		>
			{props.children}
		</p>
	);
};

export default P6;
