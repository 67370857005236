import React, { useEffect } from "react";
import { Grid } from "antd";
import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";

const { useBreakpoint } = Grid;

const Header = ({ title, changeLanguage, CustomTitle }) => {
	const screens = useBreakpoint();
	const localLanguage = localStorage.getItem("surveyLanguage");

	useEffect(() => {
		if (changeLanguage) changeLanguage(localLanguage || "it");
	}, []);

	return (
		<div style={{ padding: 0 }}>
			{/* Renderizza il componente desktop per schermi medi e più grandi */}
			{screens.lg ? (
				<HeaderDesktop
					title={title}
					changeLanguage={changeLanguage}
					CustomTitle={CustomTitle}
				/>
			) : (
				<HeaderMobile
					title={title}
					CustomTitle={CustomTitle}
					changeLanguage={changeLanguage}
				/>
			)}
		</div>
	);
};

export default Header;
