import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardPayment from "./AddCard";
import { Row, Col, Grid } from "antd";
import moment from "moment";
import { Button, Radio } from "antd";
import { ReactSVG } from "react-svg";
import visaIcon from "../../../assets/images/icon/payment/visa.svg";
import amex from "../../../assets/images/icon/payment/amex.svg";
import mastercard from "../../../assets/images/icon/payment/mastercard.svg";
import trash from "../../../assets/images/icon/payment/trash.svg";
import walletAdd from "../../../assets/images/icon/wallet-add.svg";
import google_pay from "../../../assets/images/icon/payment/google_pay.svg";
import apple_pay from "../../../assets/images/icon/payment/apple_pay.svg";
import card from "../../../assets/images/icon/payment/card.svg";

import payment from "../../../assets/images/payment/payment.svg";
import P7 from "../../../CommonElements/Headings/P7Element";
import {
	setDefaultPaymentMethodAction,
	getPaymentMethodAction,
	deletePaymentMethodAction,
} from "../../../features/payment/paymentSlice";
import { P } from "../../../AbstractElements";
import useAvailablePaymentMethods from "./hookPayment";
import { CardBody, Card } from "reactstrap";

const { useBreakpoint } = Grid;

const PaymentForm = ({ radio = false, onPaymentMethodChange }) => {
	const availableMethods = useAvailablePaymentMethods();

	const dispatch = useDispatch();
	const screens = useBreakpoint();
	const paymentMethods = useSelector((state) => state.payment.paymentMethods);
	const [showAdd, setShowAdd] = useState(false);
	const [reload, setReload] = useState(0);
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
	const [valueAdd, setValueAdd] = useState(false);
	const stateCreation = useSelector((state) => state.payment.stateCreation);
	useEffect(() => {
		dispatch(getPaymentMethodAction());
	}, [dispatch, reload]);

	useEffect(() => {
		if (radio && paymentMethods?.length > 0) {
			const defaultPaymentMethod = paymentMethods.find(
				(paymentMethod) => paymentMethod.default
			);
			if (defaultPaymentMethod) {
				setSelectedPaymentMethod(defaultPaymentMethod.id_payment);
				if (onPaymentMethodChange) {
					onPaymentMethodChange(defaultPaymentMethod.id_payment);
				}
			}
		}
	}, [paymentMethods]);

	useEffect(() => {
		if (stateCreation === "success") {
			setReload((prev) => prev + 1);
			finishAdd();
		}
	}, [stateCreation]);

	const handlePaymentMethodChange = (id) => {
		setSelectedPaymentMethod(id);
		if (onPaymentMethodChange) {
			onPaymentMethodChange(id);
		}
	};

	const finishAdd = async () => {
		const result = dispatch(getPaymentMethodAction());
		result.then(() => {
			setReload((prev) => prev + 1);
			setShowAdd(false);
		});
	};

	const setDefaultPaymentMethod = async (id) => {
		const result = dispatch(setDefaultPaymentMethodAction(id));
		result.then(() => {
			setReload((prev) => prev + 1);
		});
	};

	const deletePaymentMethod = async (id) => {
		const result = dispatch(deletePaymentMethodAction(id));
		result.then(() => {
			setReload((prev) => prev + 1);
		});
	};

	return (
		<Row
			style={{
				display: "flex",
				gap: 20,
				justifyContent: "flex-start",
				width: "100%",
				marginLeft: 0,
				marginRight: 32,
			}}
		>
			{/* Mostra il messaggio se non ci sono metodi di pagamento */}
			{paymentMethods?.length === 0 && !showAdd && (
				<Col span={screens.md ? 16 : 24}>
					<p style={{ textAlign: "left" }}>
						Non hai ancora aggiunto metodi di pagamento
					</p>
				</Col>
			)}

			{/* Mostra i metodi di pagamento disponibili */}
			{!showAdd &&
				paymentMethods?.map((paymentMethod) => (
					<Col
						key={paymentMethod?.id_payment}
						span={screens.md ? 16 : 24}
						style={{
							display: "flex",
							alignItems: "center",
							border: "1px solid #000",
							height: "fit-content",
							padding: 10,
							borderRadius: 10,
							backgroundColor: "#fff",
						}}
					>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								width: "100%",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								{radio && (
									<Radio
										checked={selectedPaymentMethod === paymentMethod.id_payment}
										onChange={() =>
											handlePaymentMethodChange(paymentMethod.id_payment)
										}
										style={{ marginLeft: 10 }}
									/>
								)}
								<ReactSVG
									src={
										paymentMethod.type_card === "card"
											? paymentMethod.type === "visa"
												? visaIcon
												: paymentMethod.type === "amex"
												? amex
												: mastercard
											: paymentMethod.type_card === "google_pay"
											? google_pay
											: apple_pay
									}
									style={{ marginLeft: 10 }}
								/>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										marginLeft: 30,
									}}
								>
									<div
										style={{ gap: 20, display: "flex", flexDirection: "row" }}
									>
										{paymentMethod?.type_card === "card" ? (
											// first caracther to uppercase

											<h6>
												{paymentMethod?.type
													? paymentMethod.type.charAt(0).toUpperCase() +
													  paymentMethod.type.slice(1)
													: paymentMethod?.type_card}
											</h6>
										) : (
											<h6>
												{" "}
												{paymentMethod?.type_card === "google_pay"
													? "Google Pay"
													: "Apple Pay"}
											</h6>
										)}

										{paymentMethod?.type_card === "card" && (
											<h6>{moment(paymentMethod?.expired).format("MM-YY")}</h6>
										)}
									</div>
									<p>**** **** **** {paymentMethod?.last_4}</p>

									<p>
										{paymentMethod?.type_card !== "card" &&
											paymentMethod?.email}
									</p>
									{!radio && (
										<P7 style={{ marginBottom: 0, cursor: "pointer" }}>
											{paymentMethod?.default ? (
												"Default"
											) : (
												<span
													onClick={() =>
														setDefaultPaymentMethod(paymentMethod?.id_payment)
													}
												>
													Imposta come metodo predefinito
												</span>
											)}
										</P7>
									)}
								</div>
							</div>
							{!radio && (
								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										width: "10%",
										alignItems: "center",
									}}
								>
									<ReactSVG
										src={trash}
										style={{
											cursor: "pointer",
											position: "absolute",
											right: 20,
										}}
										onClick={() =>
											deletePaymentMethod(paymentMethod?.id_payment)
										}
									/>
								</div>
							)}
						</div>
					</Col>
				))}

			{/* Pulsante per aggiungere un metodo di pagamento */}
			{!showAdd && (
				<Col
					span={screens.md ? 16 : 24}
					onClick={() => setShowAdd(true)}
					style={{
						display: "flex",
						alignItems: "center",
						border: "1px solid #000",
						cursor: "pointer",
						height: "fit-content",
						padding: 20,
						borderRadius: 10,
						backgroundColor: "#fff",
					}}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<ReactSVG
								src={walletAdd}
								style={{ marginLeft: 10 }}
							/>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									marginLeft: 30,
								}}
							>
								<div style={{ gap: 0, display: "flex", flexDirection: "row" }}>
									<P7 style={{ marginBottom: 0 }}>
										Aggiungi un metodo di pagamento
									</P7>
								</div>
							</div>
						</div>
					</div>
				</Col>
			)}

			{/* Modale per aggiungere un nuovo metodo di pagamento */}
			{showAdd &&
				availableMethods.map((method, index) => {
					return method.val !== "card" ? (
						<Col
							key={index}
							span={screens.md ? 16 : 24}
							style={{
								display: "flex",
								alignItems: "center",
								border: "1px solid #000",
								borderRadius: 10,
								backgroundColor: "#fff",
							}}
						>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									cursor: "pointer",
									padding: 16,
								}}
							>
								<Radio
									checked={valueAdd === method?.val}
									value={method?.val}
									onClick={(e) => {
										setValueAdd(e.target.value);
										console.log(e.target.value);
									}}
								/>
								<ReactSVG
									src={
										method.val === "google_pay"
											? google_pay
											: method.val === "apple_pay"
											? apple_pay
											: card
									}
									style={{ marginLeft: 10 }}
								/>
								<P7 style={{ marginLeft: 20, marginBottom: 0 }}>
									{method.title}
								</P7>
							</div>
						</Col>
					) : (
						<CardPayment
							key={index}
							handleChange={(val) => {
								setValueAdd(val);
							}}
							valueAdd={valueAdd}
							finishAdd={finishAdd}
							setShowAdd={setShowAdd}
							reload={reload}
							setReload={setReload}
						/>
					);
				})}
			<Row
				style={{
					display: "flex",
					width: "100%",
					justifyContent: "flex-start",
					flexDirection: "column",
				}}
			>
				<ReactSVG src={payment} />
				<P7 style={{ marginLeft: 10, marginBottom: 0, color: "#737679" }}>
					La tua carta è al sicuro! <br />
					Tao non salva i dati ma vengono salvati e gestiti interamente da
					Stripe.
				</P7>
			</Row>
		</Row>
	);
};

export default PaymentForm;
