import React from "react";
import { Row, Button } from "reactstrap";
import { Select } from "antd";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import logo from "../../../../assets/images/survey/logo.svg";
import "./style.css";
import { useLocation, useRoutes } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const HeaderDesktop = ({ changeLanguage }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	return (
		<Row className='header-desktop'>
			<div className='header-desktop-container'>
				<ReactSVG
					src={logo}
					className='header-logo'
				/>
				<div className='header-options'>
					<p className='header-text'>{t("header.question")}</p>
					<Button
						className='access-button'
						onClick={() => navigate("/login")}
					>
						{t("header.login")}
					</Button>
					<Select
						className='custom-select'
						defaultValue='it'
						value={localStorage.getItem("surveyLanguage")}
						suffixIcon={<i className='fa fa-chevron-down custom-icon'></i>}
						onChange={(value) => changeLanguage(value)}
					>
						<Select.Option value='it'>ITA</Select.Option>
						<Select.Option value='ch'>CHI</Select.Option>
					</Select>
				</div>
			</div>
		</Row>
	);
};

export default HeaderDesktop;
