import client from "../index";

export const getListChat = async () => {
	return client.get("/chat");
};

export const getChatById = async (id) => {
	return client.get(`/chat/${id}`);
};

export const createMessage = async (body) => {
	return client.post(`/chat/${body?.id}`, body);
};

export const editArchiveChat = async (id) => {
	return client.put(`/chat/archive/${id}`);
};
