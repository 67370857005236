import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
	getMineAvailability,
	saveMineAvailability,
	getConsultantAvailability,
} from "../../api/available-slots";
import SweetAlert from "sweetalert2";
import { deleteAppointment } from "../../api/appointment";

export const getMineAvailabilityAction = createAsyncThunk(
	"available-slots/getMineAvailabilityAction",
	async (_, { rejectWithValue }) => {
		try {
			const response = await getMineAvailability(); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const deleteAppointmentAction = createAsyncThunk(
	"available-slots/deleteAppointmentAction",

	async (id, { rejectWithValue }) => {
		try {
			const response = await deleteAppointment(id); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getConsultantAvailabilityAction = createAsyncThunk(
	"available-slots/getConsultantAvailabilityAction",
	async (id, { rejectWithValue }) => {
		try {
			const response = await getConsultantAvailability(id); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const saveMineAvailabilityAction = createAsyncThunk(
	"available-slots/saveMineAvailabilityAction",
	async (body, { rejectWithValue }) => {
		try {
			const response = await saveMineAvailability(body); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const availableSlotsSlice = createSlice({
	name: "availableSlots",
	initialState: {
		loading: false,
		data: [],
		sessionData: [],
		meetingData: [],
		error: null,
	},
	reducers: {
		setAvailabelSlotsSession: (state, action) => {
			state.sessionData = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getMineAvailabilityAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getMineAvailabilityAction.fulfilled, (state, action) => {
				console.log(action.payload);
				state.data = action.payload.data;
				state.meetingData = action.payload.meeting?.map(
					(el) => el?.cod_available_slots
				);
			})
			.addCase(getMineAvailabilityAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				SweetAlert.fire({
					icon: "error",
					text: "Errore sconosciuto",
				});
			})

			.addCase(deleteAppointmentAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(deleteAppointmentAction.fulfilled, (state, action) => {
				state.data = action.payload.data;
			})
			.addCase(deleteAppointmentAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				SweetAlert.fire({
					icon: "error",
					text: "Errore sconosciuto",
				});
			})

			.addCase(getConsultantAvailabilityAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getConsultantAvailabilityAction.fulfilled, (state, action) => {
				state.data = action.payload.data;
			})
			.addCase(getConsultantAvailabilityAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				SweetAlert.fire({
					icon: "error",
					text: "Errore sconosciuto",
				});
			})

			.addCase(saveMineAvailabilityAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(saveMineAvailabilityAction.fulfilled, (state, action) => {
				state.data = action.payload.data;
				SweetAlert.fire({
					icon: "success",
					text: action.payload.message.it || "Disponibilità aggiornate",
				});
			})
			.addCase(saveMineAvailabilityAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				SweetAlert.fire({
					icon: "error",
					text: "Errore sconosciuto",
				});
			});
	},
});
export const { setAvailabelSlotsSession } = availableSlotsSlice.actions;
export const selectSlotsData = (state) => state.availableSlots.data;
export const selectSlotsDataSession = (state) =>
	state.availableSlots.sessionData;
export default availableSlotsSlice.reducer;
