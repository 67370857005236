import React, { useEffect, useState } from "react";
import { Grid, Row, Spin } from "antd";
import Payment from "../../../../Components/Settings/Tab/Payment";
import { Button } from "reactstrap";
import { useSelector } from "react-redux";

import PaymentSuccess from "./PaymentSuccess";
import { ArrowLeftCircle, ArrowRightCircle, Loader } from "react-feather";

const RenderingPayment = ({
	radio,
	setPaymentMethod,
	setCurrentStep,
	handleDoPayment,
	currentStep,
}) => {
	const [paymentMethodId, setPaymentMethodId] = useState(null);
	const statusPayment = useSelector((state) => state.payment.status);
	const [loading, setLoading] = useState(false);
	const paymentLoading = useSelector((state) => state.payment.loading);

	useEffect(() => {
		setLoading(paymentLoading);
	}, [paymentLoading]);

	const breakpoint = Grid.useBreakpoint();

	return statusPayment === "doTo" ? (
		<Row style={{ width: "100%" }}>
			<Payment
				radio={radio}
				onPaymentMethodChange={(val) => {
					setPaymentMethodId(val);
					setPaymentMethod(val);
				}}
			/>
			<Row
				style={{
					display: "flex",
					justifyContent: breakpoint?.lg ? "flex-end" : "space-between",
					marginBottom: 40,
					marginTop: 40,
					width: "100%",
				}}
			>
				<Button
					color='white'
					style={{
						height: "48px",
						borderRadius: "10px",
						display: "flex",
						alignItems: "center",
						border: "1px solid #8E6B00",
						width: "150px",
						marginRight: "32px",
					}}
					className='mr-3'
					onClick={() => {
						setCurrentStep((prev) => prev - 1);
					}}
				>
					<ArrowLeftCircle
						size={20}
						className='ml-4'
						style={{ marginRight: "8px" }}
						color='#003F49'
					/>
					<span style={{ fontSize: 16, fontWeight: 600 }}>Indietro</span>
				</Button>
				<Button
					color='primary'
					style={{
						height: "48px",
						width: "150px",
						border: "none",
						borderRadius: "10px",
						display: "flex",
						fontSize: 16,
						alignItems: "center",
						justifyContent: "center",
					}}
					disabled={loading || !paymentMethodId}
					onClick={() => {
						setLoading(true);
						handleDoPayment();
					}}
				>
					<span style={{ fontSize: 16, fontWeight: 600 }}>Procedi</span>
					<ArrowRightCircle
						size={20}
						className='ml-4'
						style={{ marginLeft: "8px" }}
					/>
				</Button>
			</Row>
		</Row>
	) : loading ? (
		<Row
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				minHeight: "80vh", // Altezzo minima per occupare lo spazio
				width: "100%",
			}}
		>
			<Spin
				size='large'
				style={{
					fontSize: 80, // Rende lo spinner grande
				}}
				indicator={<Loader size={80} />}
			/>
		</Row>
	) : (
		statusPayment === "success" && (
			<Row
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					minHeight: "80vh",
				}}
			>
				<PaymentSuccess />
			</Row>
		)
	);
};

export default RenderingPayment;
