import React, { useEffect } from "react";
import { Col, Row, Card, CardBody, Button } from "reactstrap";
import { ArrowLeftCircle, ArrowRightCircle } from "react-feather"; // Importa le icone che vuoi utilizzare
import { useNavigate, useParams } from "react-router-dom";
import RightSidebar from "../../commonLayout/RightSideBar";
import P2 from "../../../CommonElements/Headings/P2Element";
import P6 from "../../../CommonElements/Headings/P6Element";
import { useDispatch, useSelector } from "react-redux";
import {
	getServiceAction,
	getServiceDetailsAction,
	getServicesAction,
} from "../../../features/service/serviceSlice";
import HeaderPlatform from "../../../CommonElements/HeaderPlatform/";
import { Grid } from "antd";
// Componente per le Card dei servizi
const CardContainer = ({ title, description }) => {
	return (
		<Card
			style={{
				borderRadius: "10px",
				border: "1px solid #000",
				width: "100%",
			}}
		>
			<CardBody>
				<p style={{ fontSize: 16, fontFamily: "Exo 2", fontWeight: 600 }}>
					{title}
				</p>
				<p style={{ fontSize: 13, fontFamily: "Exo 2" }}>{description}</p>
			</CardBody>
		</Card>
	);
};

const CardStep = ({ title, description }) => {
	return (
		<Card
			style={{
				borderRadius: "10px",
				height: "133px",
				width: "100%",
				backgroundColor: "#F7F8F8",
			}}
		>
			<CardBody
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",
				}}
			>
				<P6>{title}</P6>
				<P6>{description}</P6>
			</CardBody>
		</Card>
	);
};

const HomePage = () => {
	const navigator = useNavigate();
	const { id } = useParams();
	const dispatch = useDispatch();
	const serviceData = useSelector((state) => state.service.services);
	const consultantData = useSelector((state) => state.service.consultant);
	const singleService = useSelector((state) => state.service.singleService);
	const stepService = useSelector((state) => state.service.stepService);
	const breakpoint = Grid.useBreakpoint();

	useEffect(() => {
		if (serviceData?.length) {
			dispatch(
				getServiceAction({ id_consultant: consultantData?.id, id_service: id })
			);
		}
	}, [serviceData]);

	useEffect(() => {
		dispatch(getServiceDetailsAction(id));
	}, []);

	useEffect(() => {
		if (!serviceData?.length) {
			dispatch(getServicesAction());
		}
	}, []);

	const formService = useSelector((state) => state.service.formService);

	return (
		<>
			<Row style={{ backgroundColor: "#fff" }}>
				<HeaderPlatform title={"Home"} />
			</Row>
			<Row
				style={{
					display: "flex",
					flexDirection: "row",
					backgroundColor: "#fff",
					justifyContent: "space-between",
					marginRight: 32,
					marginTop: 32,
				}}
				className='mx-4'
			>
				<Col
					lg='9'
					style={{ marginRight: 32 }}
				>
					<Row>
						<Col
							sm='12'
							className='mb-4'
						>
							<p style={{ fontSize: 16, fontWeight: 600 }}>
								{singleService?.title_it}
							</p>
						</Col>
						<p
							style={{ fontSize: 16 }}
							className='mb-4'
						>
							{singleService?.description_it}
						</p>
						{stepService?.length > 0 && <P2>Come ti aiutiamo</P2>}
					</Row>
					<Row>
						{stepService?.map((item, index) => (
							<Col
								md='6'
								className='mb-0 mt-4'
								key={index}
							>
								<CardContainer
									title={index + 1 + ". " + item.title_it}
									description={item.description_it}
									buttonText={""}
									link={"#"}
									primaryAction={""}
								/>
							</Col>
						))}
					</Row>
					{formService?.length > 0 && <P2>Come funziona</P2>}
					<Row>
						{formService.map((item, index) => (
							<Col
								md='4'
								className='mb-0 mt-4'
								key={index}
							>
								<CardStep
									title={"Step: " + item.order}
									description={item.title_it}
									buttonText={item.buttonText}
									link={item.link}
									primaryAction={item.primaryAction}
								/>
							</Col>
						))}
					</Row>
					<P2>Modalità e costi</P2>
					<p
						style={{ fontSize: 16, width: "100%" }}
						className='mb-4'
					>
						{singleService?.mode_it}
					</p>
					<P2>Il servizio ha un costo a domanda di {singleService?.price}€</P2>
					<Row
						style={{
							display: "flex",
							justifyContent: breakpoint?.lg ? "flex-end" : "space-between", // Posiziona i bottoni ai lati opposti
							marginBottom: 24,
							gap: 20,
						}}
					>
						<Button
							color='white'
							style={{
								height: "48px",
								borderRadius: "10px",
								display: "flex",
								alignItems: "center",
								border: "1px solid #8E6B00",
								width: "150px",
							}}
							onClick={() => navigator("/home")} // Torna indietro alla pagina precedente
						>
							<ArrowLeftCircle
								size={20}
								className='ml-4'
								style={{ marginRight: "8px" }}
								color='#003F49'
							/>
							<span style={{ fontSize: 16, fontWeight: 600 }}>Indietro</span>
						</Button>

						<Button
							color='primary'
							style={{
								height: "48px",
								width: "150px",
								border: "none",
								borderRadius: "10px",
								display: "flex",
								fontSize: 16,
								alignItems: "center",
								justifyContent: "center",
							}}
							onClick={() => navigator(`/service/${id}/wizard`)}
						>
							<span style={{ fontSize: 16, fontWeight: 600 }}>Procedi</span>
							<ArrowRightCircle
								size={20}
								className='ml-4'
								style={{ marginLeft: "8px" }}
							/>
						</Button>
					</Row>
				</Col>
				<RightSidebar />
			</Row>
		</>
	);
};

export default HomePage;
