import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
	const [login, setLogin] = useState(false);
	const [authenticated, setAuthenticated] = useState(false);
	const token = localStorage.getItem("taoToken");

	useEffect(() => {
		if (token) {
			setAuthenticated(true);
			setLogin(true);
		} else {
			setAuthenticated(false);
			setLogin(false);
		}
		localStorage.setItem("authenticated", !!token);
		setLogin(!!token);
	}, [setLogin]);

	return !!token ? (
		<Outlet />
	) : (
		<Navigate
			exact
			to={`${process.env.PUBLIC_URL}/survey`}
		/>
	);
};

export default PrivateRoute;
