import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import CardContainer from "./Card";
import { useDispatch, useSelector } from "react-redux";
import { getMineAppointmentAction } from "../../features/profile/profileSlices";
import dayjs from "dayjs"; // per la gestione delle date
import HeaderPlatform from "../../CommonElements/HeaderPlatform/index";

const Appointment = () => {
	const dispatch = useDispatch();
	const appointmentData = useSelector((state) => state.profile.appointment);

	useEffect(() => {
		dispatch(getMineAppointmentAction());
	}, [dispatch]);

	// Raggruppa appuntamenti per data
	const groupedAppointments = appointmentData.reduce((acc, item) => {
		const date = dayjs(item.start_time).format("D MMMM YYYY");
		if (!acc[date]) acc[date] = [];
		acc[date].push(item);
		return acc;
	}, {});

	return (
		<div style={{ padding: 0 }}>
			<HeaderPlatform title='Appuntamenti' />
			{Object.keys(groupedAppointments)?.length === 0 && (
				<Row>
					<Col sm='12'>
						<p
							style={{
								fontSize: 20,
								fontWeight: 600,
								marginTop: 32,
								marginBottom: 16,
							}}
						>
							Non hai appuntamenti
						</p>
					</Col>
				</Row>
			)}
			{Object.keys(groupedAppointments).map((date) => (
				<Row key={date}>
					<Col sm='12'>
						<p
							style={{
								fontSize: 20,
								fontWeight: 600,
								marginTop: 32,
								marginBottom: 16,
								paddingLeft: 16,
							}}
						>
							{date}
						</p>
					</Col>
					{groupedAppointments[date].map((item, index) => (
						<Col
							md='6'
							key={index}
							className='mb-4'
						>
							<CardContainer
								item={{
									name: item.consultant,
									hourStart: dayjs(item.start_time).format("HH:mm"),
									hourEnd: dayjs(item.end_time).format("HH:mm"),
									serviceTitle: item.service,
									serviceDescription: item.service_description,
									link: item?.link,
									id: item.id,
								}}
								directLink={true}
							/>
						</Col>
					))}
				</Row>
			))}
		</div>
	);
};

export default Appointment;
