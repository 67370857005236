import client from "../index";

export const doLogin = async (body) => {
	return client.post("/auth/login", body);
};

export const verifyUseToken = async (token) => {
	return client.get(`/auth/verify/${token}`);
};

export const insertNewToken = async (body) => {
	return client.post("/auth/insertNewToken", body);
};

export const loginWithToken = async (body) => {
	return client.get("/auth/loginToken", body);
};
