import React, { useState, useEffect } from "react";
import { Select } from "antd";
import nationData from "./state.json"; // Assumi che il file JSON si trovi nella stessa cartella

const { Option } = Select;

const NationSelect = ({ language = "it", onChange, value, disabled }) => {
	const [options, setOptions] = useState([]);
	const [filteredOptions, setFilteredOptions] = useState([]);

	useEffect(() => {
		// Popola il Select in base alla lingua
		const formattedOptions = nationData.map((nation) => ({
			value: nation.code,
			label: language === "it" ? nation.name_it : nation.name_chi,
		}));

		console.log(formattedOptions);
		setOptions(formattedOptions);
		setFilteredOptions(formattedOptions); // Inizialmente le opzioni filtrate sono uguali a quelle originali
	}, [language]);

	// Funzione per filtrare i risultati della ricerca
	const searchValue = (val) => {
		const lowerCaseVal = val.toLowerCase();
		const filtered = options.filter((option) =>
			option.label.toLowerCase().includes(lowerCaseVal)
		);
		setFilteredOptions(filtered);
	};

	return (
		<Select
			style={{ height: 50, width: "100%" }}
			value={value}
			className='select'
			disabled={disabled}
			placeholder={language === "it" ? "Seleziona nazione" : "选择国家"}
			onChange={onChange}
			onSearch={searchValue} // Usa la funzione di ricerca
			showSearch
		>
			{filteredOptions.map((option) => (
				<Option
					key={option.value}
					value={option.value}
				>
					{option.label}
				</Option>
			))}
		</Select>
	);
};

export default NationSelect;
