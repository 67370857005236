import React from "react";
import { Table, Tag, Button } from "antd";
import { FaRegSmile } from "react-icons/fa";
import { Pagination } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/it";
import P6 from "../../../CommonElements/Headings/P6Element";
import { ReactSVG } from "react-svg";
import userIcon from "../../../assets/images/icon/userprofile.svg";
import { useNavigate } from "react-router-dom";
import { setSingleUser } from "../../../features/summary-service/summaryServiceSlices";

// Imposta la lingua italiana per moment
moment.locale("it");

// Funzione per formattare la data con il mese iniziale maiuscola
const formatDate = (date) => {
	const formattedDate = moment(date).format("DD MMMM YYYY");
	console.log(formattedDate);
	const spliced = formattedDate.split(" ");
	const month = spliced[1];
	spliced[1] = month.charAt(0).toUpperCase() + month.slice(1);
	return spliced.join(" ");
};

// Colonne della tabella

// Componente tabella con paginazione e layout
const DataTableUser = () => {
	const dispatch = useDispatch();

	const columns = [
		{
			title: "Elenco utenti",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Anni",
			dataIndex: "age",
			key: "age",
		},
		{
			title: "Genere",
			dataIndex: "gender",
			key: "gender",
			render: (text) => <span>{text === "male" ? "Maschio" : "Femmina"}</span>,
		},
		{
			title: "Stato",
			dataIndex: "status",
			key: "status",
			render: (status, record) => (
				<Tag
					style={{
						color: "#000",
						padding: "10px",
						borderRadius: "10px",
					}}
					color={record.servizi_attivi?.length > 0 ? "#ACE9E5" : "#FCF8F0"}
				>
					<P6 style={{ margin: 0 }}>
						{record.servizi_attivi?.length > 0 ? "Attivo" : "Archiviato"}
					</P6>
				</Tag>
			),
		},
		{
			title: "Servizio attivo",
			dataIndex: "servizi_attivi",
			key: "servizi_attivi",
			render: (record, text) => {
				console.log(record, "jack");
				return (
					<span>{record?.map((el) => el?.short_name_it)?.join(", ")}</span>
				);
			},
		},
		{
			title: "Primo contatto",
			dataIndex: "primo_contatto",
			key: "primo_contatto",
			render: (primo_contatto) => <span>{formatDate(primo_contatto)}</span>,
		},
		{
			title: "Ultimo contatto",
			dataIndex: "ultimo_contatto",
			key: "ultimo_contatto",
			render: (ultimo_contatto) => <span>{formatDate(ultimo_contatto)}</span>,
		},
		{
			title: "Azioni",
			key: "actions",
			render: (record, text) => (
				<Button
					onClick={() => {
						dispatch(setSingleUser(record));
						navigator(`/clients-details/${record?.id_user}`);
					}}
					icon={
						<ReactSVG
							src={userIcon}
							size={20}
						/>
					}
				/>
			),
		},
	];

	const navigator = useNavigate();
	const data = useSelector((state) => state.summaryService.data);
	return (
		<div style={{ padding: "20px" }}>
			<Table
				columns={columns}
				dataSource={data}
				locale={"it-IT"}
				pagination={{
					pageSize: 10,
				}}
			/>
		</div>
	);
};

export default DataTableUser;
