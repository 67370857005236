import React from "react";
import { Row, Col, Button } from "reactstrap";
import taskAlt from "../../../../assets/images/icon/payment/task_alt.svg";
import { ReactSVG } from "react-svg";
import InvoicePayment from "./InvoiceForm";
import P6 from "../../../../CommonElements/Headings/P6Element";
import P2 from "../../../../CommonElements/Headings/P2Element";
import H5 from "../../../../CommonElements/Headings/H5Element";
import H6 from "../../../../CommonElements/Headings/H6Element.jsx";

import { useSelector } from "react-redux";
import { ArrowLeftCircle, ArrowRightCircle } from "react-feather";
import { useNavigate } from "react-router-dom";

const InvoiceSuccess = () => {
	const invoiceID = useSelector((state) => state.payment.invoiceID);
	const typeService = useSelector(
		(state) => state.service?.singleService?.type
	);
	const consultantData = useSelector(
		(state) => state.user.consultantData?.profile
	);
	const navigate = useNavigate();
	const returnLink = (val) => {
		if (typeService === "slot") return "/appointment";
		else return "/chat";
	};

	const SuccessSlot = () => {
		return (
			<Row>
				<Col>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
							height: "60vh",
							width: "80%",
							margin: "auto",
						}}
					>
						<ReactSVG src={taskAlt} />
						<H5 style={{ marginTop: 20 }}>
							Hai correttamente selezionato lo slot orario per la consulenza con{" "}
							{consultantData?.name}. Ora potrai visualizzarla all’interno della
							sezione degli appuntamenti per collegarti alla call con{" "}
							{consultantData?.name}*. <br />
							<br />
							<H6>
								*Sarà possibile collegarti alla call qualche ora prima
								dell’appuntamento.
							</H6>
						</H5>
					</div>
				</Col>

				<Row
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginBottom: 40,
					}}
				>
					<Button
						color='white'
						style={{
							height: "48px",
							borderRadius: "10px",
							display: "flex",
							alignItems: "center",
							border: "1px solid #8E6B00",
							width: "150px",
							marginRight: "32px",
						}}
						className='mr-3'
					>
						<ArrowLeftCircle
							size={20}
							className='ml-4'
							style={{ marginRight: "8px" }}
							color='#003F49'
						/>
						<span style={{ fontSize: 16, fontWeight: 600 }}>Indietro</span>
					</Button>
					<Button
						color='primary'
						style={{
							height: "48px",
							width: "150px",
							border: "none",
							borderRadius: "10px",
							display: "flex",
							fontSize: 16,
							alignItems: "center",
							justifyContent: "center",
						}}
						onClick={() => navigate(returnLink(typeService))}
					>
						<span style={{ fontSize: 16, fontWeight: 600 }}>Procedi</span>
						<ArrowRightCircle
							size={20}
							className='ml-4'
							style={{ marginLeft: "8px" }}
						/>
					</Button>
				</Row>
			</Row>
		);
	};

	const SuccessChat = () => {
		return (
			<Row>
				<Col>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
							height: "60vh",
							width: "100%",
							margin: "auto",
						}}
					>
						<ReactSVG src={taskAlt} />
						<div
							style={{
								display: "flex",
								gap: 50,
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<H5
								style={{ marginTop: 20, textAlign: "center", marginBottom: 0 }}
							>
								Hai compilato tutte le informazioni correttamente.
							</H5>
							<H6 style={{ textAlign: "center" }}>
								Ora puoi accedere alla chat per porre la tua domanda al
								consulente.
							</H6>
						</div>
					</div>
				</Col>

				<Row
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginBottom: 40,
					}}
				>
					<Button
						color='white'
						style={{
							height: "48px",
							borderRadius: "10px",
							display: "flex",
							alignItems: "center",
							border: "1px solid #8E6B00",
							width: "150px",
							marginRight: "32px",
						}}
						className='mr-3'
					>
						<ArrowLeftCircle
							size={20}
							className='ml-4'
							style={{ marginRight: "8px" }}
							color='#003F49'
						/>
						<span style={{ fontSize: 16, fontWeight: 600 }}>Indietro</span>
					</Button>
					<Button
						color='primary'
						style={{
							height: "48px",
							width: "150px",
							border: "none",
							borderRadius: "10px",
							display: "flex",
							fontSize: 16,
							alignItems: "center",
							justifyContent: "center",
						}}
						onClick={() => navigate(returnLink(typeService))}
					>
						<span style={{ fontSize: 16, fontWeight: 600 }}>Procedi</span>
						<ArrowRightCircle
							size={20}
							className='ml-4'
							style={{ marginLeft: "8px" }}
						/>
					</Button>
				</Row>
			</Row>
		);
	};

	return <>{typeService === "slot" ? <SuccessSlot /> : <SuccessChat />}</>;
};

export default InvoiceSuccess;
