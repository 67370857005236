import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { DatePicker, Form, Input, Select, ConfigProvider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
	getProfile,
	updateProfile,
} from "../../../features/profile/profileSlices";
import SelectState from "../../Common/Component/StateSelect";
import SelectProvince from "../../Common/Component/ProviceSelect";
import locale from "antd/es/date-picker/locale/it_IT";
import moment, { isDate } from "moment";
import "moment/locale/it";
import "antd/dist/reset.css";
import { ReactSVG } from "react-svg";
import { Button } from "antd";
import clickboardThik from "../../../assets/images/clipboard-tick.svg";
import clicpboardClose from "../../../assets/images/clipboard-close.svg";
import dayjs from "dayjs";
import _ from "lodash";
const General = () => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const userProfile = useSelector((state) => state.profile.data);
	const [provinceBirth, setProvinceBirth] = useState(false);
	const [provinceAddress, setProvinceAddress] = useState(false);

	const handleSubmit = () => {
		const values = form.getFieldsValue();
		values.date_of_birth = dayjs(values.date_of_birth).format("YYYY-MM-DD");

		dispatch(updateProfile(values));
	};

	const [formIsChanged, setFormIsChanged] = useState(false);

	useEffect(() => {
		if (!userProfile?.name) dispatch(getProfile());
	}, []);

	useEffect(() => {
		setProvinceBirth(userProfile?.place_birth === "IT");
		setProvinceAddress(userProfile?.state_address === "IT");
	}, [userProfile]);

	useEffect(() => {
		if (userProfile) {
			form.setFieldsValue({
				...userProfile,
				date_of_birth: dayjs(userProfile?.date_of_birth).isValid()
					? dayjs(userProfile.date_of_birth)
					: undefined,
			});
		}
	}, [userProfile, form]);

	return (
		<Form
			form={form}
			onFinish={handleSubmit}
			className='mt-3 px-2'
			layout='vertical' // Imposta il layout verticale per avere la label sopra
		>
			<Row>
				<Col md='4'>
					<Form.Item
						name='name'
						label='Nome'
					>
						<Input
							required
							placeholder='Nome'
							style={{ height: 50 }}
						/>
					</Form.Item>
				</Col>
				<Col md='4'>
					<Form.Item
						name='surname'
						label='Cognome'
					>
						<Input
							placeholder='Cognome'
							style={{ height: 50 }}
						/>
					</Form.Item>
				</Col>
				<Col md='2'>
					<Form.Item
						name='gender'
						label='Sesso'
						initialValue={userProfile?.gender}
					>
						<Select style={{ height: 50 }}>
							<Select.Option value={"male"}>Uomo</Select.Option>
							<Select.Option value={"female"}>Donna</Select.Option>
						</Select>
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<Col md='3'>
					<Form.Item
						name='date_of_birth'
						label='Data di nascita'
					>
						<DatePicker
							placeholder='Seleziona una data'
							style={{ height: 50, width: "100%" }}
							format='DD-MM-YYYY'
							minDate={dayjs().subtract(18, "year")}
							disabledDate={(current) =>
								current && current > dayjs().subtract(18, "year")
							}
						/>
					</Form.Item>
				</Col>
				<Col md='3'>
					<Form.Item
						name='place_birth'
						label='Luogo di nascita'
					>
						<SelectState
							value={() => form.getFieldValue("place_birth")}
							onChange={(val) => {
								setProvinceBirth(val === "IT");
								form.setFieldsValue({ place_birth: val });
							}}
						/>
					</Form.Item>
				</Col>
				{provinceBirth && (
					<Col md='3'>
						<Form.Item
							name='province_birth'
							label='Provincia'
						>
							<SelectProvince
								value={() => form.getFieldValue("province_birth")}
								onChange={(val) => {
									form.setFieldsValue({ province_birth: val });
								}}
							/>
						</Form.Item>
					</Col>
				)}
			</Row>
			<Row>
				<Col md='4'>
					<Form.Item
						name='address'
						label='Indirizzo'
					>
						<Input
							placeholder='Indirizzo'
							style={{ height: 50 }}
						/>
					</Form.Item>
				</Col>
				<Col md='2'>
					<Form.Item
						name='cap_address'
						label='CAP'
					>
						<Input
							type='number'
							placeholder='CAP'
							style={{ height: 50 }}
						/>
					</Form.Item>
				</Col>
				<Col md='3'>
					<Form.Item
						name='state_address'
						label='Stato'
					>
						<SelectState
							value={() => form.getFieldValue("state_address")}
							onChange={(val) => {
								form.setFieldsValue({ state_address: val });
								setProvinceAddress(val === "IT");
							}}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<Col md='4'>
					<Form.Item
						name='city_address'
						label='Città'
					>
						<Input
							placeholder='Città'
							style={{ height: 50 }}
						/>
					</Form.Item>
				</Col>

				{provinceAddress && (
					<Col md='2'>
						<Form.Item
							name='province_address'
							label='Provincia'
						>
							<SelectProvince
								value={() => form.getFieldValue("province_address")}
								onChange={(val) =>
									form.setFieldsValue({
										province_address: val,
									})
								}
							/>
						</Form.Item>
					</Col>
				)}
			</Row>
			<Row>
				<Col md='4'>
					<Form.Item
						name='email'
						label='Email'
					>
						<Input
							type='email'
							placeholder='Email'
							style={{ height: 50 }}
							disabled
						/>
					</Form.Item>
				</Col>
				<Col md='4'>
					<Form.Item
						name='phone'
						label='Telefono'
					>
						<Input
							type='tel'
							placeholder='Scrivi il tuo numero di telefono'
							style={{ height: 50, width: "80%" }}
							onKeyPress={(event) => {
								// Consente solo l'inserimento di numeri
								if (!/[0-9]/.test(event.key)) {
									event.preventDefault();
								}
							}}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<div
					className='d-flex justify-content-end'
					style={{ width: "100%", gap: 40 }}
				>
					<Button
						style={{
							backgroundColor: "#fff",
							color: "#477673",
							border: "1px solid #8E6B00",
							borderRadius: 10,
							height: 50,
						}}
						icon={<ReactSVG src={clicpboardClose} />}
					>
						Annulla
					</Button>

					<Button
						style={{
							backgroundColor: "#477673",
							color: "#fff",
							height: 50,
							borderRadius: 10,
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
						}}
						onClick={handleSubmit}
					>
						Conferma <ReactSVG src={clickboardThik} />
					</Button>
				</div>
			</Row>
		</Form>
	);
};

export default General;
