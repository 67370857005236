import React, { useState } from "react";
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import { ReactSVG } from "react-svg";
import folderOpen from "../../../assets/images/icon/folder-open.svg";
import addCircle from "../../../assets/images/icon/add-circle.svg";
import { Typography } from "antd";
const { Text } = Typography;

const UploadCard = ({ field, onChange }) => {
	const [previewUrl, setPreviewUrl] = useState("");

	const handleFileChange = (e) => {
		const selectedFile = e.target.files[0];

		if (selectedFile) {
			const objectUrl = URL.createObjectURL(selectedFile);
			setPreviewUrl(objectUrl);

			// Passa il file selezionato al componente genitore tramite la callback onChange
			if (onChange) {
				onChange(selectedFile);
			}
		}
	};

	return (
		<Card
			className='border-dashed'
			style={{ borderRadius: "8px", margin: 0 }}
		>
			<CardBody>
				<div
					style={{
						border: "2px dashed #477673",
						borderRadius: "8px",
						padding: "20px",
						textAlign: "center",
						cursor: "pointer",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "200px",
						position: "relative",
					}}
				>
					<input
						type='file'
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							opacity: 0,
							cursor: "pointer",
						}}
						onChange={handleFileChange}
					/>
					{previewUrl ? (
						<img
							src={previewUrl}
							alt='Preview'
							style={{
								maxWidth: "100%",
								maxHeight: "100%",
								borderRadius: "8px",
								objectFit: "contain",
							}}
						/>
					) : (
						<>
							<ReactSVG src={folderOpen} />
							<ReactSVG src={addCircle} />
						</>
					)}
				</div>

				<CardSubtitle
					className='mb-2 mt-2 text-muted'
					style={{ color: "#477673 !important" }}
				>
					*{field?.placeholder_it}
				</CardSubtitle>
			</CardBody>
		</Card>
	);
};

export default UploadCard;
