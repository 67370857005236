import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import taskAlt from "../../../../assets/images/icon/payment/task_alt.svg";
import { ReactSVG } from "react-svg";
import InvoicePayment from "./InvoiceForm";
import P6 from "../../../../CommonElements/Headings/P6Element";
import P2 from "../../../../CommonElements/Headings/P2Element";
import H5 from "../../../../CommonElements/Headings/H5Element";
import { useSelector } from "react-redux";
import InvoiceSuccess from "./InvoiceSuccess.jsx";
const PaymentSuccess = () => {
	const invoiceID = useSelector((state) => state.payment.invoiceID);
	const typeService = useSelector((state) => state.service?.singleService?.id);

	return (
		<Row>
			<Col>
				{!invoiceID && (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
							height: "20vh",
						}}
					>
						<ReactSVG src={taskAlt} />
						<H5 style={{ marginTop: 20 }}>
							Pagamento effettuato con successo.
						</H5>
					</div>
				)}
				{!invoiceID ? <InvoicePayment /> : <InvoiceSuccess />}
			</Col>
		</Row>
	);
};

export default PaymentSuccess;
