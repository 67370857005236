import React, { useEffect, useState } from "react";

import { Button, InputNumber } from "antd";
import { Radio } from "antd";

import { Input } from "antd";
import { Col, Row } from "reactstrap";
import { ReactSVG } from "react-svg";
import usersquare from "../../../assets/images/survey/user-square.svg";
import smsnotification from "../../../assets/images/survey/sms-notification.svg";
import colorswatch from "../../../assets/images/survey/color-swatch.svg";
import "../style.css";
import Checkbox from "antd/es/checkbox/Checkbox";
import {
	saveAnswerApi,
	saveUserData,
	setChangeEmail,
} from "../../../features/survey/surveySlices";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const steps = [
	{
		description: "complete.steps.0.description",

		icon: usersquare,
	},
	{
		description: "complete.steps.1.description",

		icon: smsnotification,
	},
	{
		description: "complete.steps.2.description",

		icon: colorswatch,
	},
];

const Step1 = ({ goBack }) => {
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const answers = useSelector((state) => state.survey.answers);
	const success = useSelector((state) => state.survey.success);
	const changeEmail = useSelector((state) => state.survey.changeEmail);
	const [isLoading, setIsLoading] = useState(false);

	const handleSignup = () => {
		if (password !== confirmPassword) {
			alert("Le password non corrispondono");
			return;
		}
		setIsLoading(true);
		const session = localStorage.getItem("surveySessionId");
		dispatch(
			saveUserData({
				name,
				email,
				password,
				acceptsPromotions,
				session,
			})
		);

		localStorage.setItem(
			"userData",
			JSON.stringify({ name, email, password, acceptsPromotions, session })
		);

		dispatch(
			saveAnswerApi({
				answers,
				name,
				password,
				email,
				marketing_consent: acceptsPromotions,
				session,
				language: i18n.language,
			})
		);
		setIsLoading(false);
	};

	useEffect(() => {
		dispatch(setChangeEmail(false));
	}, []);
	useEffect(() => {
		if (success && !changeEmail) {
			goBack((prev) => prev + 1);
		}
		setIsLoading(false);
	}, [success]);

	useEffect(() => {
		const storedUserData = JSON.parse(localStorage.getItem("userData"));
		if (storedUserData) {
			setName(storedUserData.name);
			setEmail(storedUserData.email);
			setPassword(storedUserData.password);
			setConfirmPassword(storedUserData.password);
			setAcceptsTerms(true);
			setAcceptsPromotions(storedUserData.acceptsPromotions);
		}
	}, []);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [acceptsTerms, setAcceptsTerms] = useState(false);
	const [acceptsPromotions, setAcceptsPromotions] = useState(false);
	return (
		<div style={{}}>
			<Row className='survey-content-finalstep'>
				<Col
					md='6'
					/* style={{ paddingLeft: 100 }} */
				>
					<div className=''>
						<p className='survey-title'>{t("complete.title")}</p>
						<p className='survey-subtitle-bold'>{t("complete.subtitle")}</p>
						<br />
						<p className='survey-subtitle-bold'>
							<p className='survey-subtitle-bold'>{t("complete.subtitle1")}</p>
						</p>
						<br />
						<p className='survey-subtitle-bold'>
							<p className='survey-subtitle-bold'>{t("complete.subtitle2")}</p>
						</p>
						<div
							className='survey-step'
							style={{ paddingLeft: 0, gap: "30px" }}
						>
							{steps.map((step, index) => (
								<div
									className='survey-steps-intro'
									key={index}
								>
									<ReactSVG
										src={step.icon}
										className='step-icon'
									/>
									<div className='step-divider'></div>
									<p className='survey-description'>
										{t(step.description)}{" "}
										{/* Traduzione dinamica delle descrizioni */}
									</p>
								</div>
							))}
						</div>
					</div>
				</Col>
				<Col md='6'>
					<div className=''>
						<p
							className='survey-subtitle'
							style={{ color: "#003F49", textAlign: "left", marginBottom: 15 }}
						>
							{t("complete.form.title")}
						</p>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								marginBottom: 15,
								width: "100%",
							}}
						>
							<p className='input-description'>
								{t("complete.form.name")}
								<sup>*</sup>{" "}
							</p>

							<Input
								placeholder={t("complete.form.name")}
								value={name}
								onChange={(e) => setName(e.target.value)}
								className='survey-input'
							/>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								marginBottom: 15,
								width: "100%",
							}}
						>
							<p className='input-description'>
								{t("complete.form.email")}
								<sup>*</sup>{" "}
							</p>

							<Input
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								placeholder={t("complete.form.email")}
								className='survey-input'
							/>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								marginBottom: 15,
								width: "100%",
							}}
						>
							<p className='input-description'>
								{t("complete.form.password")}
								<sup>*</sup>{" "}
							</p>

							<Input
								placeholder={t("complete.form.password")}
								value={password}
								type='password'
								onChange={(e) => setPassword(e.target.value)}
								className='survey-input'
							/>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								marginBottom: 15,
								width: "100%",
							}}
						>
							<p className='input-description'>
								{t("complete.form.confirm")}
								<sup>*</sup>{" "}
							</p>

							<Input
								className='survey-input'
								placeholder={t("complete.form.password")}
								value={confirmPassword}
								type='password'
								onChange={(e) => setConfirmPassword(e.target.value)}
							/>
						</div>
						<Checkbox
							className='agree'
							style={{
								fontSize: 14,
								fontFamily: "Inter",
								color: "#737679",
								width: "100%",
							}}
							required
							checked={acceptsTerms}
							onChange={(e) => setAcceptsTerms(e.target.checked)}
						>
							{t("complete.form.checkTerms")}
						</Checkbox>
						<Checkbox
							className='agree'
							checked={acceptsPromotions}
							onChange={(e) => setAcceptsPromotions(e.target.checked)}
							style={{
								fontSize: 14,
								fontFamily: "Inter",
								color: "#737679",
								marginBottom: 15,
							}}
						>
							{t("complete.form.checkMarketing")}
						</Checkbox>
					</div>
				</Col>
			</Row>

			<Row
				className='footer-row'
				style={{ position: "fixed" }}
			>
				<Button
					className='footer-button-back'
					onClick={() => goBack((prev) => prev - 1)}
				>
					{t("footer.back")}
				</Button>
				<Button
					className='footer-button-next'
					disabled={
						!name || !email || !password || !confirmPassword || !acceptsTerms
					}
					onClick={handleSignup}
					loading={isLoading}
				>
					{t("footer.finish")}
				</Button>
			</Row>
		</div>
	);
};

export default Step1;
