import React from "react";

const H1 = (props) => {
	return (
		<h1
			{...props.attrH1}
			style={{
				fontSize: 32,
				fontWeight: 500,
				fontFamily: "Exo 2 sans-serif",
				color: "#001F24",
			}}
		>
			{props.children}
		</h1>
	);
};

export default H1;
