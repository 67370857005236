import React, { useEffect, useState } from "react";
import support from "../../assets/images/survey/24-support.svg";
import shieldtick from "../../assets/images/survey/shield-tick.svg";
import colorpicker from "../../assets/images/survey/color-swatch.svg";
import { ReactSVG } from "react-svg";
import { Container, Row } from "reactstrap";
import logo from "../../assets/images/survey/logo.svg";
import { Select, Button } from "antd";
import "./style.css";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { verifyUserAction } from "../../features/user/userSlices";
import { useDispatch } from "react-redux";
import i18n from "../../i18n";
import Header from "../../Components/Application/Survey/Header";
import { useTranslation } from "react-i18next";

const Step1 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const steps = [
		{
			description: "token.step1",
			icon: support,
		},
		{
			description: "token.step2",
			icon: colorpicker,
		},
	];

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				width: "100%",
			}}
		>
			<div className='survey-content'>
				<p
					className='survey-title'
					style={{
						color: "#539E56",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<ReactSVG
						src={shieldtick}
						className='step-icon'
						style={{ marginRight: "10px" }}
					/>
					{t("token.title")}
				</p>
				<p
					className='survey-subtitle'
					style={{
						textAlign: "left",
						fontSize: "16px",
						fontWeight: "600",
						color: "#477673",
					}}
				>
					{t("token.subtitle1")}
					<br />
					{t("token.subtitle2")}
				</p>
				<div
					className='survey-steps
				'
					style={{ gap: 40 }}
				>
					{steps.map((step, index) => (
						<div
							className='survey-steps-confirm'
							style={{ marginBottom: 30 }}
							key={index}
						>
							<ReactSVG
								src={step.icon}
								className='step-icon'
							/>
							<div className='step-divider'></div>
							<p className='survey-description'>{t(step.description)}</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

const ContainerBox = () => {
	//get params from url
	const currentLanguage = localStorage.getItem("surveyLanguage");
	const navigate = useNavigate();
	const [language, setLanguage] = useState(currentLanguage || i18n.language);

	const { token } = useParams();
	const [isVerify, setVerify] = React.useState(false);
	const dispatch = useDispatch();
	const location = useLocation();

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		setLanguage(lng);
		localStorage.setItem("surveyLanguage", lng);
	};

	useEffect(() => {
		const response = dispatch(verifyUserAction(token));
		response.then((res) => {
			console.log(res);
			if (!res.payload?.error && !res.error) {
				setVerify(true);
				localStorage.removeItem("surveySessionId");
				localStorage.removeItem("surveyAnswers");
				localStorage.removeItem("surveyComplete");
				localStorage.removeItem("surveyLanguage");
			} else {
				setVerify(false);
			}
		});
	}, [token, dispatch]);

	return (
		<Container style={{ width: "100vw", height: "100vh" }}>
			{/* Header */}
			<Header changeLanguage={changeLanguage} />

			{/* Step Content */}
			<Row
				style={{
					height: "76vh",
					backgroundColor: "#F2F2F2",
					width: "100vw",
					overflowY: "auto",
				}}
			>
				{isVerify ? <Step1 /> : <p>Token non valido</p>}
			</Row>

			{/* Footer Navigation */}
			<Row className='footer-row'>
				<Button className='footer-button-back'>Vai al sito web</Button>
				<Button
					className='footer-button-next'
					onClick={() => navigate("/login")}
				>
					Vai all'area riservata
				</Button>
			</Row>
		</Container>
	);
};

export default ContainerBox;
