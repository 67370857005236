import React, { useContext, useState, Fragment } from "react";
import { ArrowLeftCircle } from "react-feather";
import { Link } from "react-router-dom";
import { Image } from "../../AbstractElements";
import CheckContext from "../../_helper/Customizer";
import { ReactSVG } from "react-svg";
import logoit from "../../assets/images/logo/logo-it.svg";
const Leftbar = () => {
	const { mixLayout, toggleSidebar } = useContext(CheckContext);
	const [toggle, setToggle] = useState(false);

	const openCloseSidebar = () => {
		setToggle(!toggle);
		toggleSidebar(!toggle);
	};

	return (
		<Fragment>
			<div
				className='main-header-left'
				style={{
					padding: 32,
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				{mixLayout ? (
					<div className='logo-wrapper'>
						<Link to={`${process.env.PUBLIC_URL}/home`}>
							<ReactSVG
								src={logoit}
								style={{
									width: "80%",
									minWidth: "150px",
								}}
							/>

							{/* 		<Image
								attrImage={{
									className: "img-fluid d-inline",
									src: `${require("../../assets/images/logo/logo.png")}`,
									alt: "",
								}}
							/> */}
						</Link>
					</div>
				) : (
					<div className='dark-logo-wrapper'>
						<Link to={`${process.env.PUBLIC_URL}/dashboard/default`}>
							<Image
								attrImage={{
									className: "img-fluid d-inline",
									src: `${require("../../assets/images/logo/dark-logo.png")}`,
									alt: "",
								}}
							/>
						</Link>
					</div>
				)}
			</div>
		</Fragment>
	);
};

export default Leftbar;
