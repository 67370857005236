import client from "../index";

export const getPaymentMethod = async () => {
	return client.get("/payment/methods");
};

export const setDefaultPaymentMethod = async (id) => {
	return client.put("/payment/default/" + id);
};

export const savePaymentMethod = async (body) => {
	return client.post("/payment/save", body);
};

export const deletePaymentMethod = async (id) => {
	return client.delete("/payment/" + id);
};

export const doPayment = async (body) => {
	console.log(body);
	return client.post("/payment/charge", body);
};

export const createInvoice = async (body) => {
	return client.post("/payment/invoice", body);
};
