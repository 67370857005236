import React from "react";
import { Modal } from "reactstrap";
import { ReactSVG } from "react-svg";
import clicpboardClose from "../../assets/images/clipboard-close.svg";
import clickboardThik from "../../assets/images/clipboard-tick.svg";
import { Button, Grid } from "antd";

const ModalAlert = ({
	showModal,
	setShowModal,
	BodyComponent,
	onOk,
	onDelete,
	okButtonTitle,
	deleteButtonTitle,
	style,
}) => {
	const breakpoint = Grid.useBreakpoint();
	const isMobile = !breakpoint.lg;
	return (
		<Modal
			backdropClassName='modal-backdrop'
			isOpen={showModal}
			centered // Centra il modale
			style={{
				...style,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				padding: 0,
			}}
			toggle={() => setShowModal(!showModal)}
		>
			<div
				style={{
					backgroundColor: "#FCF8F0",
					padding: 20,
					borderRadius: 10,
					width: isMobile ? "100%" : "auto",
					maxHeight: "90vh",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflowY: "auto",
					boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
				}}
			>
				{BodyComponent && (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							gap: 60,
							alignItems: "center",
							width: "100%",
							marginBottom: "20px",
						}}
					>
						<BodyComponent />
					</div>
				)}

				<div
					style={{
						display: "flex",
						flexDirection: isMobile ? "column" : "row", // Cambia la direzione in base a isMobile
						justifyContent: "center",
						alignItems: "center",
						gap: isMobile ? 20 : 10,
						width: "100%",
					}}
				>
					<Button
						style={{
							backgroundColor: "#fff",
							color: "#477673",
							border: "1px solid #8E6B00",
							borderRadius: 10,
							height: 50,
							width: isMobile ? "100%" : "auto", // Pieno solo su mobile
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
						onClick={onDelete}
					>
						<ReactSVG
							src={clicpboardClose}
							style={{ marginRight: 8 }}
						/>
						{deleteButtonTitle}
					</Button>

					<Button
						style={{
							backgroundColor: "#477673",
							color: "#fff",
							height: 50,
							width: isMobile ? "100%" : "auto", // Pieno solo su mobile
							borderRadius: 10,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
						onClick={onOk}
					>
						{okButtonTitle}{" "}
						<ReactSVG
							src={clickboardThik}
							style={{ marginLeft: 8 }}
						/>
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default ModalAlert;
