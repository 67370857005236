import React, { useState } from "react";
import { Col } from "reactstrap";
import jessicali from "../../assets/images/avtar/jessica_li.png";
import H5 from "../../CommonElements/Headings/H5Element";
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from "reactstrap";
import { ArrowDownCircle, ArrowUpCircle } from "react-feather";
import { Grid } from "antd";
import { useSelector } from "react-redux";
import P6 from "../../CommonElements/Headings/P6Element";
import arrowUp from "../../assets/images/sidebar/arrow-up.svg";
import arrowDown from "../../assets/images/sidebar/arrow-down.svg";
import { ReactSVG } from "react-svg";

const RightSidebar = () => {
	const [open, setOpen] = useState("1");
	const { useBreakpoint } = Grid;
	const { profile, actionArea, presentation } = useSelector(
		(state) => state?.user?.consultantData
	);
	const img = useSelector(
		(state) => state.user.consultantData?.presentation?.img_profile
	);

	const fullPath = process.env.REACT_APP_API_URL_MEDIA + img;
	const screens = useBreakpoint();

	const toggle = (id) => {
		open === id ? setOpen() : setOpen(id);
	};

	const CustomAccordionHeader = ({ targetId, toggle, isOpen, children }) => (
		<AccordionHeader
			targetId={targetId}
			onClick={() => toggle(targetId)}
			className='custom-accordion-header'
		>
			{children}
			{isOpen ? (
				<ReactSVG
					src={arrowUp}
					style={{ marginLeft: "auto" }}
				/> // Icona per quando l'accordion è aperto
			) : (
				<ReactSVG
					src={arrowDown}
					style={{ marginLeft: "auto" }}
				/> // Icona per quando l'accordion è aperto
			)}
		</AccordionHeader>
	);

	return (
		screens.lg && (
			<Col
				style={{
					backgroundColor: "#F7F8F8",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<img
						src={fullPath || jessicali}
						alt='placeholder'
						style={{ borderRadius: "50%", width: "128px" }}
						className='mb-2'
					/>
					<H5>
						{profile?.name} {profile?.surname}
					</H5>
					<span
						className='mb-4'
						style={{ fontSize: 14 }}
					>
						Consulente esperto
					</span>
				</div>
				<Accordion
					open={open}
					toggle={toggle}
					style={{ border: "none" }}
				>
					<AccordionItem style={{ border: "none" }}>
						<CustomAccordionHeader
							targetId='1'
							toggle={toggle}
							isOpen={open === "1"}
						>
							Presentazione
						</CustomAccordionHeader>
						<AccordionBody
							accordionId='1'
							style={{ border: "none", overflowY: "scroll", maxHeight: "50vh" }}
						>
							<P6>{presentation?.presentation_it}</P6>
						</AccordionBody>
					</AccordionItem>
					<AccordionItem>
						<CustomAccordionHeader
							targetId='2'
							toggle={toggle}
							isOpen={open === "2"}
						>
							Biografia
						</CustomAccordionHeader>
						<AccordionBody
							accordionId='2'
							style={{ border: "none", overflowY: "scroll", maxHeight: "50vh" }}
						>
							<P6> {presentation?.description_it}</P6>
						</AccordionBody>
					</AccordionItem>
					<AccordionItem>
						<CustomAccordionHeader
							targetId='3'
							toggle={toggle}
							isOpen={open === "3"}
						>
							Aree di intervento
						</CustomAccordionHeader>
						<AccordionBody
							accordionId='3'
							style={{ border: "none", overflowY: "scroll", maxHeight: "50vh" }}
						>
							<ul>
								{actionArea.map((area, index) => (
									<>
										<li key={index}>
											<b>{area.title_it}</b>
											<br />
											<P6>{area.description_it}</P6>
										</li>
										<br /> <br />
									</>
								))}
							</ul>
						</AccordionBody>
					</AccordionItem>
					{/* 					<AccordionItem>
						<CustomAccordionHeader
							targetId='4'
							toggle={toggle}
							isOpen={open === "4"}
						>
							Certificazioni
						</CustomAccordionHeader>
						<AccordionBody accordionId='4'>
							Quis cursus lectus sit lectus tristique...
						</AccordionBody>
					</AccordionItem>
					<AccordionItem>
						<CustomAccordionHeader
							targetId='5'
							toggle={toggle}
							isOpen={open === "5"}
						>
							Recensioni
						</CustomAccordionHeader>
						<AccordionBody accordionId='5'>
							Eccellente consulente, molto professionale...
						</AccordionBody>
					</AccordionItem> */}
				</Accordion>
			</Col>
		)
	);
};

export default RightSidebar;
