import client from "../index";

export const getMineAvailability = async () => {
	return client.get("/slots");
};

export const getConsultantAvailability = async (id) => {
	return client.get("/slots/get-consultant/" + id);
};

export const saveMineAvailability = async (body) => {
	return client.post("/slots", body);
};
