import {
	File,
	Users,
	Home,
	MessageCircle,
	Calendar,
	Settings,
} from "react-feather";
import home from "../../assets/images/icon/menu/hone.svg";
import chatIcon from "../../assets/images/icon/menu/chat.svg";
import appointmentsIcon from "../../assets/images/icon/menu/appointment.svg";
import service from "../../assets/images/icon/menu/service.svg";
import user from "../../assets/images/icon/menu/appointment.svg";
import calendar from "../../assets/images/icon/menu/calendar.svg";
import user2 from "../../assets/images/icon/menu/user_2.svg";
import settingsIcon from "../../assets/images/icon/menu/settings.svg";
import { ReactSVG } from "react-svg";
export const MENUITEMSCONSULTANT = [
	{
		Items: [
			{
				title: "Elenco Chat",
				icon: () => <ReactSVG src={home} />,
				type: "link",
				active: false,
				path: "/chat" /* 
				children: [
					{
						path: `${process.env.PUBLIC_URL}/general/sample_page`,
						title: "Sample Page",
						type: "link",
					},
				], */,
			},
			{
				title: "Appuntamenti",
				icon: () => <ReactSVG src={appointmentsIcon} />,
				type: "link",
				active: false,
				path: "/appointment" /* 
				children: [
					{
						path: `${process.env.PUBLIC_URL}/general/sample_page`,
						title: "Sample Page",
						type: "link",
					},
				], */,
			},
			{
				title: "Disponibilità",
				icon: () => <ReactSVG src={calendar} />,
				type: "link",
				active: false,
				path: "/available-slots" /* 
				children: [
					{
						path: `${process.env.PUBLIC_URL}/general/sample_page`,
						title: "Sample Page",
						type: "link",
					},
				], */,
			},
			{
				title: "Elenco clienti",
				icon: () => <ReactSVG src={user2} />,
				type: "link",
				active: false,
				path: "/clients" /* 
				children: [
					{
						path: `${process.env.PUBLIC_URL}/general/sample_page`,
						title: "Sample Page",
						type: "link",
					},
				], */,
			},

			{
				title: "Servizi erogati",
				icon: () => <ReactSVG src={service} />,
				type: "link",
				active: false,
				path: "/my-service" /* 
				children: [
					{
						path: `${process.env.PUBLIC_URL}/general/sample_page`,
						title: "Sample Page",
						type: "link",
					},
				], */,
			},
			{
				title: "Impostazioni profilo",
				icon: () => <ReactSVG src={settingsIcon} />,
				type: "link",
				active: false,
				path: "/settings" /* 
				children: [
					{
						path: `${process.env.PUBLIC_URL}/general/sample_page`,
						title: "Sample Page",
						type: "link",
					},
				], */,
			},
			/* 	{
				path: `https://support.pixelstrap.com/`,
				icon: Users,
				type: "link",
				active: false,
				title: "Support Ticket",
			}, */
		],
	},
];

export const MENUITEMUSER = [
	{
		Items: [
			{
				title: "Home",
				icon: Home,
				type: "link",
				active: false,
				path: "/home" /* 
				children: [
					{
						path: `${process.env.PUBLIC_URL}/general/sample_page`,
						title: "Sample Page",
						type: "link",
					},
				], */,
			},
			{
				title: "Chat",
				icon: MessageCircle,
				type: "link",
				active: false,
				path: "/chat" /* 
				children: [
					{
						path: `${process.env.PUBLIC_URL}/general/sample_page`,
						title: "Sample Page",
						type: "link",
					},
				], */,
			},
			{
				title: "Appuntamenti",
				icon: Calendar,
				type: "link",
				active: false,
				path: "/appointment" /* 
				children: [
					{
						path: `${process.env.PUBLIC_URL}/general/sample_page`,
						title: "Sample Page",
						type: "link",
					},
				], */,
			},
			{
				title: "Impostazioni profilo",
				icon: Settings,
				type: "link",
				active: false,
				path: "/settings" /* 
				children: [
					{
						path: `${process.env.PUBLIC_URL}/general/sample_page`,
						title: "Sample Page",
						type: "link",
					},
				], */,
			},
			/* 	{
				path: `https://support.pixelstrap.com/`,
				icon: Users,
				type: "link",
				active: false,
				title: "Support Ticket",
			}, */
		],
	},
];
