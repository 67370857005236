import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const useAvailablePaymentMethods = () => {
	const [availableMethods, setAvailableMethods] = useState([]);

	useEffect(() => {
		const checkPaymentMethods = async () => {
			const stripe = await stripePromise;

			// Configura PaymentRequest per Apple Pay, Google Pay e carte
			const paymentRequest = stripe.paymentRequest({
				country: "IT", // Cambia il paese se necessario
				currency: "eur", // Cambia la valuta se necessario
				total: {
					label: "Pagamento di prova",
					amount: 0, // Importo zero per non avviare una vera transazione
				},
				requestPayerName: true,
				requestPayerEmail: true,
			});

			// Verifica la disponibilità di Apple Pay e Google Pay
			const canMakePaymentResult = await paymentRequest.canMakePayment();
			const methods = [];

			if (canMakePaymentResult?.applePay) {
				methods.push({ val: "apple_pay", title: "Apple Pay" });
			}
			if (canMakePaymentResult?.googlePay) {
				methods.push({ val: "google_pay", title: "Google Pay" });
			}

			// Aggiungi sempre il metodo di pagamento con carta
			methods.push({ val: "card", title: "Carta di Credito / Debito" });

			setAvailableMethods(methods);
			console.log(methods);
		};

		checkPaymentMethods();
	}, []);

	return availableMethods;
};

export default useAvailablePaymentMethods;
