import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { getConsultantAvailabilityAction } from "../../../features/availableSlots/availableSlotsSlices";
import { useDispatch, useSelector } from "react-redux";
import H5 from "../../../CommonElements/Headings/H5Element";
import P1 from "../../../CommonElements/Headings/P1Element";
import { ArrowLeftCircle, ArrowRightCircle } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
const SlotTime = ({
	setCurrentStep,
	currentStep,
	selectedSlot,
	setSelectedSlot,
}) => {
	const dispatch = useDispatch();
	const userData = useSelector((state) => state.user);
	const availableSlots = useSelector((state) => state.availableSlots.data);
	const navigator = useNavigate();
	useEffect(() => {
		if (!!userData?.data?.cod_consultant) {
			dispatch(getConsultantAvailabilityAction(userData?.data?.cod_consultant));
		}
	}, [userData]);
	const id = useParams().id;

	const raggruppaPerData = (slots) => {
		return slots?.reduce((acc, slot) => {
			const data = new Date(slot.start_time).toLocaleDateString("it-IT", {
				weekday: "long",
				year: "numeric",
				month: "long",
				day: "numeric",
			});
			if (!acc[data]) acc[data] = [];
			acc[data].push(slot);
			return acc;
		}, {});
	};

	const [slotsRaggruppati, setSlotsRaggruppati] = useState({});

	useEffect(() => {
		// Raggruppa gli slot per data al caricamento del componente
		setSlotsRaggruppati(raggruppaPerData(availableSlots));
	}, [availableSlots]);

	const getLabelForDate = (dateString) => {
		const today = new Date();
		const tomorrow = new Date(today);
		tomorrow.setDate(today.getDate() + 1);

		const date = new Date(dateString);
		if (date.toDateString() === today.toDateString()) {
			return "Oggi";
		} else if (date.toDateString() === tomorrow.toDateString()) {
			return "Domani";
		} else {
			return date.toLocaleDateString("it-IT", {
				year: "numeric",
				month: "long",
				day: "numeric",
			});
		}
	};

	return (
		<Row>
			<div style={{ height: "50vh", overflowY: "scroll" }}>
				{!!slotsRaggruppati && Object?.keys(slotsRaggruppati)?.length > 0 ? (
					Object?.entries(slotsRaggruppati).map(([data, slots]) => (
						<div
							key={data}
							className='day-group'
							style={{ marginBottom: "20px" }}
						>
							<H5>{getLabelForDate(slots[0].start_time)}</H5>
							<div
								className='time-slots'
								style={{
									display: "flex",
									flexDirection: "row",
									gap: 30,
									flexWrap: "wrap",
								}}
							>
								{slots.map((slot) => (
									<div
										key={slot.id}
										onClick={() => setSelectedSlot(slot.id)}
										style={{
											borderRadius: "10px",
											width: "fit-content",
											cursor: "pointer",
											padding: "15px",
											backgroundColor:
												selectedSlot === slot.id ? "#477673" : "#F7F8F8",
										}}
										className='time-slot'
									>
										<P1
											style={{
												margin: 0,
												fontWeight: 500,
												color:
													selectedSlot === slot.id ? "#fff" : "rgb(0, 31, 36)",
											}}
										>
											{new Date(slot.start_time).toLocaleTimeString("it-IT", {
												hour: "2-digit",
												minute: "2-digit",
											})}{" "}
											-{" "}
											{new Date(slot.end_time).toLocaleTimeString("it-IT", {
												hour: "2-digit",
												minute: "2-digit",
											})}
										</P1>
									</div>
								))}
							</div>
						</div>
					))
				) : (
					<H5>Non ci sono slot disponibili</H5>
				)}
			</div>
			<div
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "flex-end",
					marginTop: "20px",
				}}
			>
				<Button
					color='white'
					style={{
						height: "48px",
						borderRadius: "10px",
						display: "flex",
						alignItems: "center",
						border: "1px solid #8E6B00",
						width: "150px",
						marginRight: "32px",
					}}
					className='mr-3'
					onClick={() => {
						navigator(`/service/${id}`);
					}}
				>
					<ArrowLeftCircle
						size={20}
						className='ml-4'
						style={{ marginRight: "8px" }}
						color='#003F49'
					/>
					<span style={{ fontSize: 16, fontWeight: 600 }}>Indietro</span>
				</Button>
				<Button
					color='primary'
					style={{
						height: "48px",
						width: "150px",
						border: "none",
						borderRadius: "10px",
						display: "flex",
						fontSize: 16,
						alignItems: "center",
						justifyContent: "center",
					}}
					disabled={!selectedSlot || slotsRaggruppati?.length === 0}
					onClick={() => {
						setCurrentStep((prev) => prev + 1);
					}}
				>
					<span style={{ fontSize: 16, fontWeight: 600 }}>Procedi</span>
					<ArrowRightCircle
						size={20}
						className='ml-4'
						style={{ marginLeft: "8px" }}
					/>
				</Button>
			</div>
		</Row>
	);
};

export default SlotTime;

// Array di dati degli slot di disponibilità

// Funzione per raggruppare gli slot per data
