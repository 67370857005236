import { Checkbox, Input, Form } from "antd";
import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import ProvinceSelect from "../../../Common/Component/ProviceSelect";
import StateSelect from "../../../Common/Component/StateSelect";
import {
	setInvoiceData,
	createInvoiceAction,
} from "../../../../features/payment/paymentSlice";
import { ArrowLeftCircle, ArrowRightCircle } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import * as invoiceSlice from "../../../../features/profile/profileSlices";
import { set } from "react-hook-form";
const InvoiceForm = () => {
	const [formVisible, setFormVisible] = useState(false);
	const [form] = Form.useForm();
	// Funzione per gestire il cambiamento di stato della checkbox
	const handleCheckboxChange = (e) => {
		setFormVisible(e.target.checked);
	};
	const paymentConfirmID = useSelector((state) => state.payment.confirmID);
	const singleService = useSelector((state) => state.service.singleService);
	const invoiceID = useSelector((state) => state.payment.invoiceID);
	const dispatch = useDispatch();
	const invoiceData = useSelector((state) => state.profile.invoiceData);
	const [proviceToShow, setProvinceToShow] = useState(false);

	const [finalStep, setFinalStep] = useState(false);
	// Gestione del submit del form
	const handleFormSubmit = () => {
		const values = form.getFieldsValue();
		const valuesEdited = {
			...values,
			id_service: singleService?.id,
			cod_transaction: paymentConfirmID,
			amount: singleService?.price,
		};
		dispatch(createInvoiceAction(valuesEdited));
	};

	useEffect(() => {
		if (Number(invoiceID)) setFinalStep(true);
	}, [invoiceID]);

	useEffect(() => {
		dispatch(invoiceSlice.getProfileInvoiceAction());
	}, []);

	useEffect(() => {
		console.log(invoiceData);
		form.setFieldsValue({
			name: invoiceData?.name,
			surname: invoiceData?.surname,
			address_invoice: invoiceData?.address,
			cap_invoice: invoiceData?.cap,
			city_invoice: invoiceData?.city,
			province_invoice: invoiceData?.province,
			cf: invoiceData?.cf,
			state_invoice: invoiceData?.state,
		});
		setProvinceToShow(invoiceData?.state === "IT");
	}, [invoiceData]);

	return (
		<Row style={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
			<Col
				sm='12'
				style={{ marginBottom: 60 }}
			>
				<Checkbox
					className='agree'
					onChange={handleCheckboxChange}
				>
					Clicca sulla casella e compila i dati sottostanti se vuoi ricevere la
					fattura via mail
				</Checkbox>
			</Col>

			<Form
				form={form}
				onFinish={handleFormSubmit}
				layout='vertical'
				disabled={!formVisible}
			>
				<Row gutter={[16, 16]}>
					<Col md='6'>
						<Form.Item
							label='Nome'
							name='name'
							rules={[{ required: true, message: "Inserisci il tuo nome" }]}
						>
							<Input
								placeholder='Nome'
								style={{ height: 50 }}
							/>
						</Form.Item>
					</Col>
					<Col md='6'>
						<Form.Item
							label='Cognome'
							name='surname'
							rules={[{ required: true, message: "Inserisci il tuo cognome" }]}
						>
							<Input
								placeholder='Cognome'
								style={{ height: 50 }}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col md='6'>
						<Form.Item
							label='Indirizzo'
							name='address_invoice'
							rules={[
								{ required: true, message: "Inserisci il tuo indirizzo" },
							]}
						>
							<Input
								placeholder='Indirizzo'
								style={{ height: 50 }}
							/>
						</Form.Item>
					</Col>
					<Col md='3'>
						<Form.Item
							label='CAP'
							name='cap_invoice'
							rules={[{ required: true, message: "Inserisci il CAP" }]}
						>
							<Input
								placeholder='cap_invoice'
								style={{ height: 50 }}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col md='6'>
						<Form.Item
							label='Città'
							name='city_invoice'
							rules={[{ required: true, message: "Inserisci la tua città" }]}
						>
							<Input
								placeholder='city_invoice'
								style={{ height: 50 }}
							/>
						</Form.Item>
					</Col>
					{proviceToShow && (
						<Col md='3'>
							<Form.Item
								label='Provincia'
								name='province_invoice'
								rules={[
									{ required: true, message: "Seleziona la tua provincia" },
								]}
							>
								<ProvinceSelect />
							</Form.Item>
						</Col>
					)}
					<Col md='3'>
						<Form.Item
							label='Stato'
							name='state_invoice'
							rules={[{ required: true, message: "Seleziona il tuo stato" }]}
						>
							<StateSelect
								style={{ width: "100%" }}
								onChange={() => {
									setProvinceToShow(
										form.getFieldValue("state_invoice") === "IT"
									);
								}}
							/>
						</Form.Item>
					</Col>
					<Col md='6'>
						<Form.Item
							label='Codice Fiscale'
							name='cf'
							rules={[{ required: true, message: "Seleziona il tuo stato" }]}
						>
							<Input
								placeholder='Codice Fiscale'
								style={{ height: 50 }}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Form.Item></Form.Item>
			</Form>

			<Row
				style={{
					display: "flex",
					justifyContent: "flex-end",
					marginBottom: 40,
				}}
			>
				<Button
					color='white'
					style={{
						height: "48px",
						borderRadius: "10px",
						display: "flex",
						alignItems: "center",
						border: "1px solid #8E6B00",
						width: "150px",
						marginRight: "32px",
					}}
					className='mr-3'
				>
					<ArrowLeftCircle
						size={20}
						className='ml-4'
						style={{ marginRight: "8px" }}
						color='#003F49'
					/>
					<span style={{ fontSize: 16, fontWeight: 600 }}>Indietro</span>
				</Button>
				<Button
					color='primary'
					type='submit'
					style={{
						height: "48px",
						width: "150px",
						border: "none",
						borderRadius: "10px",
						display: "flex",
						fontSize: 16,
						alignItems: "center",
						justifyContent: "center",
					}}
					onClick={handleFormSubmit}
				>
					<span style={{ fontSize: 16, fontWeight: 600 }}>Procedi</span>
					<ArrowRightCircle
						size={20}
						className='ml-4'
						style={{ marginLeft: "8px" }}
					/>
				</Button>
			</Row>
		</Row>
	);
};

export default InvoiceForm;
