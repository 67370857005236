import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
	getServicesData,
	getServiceData,
	getServiceDetails,
} from "../../api/service";

export const getServiceAction = createAsyncThunk(
	"service/getService",
	async (body, { rejectWithValue }) => {
		try {
			const response = await getServiceData(body); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getServiceDetailsAction = createAsyncThunk(
	"service/getServiceDetails",
	async (cod_service, { rejectWithValue }) => {
		try {
			const response = await getServiceDetails(cod_service); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getServicesAction = createAsyncThunk(
	"service/getServices",
	async (_, { rejectWithValue }) => {
		try {
			const response = await getServicesData(); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const serviceSlice = createSlice({
	name: "service",
	initialState: {
		loading: false,
		singleService: {},
		stepService: [],
		serviceForm: [],
		formService: [],
		consultant: {},
		services: [],
		error: null,
	},
	reducers: {
		setFormStep: (state, action) => {
			state.serviceForm = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getServicesAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getServicesAction.fulfilled, (state, action) => {
				state.services = action.payload.services;
				state.consultant = action.payload.consultant;
			})
			.addCase(getServicesAction.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
			})
			.addCase(getServiceAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getServiceAction.fulfilled, (state, action) => {
				console.log(action.payload);
				state.singleService = action.payload.service;
				state.stepService = action.payload.details;
			})
			.addCase(getServiceAction.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
			})
			.addCase(getServiceDetailsAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getServiceDetailsAction.fulfilled, (state, action) => {
				console.log(action.payload);
				state.singleService = action.payload.service;
				state.formService = action.payload.steps;
			})
			.addCase(getServiceDetailsAction.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
			});
	},
});
export const selectServiceData = (state) => state.services;
export const selectSingleServiceData = (state) => state.singleService;
export const selectServiceStep = (state) => state.stepService;

export const selectServiceLoading = (state) => state.loading;
export const selectServiceError = (state) => state.error;
export const { setFormStep } = serviceSlice.actions;
export default serviceSlice.reducer;
