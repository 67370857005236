import React from "react";

const H6 = (props) => {
	return (
		<h6
			{...props.attrH6}
			style={{ ...props.style }}
		>
			{props.children}
		</h6>
	);
};

export default H6;
