import React from "react";
import { Col } from "reactstrap";
const HeaderPlatform = ({ title }) => {
	return (
		<Col sm='12'>
			<p
				style={{
					fontSize: 24,
					fontWeight: 600,
					marginTop: 32,
					marginBottom: 32,
					marginLeft: 36,
				}}
			>
				{title}
			</p>
			<hr style={{ width: "100%" }} />
		</Col>
	);
};

export default HeaderPlatform;
