import React, { useState } from "react";
import P6 from "../../CommonElements/Headings/P6Element";
import { useDispatch, useSelector } from "react-redux";
import * as chatSlices from "../../features/chat/chatSlice";
import P7 from "../../CommonElements/Headings/P7Element";
import P5 from "../../CommonElements/Headings/P5Element";
import { AntDesignOutlined } from "@ant-design/icons";
import { Grid } from "react-feather";
import { Avatar } from "antd";
import moment from "moment";
import { Button } from "antd";
const ChatList = ({ chat, onClick }) => {
	const { useBreakpoint } = Grid;

	const selectedChat = useSelector(chatSlices.selectSelectedChat);
	const showArchive = useSelector((state) => state.chat.showArchive);
	const { img, name, description, date, surname } = chat;
	const userData = useSelector((state) => state.user.data);
	const getInitials = (name) => {
		const nameArray = name.split(" ");
		const initials = nameArray.map((word) => word.charAt(0).toUpperCase());
		return initials.join("");
	};

	const dispatch = useDispatch();

	const handleShowArchive = () => {
		dispatch(chatSlices.setShowArchive(!showArchive));
		dispatch(chatSlices.setSelectedChat(null));
	};

	const formatDate = (date) => {
		console.log(date);
		// if date is today return time
		if (moment(date).isSame(moment(), "day")) {
			return moment(date).format("HH:mm");
		}
		// if date is yesterday return Yesterday
		if (moment(date).isSame(moment().subtract(1, "day"), "day")) {
			return "Ieri";
		}
		// else return date
		return moment(date).format("DD/MM/YYYY");
	};

	return (
		<>
			{((showArchive === true && chat.archive === 1) ||
				(showArchive === false && chat.archive === 0)) && (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						backgroundColor: selectedChat === chat.id ? "#F7F8F8" : "",
						cursor: "pointer",
					}}
					onClick={() => onClick(chat.id)}
				>
					<div
						className='d-flex align-items-center'
						style={{
							justifyContent: "space-between",
							flexDirection: "row",
							width: "100%",
						}}
					>
						<div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
							<Avatar
								size={50}
								style={{ marginRight: 20 }}
							>
								{getInitials(chat.name + " " + chat.surname)}
							</Avatar>
							<div style={{ flex: 0.7 }}>
								<P5 style={{ fontWeight: 600 }}>
									{name} {surname}
								</P5>
								<P7>{description}</P7>
							</div>
							<div
								className='chat-list__time'
								style={{ flex: 0.2 }}
							>
								<P6 style={{ fontSize: 12, width: "100%", textAlign: "right" }}>
									{!!chat?.last_message && formatDate(chat?.last_message)}
								</P6>
							</div>
						</div>
					</div>
				</div>
			)}

			<div
				style={{
					height: 1,
					backgroundColor: "#F7F8F8",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{userData?.cod_type === "2" && (
					<Button
						type='primary'
						onClick={handleShowArchive}
						style={{
							position: "absolute",

							bottom: -20,
						}}
					>
						{" "}
						{showArchive ? "Mostra chat attive" : "Mostra chat archiviate"}
					</Button>
				)}
			</div>
		</>
	);
};

export default ChatList;
