import React, { useEffect } from "react";
import { Grid } from "antd";
import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";

const { useBreakpoint } = Grid;

const Header = ({ changeLanguage }) => {
	const screens = useBreakpoint();
	const localLanguage = localStorage.getItem("surveyLanguage");

	useEffect(() => {
		changeLanguage(localLanguage || "it");
	}, []);

	return (
		<>
			{/* Renderizza il componente desktop per schermi medi e più grandi */}
			{screens.md ? (
				<HeaderDesktop changeLanguage={changeLanguage} />
			) : (
				<HeaderMobile changeLanguage={changeLanguage} />
			)}
		</>
	);
};

export default Header;
