import client from "../index";

export const getListConsultantUserService = async (searchQuery) => {
	return client.get("/user/userListConsultant", {
		params: { search: searchQuery }, // Parametro di ricerca passato correttamente
	});
};

export const getListConsultantUserServiceById = async (id) => {
	return client.get(`/user/getUserDetailsWithServices/${id}`);
};

export const getConsultantReportUser = async () => {
	return client.get("service/consultant/consultant-service/get");
};
