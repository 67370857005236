import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Input, Select, Form, Grid } from "antd";
import UploadCard from "./FileUpload";
import moment from "moment";
import SelectState from "../../Common/Component/StateSelect";
import SelectProvince from "../../Common/Component/ProviceSelect";
import SelectGender from "../../Common/Component/GenserSelect";
import P2 from "../../../CommonElements/Headings/P2Element";
import { ArrowRightCircle, ArrowLeftCircle } from "react-feather";
import RenderingPayment from "./Payment/RenderingPayment";
import { createPaymentTransaction } from "../../../features/payment/paymentSlice";
import SlotTime from "./SlotTime";
import { setFormStep } from "../../../features/service/serviceSlice";
const RenderingStep = ({ step, setCurrentStep, currentStep }) => {
	const [form] = Form.useForm();
	const dataUser = useSelector((state) => state.user?.data);
	const [paymentMethod, setPaymentMethod] = React.useState(null);
	const dispatch = useDispatch();
	const singleService = useSelector((state) => state.service.singleService);
	const [selectedSlot, setSelectedSlot] = useState(null);
	const formService = useSelector((state) => state.service.serviceForm);

	function handleDoPayment() {
		dispatch(
			createPaymentTransaction({
				paymentMethodId: paymentMethod,
				amount: Number(singleService?.price) * 100,
				cod_service: singleService?.id,
				slot_id: selectedSlot,
				dataForm: generatePayload(formService),
			})
		);
	}

	function generatePayload(formService) {
		const payload = {};

		for (const key in formService) {
			if (formService.hasOwnProperty(key)) {
				const value = formService[key];
				const type =
					typeof value === "string" && value.length > 100 ? "base64" : "string";

				payload[key] = {
					type,
					extensions:
						type === "base64" ? value.split(";")[0].split("/")[1] : null,
					value: value._isAMomentObject ? value.format("YYYY-MM-DD") : value,
				};
			}
		}

		return payload;
	}

	useEffect(() => {
		if (dataUser && step?.fields) {
			const values = {};
			step.fields.forEach((field) => {
				const userFieldValue = dataUser[field?.user_field];
				if (field?.type === "date" && userFieldValue) {
					values[field?.id_field] = moment(userFieldValue); // Conversione di valori data
				} else {
					values[field?.id_field] = userFieldValue;
				}
			});
			form.setFieldsValue(values); // Imposta i valori del form
		}
	}, [dataUser, step?.fields, form]);

	const handleSaveForm = () => {
		const values = form.getFieldsValue();
		// check if all required fields are filled
		const requiredFields = step.fields.filter((field) => field.required);
		const missingFields = requiredFields.filter(
			(field) => !values[field.id_field]
		);
		if (missingFields.length) {
			return;
		}
		dispatch(setFormStep(values));
	};

	const RenderingField = ({ field }) => {
		switch (field?.type) {
			case "text":
				return (
					<Form.Item
						label={field?.label_it}
						name={field?.id_field} // Utilizza field.id_field come nome del campo
						rules={[
							{
								required: field?.required,
								message: `${field?.label_it} è obbligatorio`,
							},
						]}
					>
						<Input style={{ width: "100%", height: 50 }} />
					</Form.Item>
				);
			case "gender":
				return (
					<Form.Item
						label={field?.label_it}
						name={field?.id_field} // Utilizza field.id_field come nome del campo
						rules={[
							{
								required: field?.required,
								message: `${field?.label_it} è obbligatorio`,
							},
						]}
					>
						<SelectGender />
					</Form.Item>
				);
			case "image":
				return (
					<Form.Item
						label={field?.label_it}
						name={field?.id_field} // Utilizza field.id_field come nome del campo
						rules={[
							{
								required: field?.required,
								message: `${field?.label_it} è obbligatorio`,
							},
						]}
					>
						{form.getFieldValue(field?.id_field)?.name}
						<UploadCard
							field={field}
							onChange={(val) => {
								// Creazione dell'immagine in base64
								const reader = new FileReader();
								reader.onload = (e) => {
									form.setFieldsValue({ [field?.id_field]: e.target.result });
								};
								reader.readAsDataURL(val); // Leggi il file come URL in base64
							}}
						/>
					</Form.Item>
				);
			case "date":
				return (
					<Form.Item
						label={field?.label_it}
						name={field?.id_field} // Utilizza field.id_field come nome del campo
						rules={[
							{
								required: field?.required,
								message: `${field?.label_it} è obbligatorio`,
							},
						]}
					>
						<DatePicker
							style={{ width: "100%", height: 50 }}
							format='YYYY-MM-DD'
						/>
					</Form.Item>
				);
			case "place":
				return (
					<Form.Item
						label={field?.label_it}
						name={field?.id_field} // Utilizza field.id_field come nome del campo
						rules={[
							{
								required: field?.required,
								message: `${field?.label_it} è obbligatorio`,
							},
						]}
					>
						<SelectState style={{ width: "100%", height: 50 }} />
					</Form.Item>
				);
			case "province":
				return (
					<Form.Item
						label={field?.label_it}
						name={field?.id_field} // Utilizza field.id_field come nome del campo
						rules={[
							{
								required: field?.required,
								message: `${field?.label_it} è obbligatorio`,
							},
						]}
					>
						<SelectProvince style={{ width: "100%", height: 50 }} />
					</Form.Item>
				);
			default:
				return null;
		}
	};

	const RenderingForm = ({ step, currentStep, setCurrentStep }) => {
		return (
			<Form
				form={form}
				layout='vertical'
				style={{ width: "100%" }}
			>
				<Row style={{ marginTop: 20, marginBottom: 20 }}>
					<Row style={{ overflowY: "scroll" }}>
						{step?.fields.map((field, index) => (
							<Col
								key={index}
								md={field?.dim_col}
								style={{ marginBottom: 20 }}
							>
								<RenderingField field={field} />
							</Col>
						))}
					</Row>
				</Row>
			</Form>
		);
	};
	const statusPayment = useSelector((state) => state.payment.status);
	const breakpoint = Grid.useBreakpoint();
	const navigator = useNavigate();
	const id = useParams().id;
	return (
		<Row>
			<P2 style={{ fontSize: 14, fontWeight: 500, marginTop: 30 }}>
				{statusPayment !== "success" && step?.description_it}
			</P2>
			{step?.cod_type === "form" && (
				<div style={{ width: "100%" }}>
					<RenderingForm step={step} />
					<Row
						style={{
							display: "flex",
							justifyContent: breakpoint?.lg ? "flex-end" : "space-between",
							marginBottom: 40,
							width: "100%",
						}}
					>
						<Button
							color='white'
							style={{
								height: "48px",
								borderRadius: "10px",
								display: "flex",
								alignItems: "center",
								border: "1px solid #8E6B00",
								width: "150px",
								marginRight: "32px",
							}}
							className=''
							onClick={() => {
								navigator(`/service/${id}`);
							}}
						>
							<ArrowLeftCircle
								size={20}
								className='ml-4'
								style={{ marginRight: "8px" }}
								color='#003F49'
							/>
							<span style={{ fontSize: 16, fontWeight: 600 }}>Indietro</span>
						</Button>
						<Button
							color='primary'
							style={{
								height: "48px",
								width: "150px",
								border: "none",
								borderRadius: "10px",
								display: "flex",
								fontSize: 16,
								alignItems: "center",
								justifyContent: "center",
							}}
							onClick={() => {
								setCurrentStep((prev) => prev + 1);
								handleSaveForm();
							}}
						>
							<span style={{ fontSize: 16, fontWeight: 600 }}>Procedi</span>
							<ArrowRightCircle
								size={20}
								style={{ marginLeft: "8px" }}
							/>
						</Button>
					</Row>
				</div>
			)}
			{step?.cod_type === "payment" && (
				<>
					<RenderingPayment
						radio={true}
						setPaymentMethod={setPaymentMethod}
						currentStep={currentStep}
						setCurrentStep={setCurrentStep}
						handleDoPayment={handleDoPayment}
					/>
				</>
			)}
			{step?.cod_type === "slot" && (
				<SlotTime
					currentStep={currentStep}
					setCurrentStep={setCurrentStep}
					setSelectedSlot={setSelectedSlot}
					selectedSlot={selectedSlot}
				/>
			)}
		</Row>
	);
};

export default RenderingStep;
