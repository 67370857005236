import React, { Fragment, useEffect, useState } from "react";
import SidebarMenuItems from "./SidebarMenuItems";
import { ArrowLeft, ArrowRight } from "react-feather";
import ConfigDB from "../../Config/ThemeConfig";

const SidebarMenu = ({
	setMainMenu,
	props,
	sidebartoogle,
	setNavActive,
	width,
}) => {
	const [rightArrow, setRightArrow] = useState(false);
	const [leftArrow, setLeftArrow] = useState(false);
	const sidebar_types = localStorage.getItem("sidebar_types");
	const wrapper = sidebar_types || ConfigDB.data.settings.sidebar.type;
	const [margin, setMargin] = useState(0);
	useEffect(() => {
		setLeftArrow(true);
	}, []);

	return (
		<Fragment>
			<nav>
				<div className='main-navbar'>
					<div
						className={`left-arrow ${leftArrow ? "d-none" : ""}`}
						id='left-arrow'
					>
						<ArrowLeft />
					</div>
					<div
						id='sidebar-menu'
						style={
							wrapper.split(" ").includes("horizontal-wrapper")
								? { marginLeft: margin + "px" }
								: { margin: "0px" }
						}
					>
						<SidebarMenuItems
							setMainMenu={setMainMenu}
							props={props}
							sidebartoogle={sidebartoogle}
							setNavActive={setNavActive}
						/>
					</div>
					{/* <div
						className={`right-arrow ${rightArrow ? "d-none" : ""}`}
						onClick={scrollToRight}
					>
						<ArrowRight />
					</div> */}
				</div>
			</nav>
		</Fragment>
	);
};

export default SidebarMenu;
