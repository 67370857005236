import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { Footer, P } from "../../AbstractElements";
import { useLocation } from "react-router-dom";

const FooterClass = () => {
	const location = useLocation();

	return <Fragment></Fragment>;
};

export default FooterClass;
