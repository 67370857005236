import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import P6 from "../CommonElements/Headings/P6Element";
import P5 from "../CommonElements/Headings/P5Element";

import { useDispatch, useSelector } from "react-redux";
import { getConsultantServiceActionByIdUserAction } from "../features/summary-service/summaryServiceSlices";
import HeaderPlatform from "../CommonElements/HeaderPlatform/index";
import Avatar from "antd/es/avatar/avatar";
import H1 from "../CommonElements/Headings/H1Element";
import H5 from "../CommonElements/Headings/H5Element";
import P7 from "../CommonElements/Headings/P7Element";

import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import thikicon from "../assets/images/icon/tick-circle.svg";
import { ReactSVG } from "react-svg";
import remove from "../assets/images/icon/add.svg";
const AvailableSlot = () => {
	const dispatch = useDispatch();
	const dataUser = useSelector(
		(state) => state.summaryService.singleUserService
	);
	const { id } = useParams();

	moment.locale("it");
	const formatDate = (date) => {
		const formattedDate = moment(date).format("DD MMMM YYYY");
		console.log(formattedDate);
		const spliced = formattedDate.split(" ");
		const month = spliced[1];
		spliced[1] = month.charAt(0).toUpperCase() + month.slice(1);
		return spliced.join(" ");
	};

	useEffect(() => {
		dispatch(getConsultantServiceActionByIdUserAction(id));
	}, [dispatch, id]);
	const navigate = useNavigate();

	return (
		<>
			<Row>
				<Col sm='12'>
					<HeaderPlatform
						title={
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "baseline",
									gap: 10,
									width: "100%",
								}}
							>
								<ReactSVG
									src={remove}
									onClick={() => navigate("/clients")}
								/>{" "}
								<p
									style={{
										fontSize: 24,
										fontWeight: 600,
										margin: 0,
									}}
								>
									Informazioni del profilo
								</p>
							</div>
						}
					/>

					<div
						style={{
							padding: 50,
						}}
					>
						<Avatar
							size={200}
							style={{ marginBottom: 30 }}
						/>
						<H1 style={{ marginTop: 30 }}>
							{dataUser?.name} {dataUser?.surname}
						</H1>
						<div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
							<P5 style={{ marginTop: 10 }}>
								{dataUser?.gender === "male" ? "Uomo" : "Donna"}
							</P5>
							<P5 style={{ marginTop: 10 }}>{dataUser?.age} anni</P5>
						</div>
						<H5 style={{ marginTop: 10 }}>Dettagli</H5>
						<div style={{ display: "flex", flexDirection: "row", gap: 50 }}>
							<P6>Primo contatto</P6>{" "}
							<span>{formatDate(dataUser?.primo_contatto)}</span>
						</div>
						<div style={{ display: "flex", flexDirection: "row", gap: 50 }}>
							<P6>Ultimo contatto</P6>
							<span>{formatDate(dataUser?.ultimo_contatto)}</span>
						</div>
						<H5 style={{ marginTop: 10 }}>Servizi richiesti</H5>
						{dataUser?.servizi?.length > 0 ? (
							<div>
								{dataUser?.servizi?.map((item, index) => (
									<div
										key={index}
										style={{ display: "flex", flexDirection: "row", gap: 10 }}
									>
										<ReactSVG src={thikicon} />

										<P6>{item.short_name_it}</P6>

										<div
											style={{
												display: "flex",
												flexDirection: "row",
												gap: 10,
												alignItems: "baseline",
											}}
										>
											<div
												style={{
													display: "flex",
													gap: 5,
													alignItems: "baseline",
												}}
											>
												<P7 style={{ margin: 0, color: "#477673" }}>
													{item?.count} {item.count === 1 ? "Volta" : "Volte"}
												</P7>
											</div>
										</div>
									</div>
								))}
							</div>
						) : (
							<P6>Non ci sono servizi attivi</P6>
						)}
					</div>
				</Col>
			</Row>
		</>
	);
};

export default AvailableSlot;
