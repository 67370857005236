import React, { useEffect, useRef, useState } from "react";
import P2 from "../../CommonElements/Headings/P2Element";
import P6 from "../../CommonElements/Headings/P6Element";
import P7 from "../../CommonElements/Headings/P7Element";
import { Card, CardBody, Col, Row } from "reactstrap";
import ChatList from "./ChatList";
import ChatData from "./ChatData";
import { Avatar, Button, Form, Grid, Input } from "antd";
import { useSelector, useDispatch } from "react-redux";
import * as chatSlices from "../../features/chat/chatSlice";
import attach from "../../assets/images/icon/attach.svg";
import send from "../../assets/images/icon/send.svg";
import archive from "../../assets/images/icon/archive.svg";
import unarchive from "../../assets/images/icon/unarchive.svg";

import { ReactSVG } from "react-svg";
import {
	getChatListAction,
	getChatByIdAction,
	createMessageAction,
} from "../../features/chat/chatSlice";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import HeaderPlatform from "../../CommonElements/HeaderPlatform/index";

const Step3 = () => {
	const { id } = useParams();
	const userData = useSelector((state) => state.user.data);
	const dispatch = useDispatch();
	const selectedChat = useSelector(chatSlices.selectSelectedChat || id);
	const chatData = useSelector(chatSlices.selectChatUser);
	const [formMessage] = Form.useForm();
	const chatList = useSelector((state) => state.chat.chatList);
	const [selectedFile, setSelectedFile] = useState(null);
	const [fileBase64, setFileBase64] = useState(null);
	const showArchive = useSelector((state) => state.chat.showArchive);
	const { useBreakpoint } = Grid;
	const breakpoint = useBreakpoint();
	const navigate = useNavigate();

	const getInitials = (name) => {
		const nameArray = name.split(" ");
		const initials = nameArray.map((word) => word.charAt(0).toUpperCase());
		return initials.join("");
	};

	useEffect(() => {
		dispatch(getChatListAction());
		/* if (!userData?.cod_consultant) return navigate("/home"); */
	}, []);

	const handleArchiveChat = () => {
		dispatch(chatSlices.setShowArchive(!showArchive));
	};

	useEffect(() => {
		const fetchChatData = () => {
			if (selectedChat) {
				dispatch(getChatByIdAction(selectedChat));
			}
		};

		// Avvio del polling
		const intervalId = setInterval(fetchChatData, 5000);

		// Chiamata iniziale per il caricamento immediato
		fetchChatData();

		// Pulizia del polling quando il componente si smonta o `selectedChat` cambia
		return () => clearInterval(intervalId);
	}, [dispatch, selectedChat]);

	const editArchiveChatCallback = () => {
		if (selectedChat) dispatch(chatSlices.editArchiveChatAction(selectedChat));
	};

	const [headerSelected, setHeaderSelected] = useState(null);

	const handleBack = () => {
		if (!breakpoint.lg) {
			dispatch(chatSlices.setSelectedChat(null));
		}
	};

	useEffect(() => {
		if (id) {
			const result = chatList?.filter((el) => el?.id_chat === id)?.[0];
			setHeaderSelected(result);
			console.log(result);
			if (result) dispatch(chatSlices.setSelectedChat(id));
		}
	}, [id]);

	useEffect(() => {
		if (!!selectedChat) {
			dispatch(getChatByIdAction(selectedChat));
		}
		const result = chatList.find((chat) => chat.id_chat === selectedChat);
		setHeaderSelected(result);
	}, [selectedChat]);

	const messagesEndRef = useRef(null);

	function setActiveChatUser(chat) {
		dispatch(chatSlices.setSelectedChat(chat));
	}

	const fileToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	};

	const sendMessage = async () => {
		const message = formMessage.getFieldValue("message");
		if (message === "" && !selectedFile) return;

		let messageContent = {
			message,
			cod_chat: selectedChat,
			type_message: selectedFile?.type,
			cod_receiver: chatList.find((chat) => chat.id_chat === selectedChat)
				?.cod_consultant,
			id: selectedChat,
		};

		if (selectedFile) {
			const base64 = await fileToBase64(selectedFile);
			messageContent = {
				...messageContent,
				fileBase64: base64,
				fileType: selectedFile.type,
			};
		}

		dispatch(createMessageAction(messageContent));

		formMessage.resetFields();
		setSelectedFile(null);
		setFileBase64(null);
	};

	const handleAttachClick = () => {
		document.getElementById("fileInput").click();
	};

	const handleFileChange = async (event) => {
		const file = event.target.files[0];
		if (file) {
			const fileType = file.type;
			if (
				![
					"image/jpeg",
					"image/png",
					"image/gif",
					"video/mp4",
					"application/pdf",
				].includes(fileType)
			) {
				alert(
					"Tipo di file non consentito. Seleziona un'immagine, un video o un PDF."
				);
				return;
			}

			setSelectedFile(file);
			const base64 = await fileToBase64(file);
			setFileBase64(base64);
		}
	};

	return (
		<Card style={{ padding: 0, margin: 0 }}>
			<CardBody style={{ padding: 0 }}>
				<Row>
					{/* Verifica il breakpoint per mostrare la lista delle chat su desktop o mobile */}
					{breakpoint.lg || selectedChat === null ? (
						<Col
							sm='12'
							lg='4'
							style={{ height: "90%", padding: 0 }}
						>
							<div
								style={{
									borderBottom: "1px solid #000",
									height: breakpoint.lg ? 60 : "auto",
									display: "flex",
									alignItems: "center",
								}}
							>
								{breakpoint.lg ? (
									<p
										style={{
											fontSize: 24,
											fontWeight: 600,
											marginTop: 32,
											marginBottom: 32,
											marginLeft: 36,
										}}
									>
										{showArchive ? "Chat Archiviate" : "Chat"}
									</p>
								) : (
									<div style={{ width: "100%" }}>
										<HeaderPlatform
											title={showArchive ? "Chat Archiviate" : "Chat"}
										/>
									</div>
								)}
							</div>

							<div
								style={{
									paddingLeft: 32,
									paddingRight: 32,
									paddingTop: 32,
									height: "85vh",
									overflowY: "scroll",
									display: "flex",
									gap: 30,
									flexDirection: "column",
								}}
							>
								{chatList?.map((chat) => (
									<ChatList
										key={chat.id}
										chat={chat}
										onClick={() => setActiveChatUser(chat?.id_chat)}
									/>
								))}
							</div>
						</Col>
					) : (
						<>
							<HeaderPlatform CustomTitle={() => <></>} />
						</>
					)}

					<Col
						md='12'
						lg='8'
						style={{
							borderLeft: "1px solid #000",
							height: selectedChat === null && !breakpoint.lg ? "0" : "100vh",
							padding: 0,
						}}
					>
						{selectedChat === null ? (
							<div
								style={{
									height: "100%",
									display: breakpoint.lg ? "flex" : "none",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								{chatList?.length > 0 ? (
									<P2>Seleziona una chat per visualizzarla qui</P2>
								) : (
									<P2>Non ci sono chat disponibili</P2>
								)}
							</div>
						) : (
							<>
								<div
									className='d-flex justify-content-flex-start align-items-center'
									style={{
										height: 50,
										paddingLeft: 20,
										display: selectedChat === "-1" ? "none" : "flex",
									}}
								>
									<ArrowLeft
										style={{
											cursor: "pointer",
											width: 30,
											display: breakpoint.lg ? "none" : "block",
										}}
										onClick={handleBack}
									/>

									<Avatar
										size={40}
										style={{
											marginRight: 10,
											display: selectedChat === "-1" ? "none" : "flex",
										}}
									>
										{getInitials(
											headerSelected?.name + " " + headerSelected?.surname
										)}
									</Avatar>

									<div
										style={{
											justifyContent: "space-between",
											width: breakpoint?.lg ? "80%" : "70%",
											display: "flex",
											flexDirection: "column",
											flexWrap: "wrap",
										}}
									>
										<P2 style={{ marginBottom: 0 }}>{headerSelected?.name}</P2>
										<P7>{headerSelected?.description}</P7>
									</div>
									{userData?.cod_type === "2" && selectedChat !== "-1" && (
										<Button
											onClick={editArchiveChatCallback}
											style={{
												backgroundColor: "#fff",
												border: "none",
												color: "#000",
												marginRight: 40,
											}}
											icon={
												<ReactSVG src={showArchive ? archive : unarchive} />
											}
										/>
									)}
								</div>
								<hr style={{ borderBottom: "1px solid #ddd", marginTop: 9 }} />
								<div
									style={{
										marginTop: 20,
										height: "calc(100vh - 120px)",
										overflowY: "auto",
										padding: "24px",
										marginBottom: 0,
									}}
								>
									<ChatData />
									<div ref={messagesEndRef} />
								</div>

								<Form
									form={formMessage}
									style={{
										position: breakpoint.lg ? "absolute" : "fixed",
										bottom: 0,
										left: 0,
										right: 0,
										width: "100%",
										borderTop: "1px solid #ddd",
										padding: "10px 20px",
										backgroundColor: "#fff",
									}}
									onFinish={sendMessage}
								>
									<Col style={{ display: "flex", alignItems: "center" }}>
										<Form.Item
											name='message'
											style={{ flexGrow: 1, marginBottom: 0 }}
										>
											<div style={{ display: "flex", alignItems: "center" }}>
												{fileBase64 &&
													selectedFile.type.startsWith("image/") && (
														<div style={{ marginRight: 10 }}>
															<img
																src={fileBase64}
																alt='preview'
																style={{
																	width: 50,
																	height: 50,
																	borderRadius: 4,
																	objectFit: "cover",
																	border: "1px solid #ddd",
																}}
															/>
														</div>
													)}
												<Input
													placeholder='Scrivi un messaggio'
													style={{
														width: "100%",
														height: 45,
														borderRadius: 20,
														paddingLeft: 15,
														paddingRight: 15,
														border: "1px solid #ddd",
														backgroundColor: "#f9f9f9",
													}}
												/>
											</div>
										</Form.Item>

										<div
											style={{
												display: "flex",
												alignItems: "center",
												gap: 10,
												marginLeft: 10,
											}}
										>
											<input
												type='file'
												id='fileInput'
												style={{ display: "none" }}
												onChange={handleFileChange}
											/>
											<ReactSVG
												src={attach}
												onClick={handleAttachClick}
												style={{ cursor: "pointer", width: 24, height: 24 }}
											/>
											<Button
												onClick={sendMessage}
												disabled={
													fileBase64 === null &&
													formMessage.getFieldValue("message") === ""
												}
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													width: 45,
													height: 45,
													borderRadius: "50%",
													border: "none",
												}}
											>
												<ReactSVG
													src={send}
													style={{ width: 20, height: 20, fill: "#fff" }}
												/>
											</Button>
										</div>
									</Col>
								</Form>
							</>
						)}
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};

export default Step3;
