import React from "react";
import Appointment from "../Components/Appointment/AppointmentUser";

import AppointmentConsultant from "../Components/Appointment/AppointmentConsultant";
import { useSelector } from "react-redux";
const SettingsPage = () => {
	const userType = useSelector((state) => state.user.type);

	return userType === "consultant" ? (
		<AppointmentConsultant />
	) : (
		<Appointment />
	);
};

export default SettingsPage;
