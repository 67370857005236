import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Callback from "../Auth/Callback";
import Loader from "../Layout/Loader";
import { authRoutes } from "./AuthRoutes";
import LayoutRoutes from "../Route/LayoutRoutes";
import Signin from "../Auth/Signin";
import PrivateRoute from "./PrivateRoute";
import Survey from "../pages/survey/index";
import ConfirmUser from "../pages/survey/ConfirmUser";
import { useDispatch, useSelector } from "react-redux";
import { tokenLoginAction } from "../features/user/userSlices";
import AppleVerification from "../Components/Common/Verification";
const Routers = () => {
	const [currentUser, setCurrentUser] = useState(false);
	const [authenticated, setAuthenticated] = useState(false);
	const taoToken = localStorage.getItem("taoToken");
	const dispatch = useDispatch();
	const dataProfile = useSelector((state) => state.user.data);

	useEffect(() => {
		console.log("ci passo");
		if (taoToken) {
			dispatch(tokenLoginAction());
		}
	}, []);

	useEffect(() => {
		let abortController = new AbortController();
		setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
		console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
		console.disableYellowBox = true;
		return () => {
			abortController.abort();
		};
	}, []);

	return (
		<BrowserRouter basename={"/"}>
			<Suspense fallback={<Loader />}>
				<Routes>
					<Route
						path={"/"}
						element={<PrivateRoute />}
					>
						{authenticated ? (
							<>
								<Route
									exact
									path={`/`}
									element={<Navigate to={`${process.env.PUBLIC_URL}/home`} />}
								/>
							</>
						) : (
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/test/survey`}
								element={<Survey />}
							/>
						)}
						<Route
							path={`/*`}
							element={<LayoutRoutes />}
						/>
					</Route>
					<Route
						path={`${process.env.PUBLIC_URL}/callback`}
						element={<Callback />}
					/>
					<Route
						path='/.well-known/apple-developer-merchantid-domain-association'
						element={<AppleVerification />}
					/>

					<Route
						exact
						path={`${process.env.PUBLIC_URL}/survey`}
						element={<Survey />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL}/verify/:token`}
						element={<ConfirmUser />}
					/>

					<Route
						exact
						path={`${process.env.PUBLIC_URL}/login`}
						element={<Signin />}
					/>
					{authRoutes.map(({ path, Component }, i) => (
						<Route
							path={path}
							element={<Component />}
							key={i}
						/>
					))}
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
};

export default Routers;
