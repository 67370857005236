import { createSlice } from "@reduxjs/toolkit";

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	createMessage,
	getChatById,
	getListChat,
	editArchiveChat,
} from "../../api/chat";

export const getChatListAction = createAsyncThunk(
	"chat/getChatListAction",
	async (_, { rejectWithValue }) => {
		try {
			const response = await getListChat(); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const editArchiveChatAction = createAsyncThunk(
	"chat/editArchiveChatAction",
	async (id, { rejectWithValue }) => {
		try {
			const response = await editArchiveChat(id); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getChatByIdAction = createAsyncThunk(
	"chat/getChatByIdAction",
	async (id, { rejectWithValue }) => {
		try {
			const response = await getChatById(id); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const createMessageAction = createAsyncThunk(
	"chat/createMessageAction",
	async (body, { rejectWithValue }) => {
		try {
			console.log(body);
			const response = await createMessage(body); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const chatSlice = createSlice({
	name: "chat",
	initialState: {
		chatUser: [],
		showArchive: false,
		chatData: [],
		selectedChat: null,
		chatList: [],
		chatMessages: [],
	},
	reducers: {
		setChatUser: (state, action) => {
			state.chatUser = action.payload;
		},
		sendMessage: (state, action) => {
			state.chatData = [...state.chatData, action.payload];
		},
		setShowArchive: (state, action) => {
			state.showArchive = action.payload;
		},
		decrement: (state) => {
			state.value -= 1;
		},
		setSelectedChat: (state, action) => {
			state.selectedChat = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getChatListAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getChatListAction.fulfilled, (state, action) => {
				console.log(action.payload);
				state.chatList = action.payload;
			})
			.addCase(getChatListAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
			})
			.addCase(getChatByIdAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getChatByIdAction.fulfilled, (state, action) => {
				console.log(action.payload);
				state.chatMessages = action.payload;
			})
			.addCase(getChatByIdAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
			})
			.addCase(createMessageAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(createMessageAction.fulfilled, (state, action) => {
				state.chatMessages = action.payload;
			})
			.addCase(createMessageAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
			})

			.addCase(editArchiveChatAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(editArchiveChatAction.fulfilled, (state, action) => {
				console.log(action.payload);
				state.chatList = action.payload;
			})
			.addCase(editArchiveChatAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
			});
	},
});

export const { setChatUser, setSelectedChat, sendMessage, setShowArchive } =
	chatSlice.actions;
export const selectChatUser = (state) => state.chat.chatUser;
export const selectChatData = (state) => state.chat.chatMessages;
export const selectSelectedChat = (state) => state.chat.selectedChat;
export default chatSlice.reducer;
