import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Button, Checkbox, DatePicker, Form, Input, Select } from "antd";
import P2 from "../../../CommonElements/Headings/P2Element";
import P5 from "../../../CommonElements/Headings/P5Element";
import SelectProvince from "../../../Components/Common/Component/ProviceSelect";
import SelectState from "../../../Components/Common/Component/StateSelect";
import * as invoiceSlice from "../../../features/profile/profileSlices";
import { useDispatch, useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import clickboardThik from "../../../assets/images/clipboard-tick.svg";
import clicpboardClose from "../../../assets/images/clipboard-close.svg";

const Invoice = () => {
	const [form] = Form.useForm();
	const [isLocked, setIsLocked] = useState(false);
	const userProfile = useSelector((state) => state.profile.data);
	const dispatch = useDispatch();
	const [provinceShow, setProvinceShow] = useState(false);
	const invoiceData = useSelector((state) => state.profile.invoiceData);
	useEffect(() => {
		if (!invoiceData?.name) {
			dispatch(invoiceSlice.getProfileInvoiceAction());
		}
	}, []);

	useEffect(() => {
		form.setFieldsValue(invoiceData);
		setIsLocked(invoiceData.same_data);
	}, [invoiceData]);

	const handleSubmit = () => {
		const values = form.getFieldsValue();
		dispatch(invoiceSlice.setInvoiceData(values));
		dispatch(invoiceSlice.updateUserDataInvoiceAction(values));
		console.log(values);
	};

	return (
		<Form
			form={form}
			className='mt-3 px-2'
			initialValues={invoiceData}
			layout='vertical' // Imposta il layout verticale per avere la label sopra
			onFieldsChange={(changedFields, allFields) => {
				if (changedFields[0].name[0] === "same_data") {
					setIsLocked(changedFields[0].value);
					setProvinceShow(userProfile.state_address === "IT");
					if (changedFields[0].value) {
						form.setFieldsValue({
							...form.getFieldsValue(),

							name: userProfile.name,
							surname: userProfile.surname,
							address: userProfile.address,
							cap: userProfile.cap_address,
							city: userProfile.city_address,
							province: userProfile.province_address,
							state: userProfile.state_address,
							cf: userProfile.cf,
							phone: userProfile.phone,
						});
					}
				}
			}}
		>
			<Row>
				<Col md='12'>
					<P2>
						Compila le informazioni sottostanti nel caso tu voglia ricevere la
						fattura via mail
					</P2>
				</Col>
			</Row>
			<Row
				className='mt-3'
				s
			>
				<Col md='12'>
					<Form.Item
						name='same_data'
						valuePropName='checked'
					>
						<Checkbox
							className='agree'
							onChange={(e) => {
								form.setFieldsValue({ same_data: e.target.checked });
								setIsLocked(e.target.checked); // Aggiorna lo stato di isLocked basato sul valore della checkbox
							}}
						>
							<P5 style={{ margin: 0 }}>
								I miei dati di fatturazione sono gli stessi di quelli della
								residenza
							</P5>
						</Checkbox>
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<Col md='4'>
					<Form.Item
						name='name'
						label='Nome'
					>
						<Input
							disabled={isLocked}
							required
							placeholder='Nome'
							style={{ height: 50 }}
						/>
					</Form.Item>
				</Col>
				<Col md='4'>
					<Form.Item
						name='surname'
						label='Cognome'
					>
						<Input
							disabled={isLocked}
							placeholder='Cognome'
							style={{ height: 50 }}
						/>
					</Form.Item>
				</Col>
			</Row>

			<Row>
				<Col md='4'>
					<Form.Item
						name='address'
						label='Indirizzo'
					>
						<Input
							disabled={isLocked}
							placeholder='Indirizzo'
							style={{ height: 50 }}
						/>
					</Form.Item>
				</Col>
				<Col md='2'>
					<Form.Item
						name='cap'
						label='CAP'
					>
						<Input
							disabled={isLocked}
							type='number'
							placeholder='CAP'
							style={{ height: 50 }}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<Col md='4'>
					<Form.Item
						name='city'
						label='Città'
					>
						<Input
							disabled={isLocked}
							placeholder='Città'
							style={{ height: 50 }}
						/>
					</Form.Item>
				</Col>
				<Col md='2'>
					<Form.Item
						name='state'
						label='Stato'
					>
						<SelectState
							disabled={isLocked}
							value={() => form.getFieldValue("state")}
							onChange={(val) => {
								setProvinceShow(val === "IT");
								form.setFieldsValue({ state: val });
							}}
						/>
					</Form.Item>
				</Col>
				{provinceShow && (
					<Col md='2'>
						<Form.Item
							name='province'
							label='Provincia'
						>
							<SelectProvince
								disabled={isLocked}
								value={() => form.getFieldValue("province")}
								onChange={(val) => {
									form.setFieldsValue({ province: val });
								}}
							/>
						</Form.Item>
					</Col>
				)}
			</Row>
			<Row>
				<Col md='4'>
					<Form.Item
						name='cf'
						label='Codice fiscale'
					>
						<Input
							required
							type='text'
							placeholder='Codice Fiscale'
							style={{ height: 50 }}
						/>
					</Form.Item>
				</Col>
				<Col md='4'>
					<Form.Item
						name='phone'
						label='Telefono'
					>
						<Input
							disabled={isLocked}
							type='text'
							placeholder='Scrivi il tuo numero di telefono'
							style={{ height: 50, width: "80%" }}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<div
					className='d-flex justify-content-end'
					style={{ width: "100%", gap: 40 }}
				>
					<Button
						style={{
							backgroundColor: "#fff",
							color: "#477673",
							border: "1px solid #8E6B00",
							borderRadius: 10,
							height: 50,
						}}
						icon={<ReactSVG src={clicpboardClose} />}
					>
						Annulla
					</Button>

					<Button
						style={{
							backgroundColor: "#477673",
							color: "#fff",
							height: 50,
							borderRadius: 10,
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
						}}
						onClick={handleSubmit}
					>
						Conferma <ReactSVG src={clickboardThik} />
					</Button>
				</div>
			</Row>
		</Form>
	);
};

export default Invoice;
