import React from "react";
import { Button, Col, Row } from "reactstrap";
import { DatePicker, Form, Input, Select } from "antd";
import P2 from "../../../CommonElements/Headings/P2Element";
import H4 from "../../../CommonElements/Headings/H4Element";
import { Edit, File } from "react-feather";
import { useDispatch } from "react-redux";
import { updatePasswordAction } from "../../../features/profile/profileSlices";
const General = () => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const passwordRegex =
		/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

	const handleSubmit = () => {
		if (
			!form.getFieldValue("currentPassword") ||
			!form.getFieldValue("newPassword")
		) {
			return alert("Inserire la password attuale e la nuova password");
		}
		if (!passwordRegex.test(form.getFieldValue("newPassword"))) {
			return alert(
				"La nuova password deve contenere almeno 8 caratteri, lettere, numeri e simboli speciali"
			);
		} else {
			dispatch(updatePasswordAction(form.getFieldsValue()));
		}
		console.log(form.getFieldsValue());
	};

	return (
		<Form
			form={form}
			onFinish={handleSubmit}
			className='mt-3 px-2 w-100'
			layout='vertical' // Imposta il layout verticale per avere la label sopra
		>
			<h6>Modifica password</h6>
			<Row className='mt-5'>
				<Col md='4'>
					<Form.Item
						name='currentPassword'
						label='Vecchia password'
					>
						<Input
							type='password'
							required
							placeholder='Password corrente'
							style={{ height: 50 }}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<Col md='4'>
					<Form.Item
						name='newPassword'
						label='Nuova password'
					>
						<Input
							type='password'
							placeholder='Nuova password'
							style={{ height: 50 }}
						/>
					</Form.Item>
				</Col>
				<Row>
					<Col sm='12'>
						La password deve contenere almeno 8 caratteri, di cui un numero e un
						carattere speciale (&, $, @, ...)
					</Col>
				</Row>
				<Row>
					<Col sm='4'>
						<Button
							color='primary'
							className='mt-3'
							style={{ height: 50 }}
						>
							<div style={{ display: "flex", alignItems: "center" }}>
								<span style={{ marginRight: 10 }}>Richiedi modifica</span>
								<Edit />
							</div>
						</Button>
					</Col>
				</Row>
			</Row>
		</Form>
	);
};

export default General;
