import React, { useState } from "react";
import { Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { ReactSVG } from "react-svg";
import logo from "../../../../assets/images/survey/logo.svg";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
const HeaderMobile = ({ changeLanguage }) => {
	const { t } = useTranslation();
	const [drawerVisible, setDrawerVisible] = useState(false);

	const toggleDrawer = () => {
		setDrawerVisible(!drawerVisible);
	};

	return (
		<div className='header-mobile'>
			<ReactSVG
				src={logo}
				className='header-logo-mobile'
			/>
			<Button
				type='text'
				icon={<MenuOutlined style={{ fontSize: "24px", color: "#fff" }} />}
				onClick={toggleDrawer}
				className='menu-button'
			/>
			<Drawer
				style={{ height: "30vh" }}
				title={
					<div
						style={{
							marginRight: "20px",
							display: "flex",
							alignItems: "center",
							gap: "20px",
							color: "#003f4a",
						}}
					>
						{t("changeLanguage")}
						<Select
							className='custom-select-mobile'
							defaultValue='it'
							value={localStorage.getItem("surveyLanguage")}
							suffixIcon={<i className='fa fa-chevron-down custom-icon'></i>}
							onChange={(value) => changeLanguage(value)}
						>
							<Select.Option value='it'>ITA</Select.Option>
							<Select.Option value='ch'>CHI</Select.Option>
						</Select>
					</div>
				}
				placement='top'
				closable
				onClose={toggleDrawer}
				visible={drawerVisible}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						marginBottom: "20px",
						alignContent: "center",
						alignItems: "center",
						gap: "20px",
					}}
				>
					<p
						className='header-text'
						style={{ color: "#003f4a" }}
					>
						{t("header.question")}
					</p>
					<Button className='access-button'>{t("header.login")}</Button>
				</div>
			</Drawer>
		</div>
	);
};

export default HeaderMobile;
