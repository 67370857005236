import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
	doLogin,
	insertNewToken,
	loginWithToken,
	verifyUseToken,
} from "../../api/auth";
import SweetAlert from "sweetalert2";
import { getMe } from "../../api/user";
import { act } from "react";
import { useLocation } from "react-router-dom";
export const loginAction = createAsyncThunk(
	"user/login",
	async (body, { rejectWithValue }) => {
		try {
			const response = await doLogin(body); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const tokenLoginAction = createAsyncThunk(
	"user/tokenLogin",
	async (_, { rejectWithValue }) => {
		try {
			const response = await loginWithToken(); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

/* export const loginUser = createAsyncThunk(
	"user/login",
	async (credentials, { rejectWithValue }) => {
		try {
			console.log("credentials", credentials);
			const response = await doLogin(credentials);
			console.log(response.data);
			return response.data; // Questa parte va in fulfilled
		} catch (error) {
			// Gestisci correttamente l'errore 401 o altri errori
			if (error.response && error.response.status === 401) {
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
); */

export const verifyUserAction = createAsyncThunk(
	"user/verifyUser",
	async (token, { rejectWithValue }) => {
		try {
			const response = await verifyUseToken(token);
			console.log("API Response:", response); // Log per controllare il formato
			// Ritorna il messaggio che ti serve
			return response.data.message;
		} catch (error) {
			if (error.response && error.response.status === 401) {
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const insertNewTokenAction = createAsyncThunk(
	"user/insertNewToken",
	async (token, { rejectWithValue }) => {
		try {
			const response = await insertNewToken(token);
			console.log("API Response:", response); // Log per controllare il formato
			// Ritorna il messaggio che ti serve
			return response.data.message;
		} catch (error) {
			if (error.response && error.response.status === 401) {
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

/* export const userSlices = createSlice({
	name: "user",
	initialState: {
		type: "1",
		data: {},
		loading: false,
		error: null,
		profile: {},
		verify: false,
		successMessage: null,
	},
	reducers: {
		setUser: (state) => {
			state.type = "user";
		},
		extraReducers: (builder) => {
		
				.addCase(verifyUserAction.pending, (state) => {
					state.loading = true;
				})
				.addCase(verifyUserAction.fulfilled, (state, action) => {
					state.loading = false;
					state.verify = true; 
				})
				.addCase(verifyUserAction.rejected, (state, action) => {
					state.loading = false;
					state.verify = false;
					state.error = action.payload || "Errore sconosciuto";
				})

				.addCase(insertNewTokenAction.pending, (state) => {
					state.loading = true;
					state.send = false;
				})
				.addCase(insertNewTokenAction.fulfilled, (state, action) => {
					state.loading = false;
					state.send = true; 
				})
				.addCase(insertNewTokenAction.rejected, (state, action) => {
					state.loading = false;
					state.send = false;
					state.error = action.payload || "Errore sconosciuto";
				});
		},
	},
}); */

export const userSlices = createSlice({
	name: "user",
	initialState: {
		type: "1",
		data: {},
		loading: false,
		error: null,
		profile: {},
		verify: false,
		successMessage: null,
		consultantData: { actionArea: [], presentation: {}, profile: {} },
	},
	reducers: {
		setAvailabelSlotsSession: (state, action) => {
			state.sessionData = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(loginAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(loginAction.fulfilled, (state, action) => {
				state.data = action.payload.data;
				localStorage.setItem("taoToken", action.payload.token);
				state.consultantData = action.payload.consultant;

				state.loading = false;
				state.type =
					action.payload.data?.cod_type === "1" ? "user" : "consultant";
			})
			.addCase(loginAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				SweetAlert.fire({
					icon: "error",
					text: "Credenziali non corrette",
				});
			})

			.addCase(tokenLoginAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(tokenLoginAction.fulfilled, (state, action) => {
				state.data = action.payload.data;
				state.loading = false;
				console.log(action.payload.data);
				state.consultantData = action.payload.consultant;
				/* 	localStorage.setItem("taoToken", action.payload.token);
				localStorage.setItem("authenticated", true); */
				state.type =
					action.payload.data?.cod_type === "1" ? "user" : "consultant";
			})
			.addCase(tokenLoginAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				localStorage.removeItem("taoToken");
				window.location.href = "/login";

				state.error = action.payload || "Errore sconosciuto";
			})

			.addCase(verifyUserAction.pending, (state) => {
				state.loading = true;
			})
			.addCase(verifyUserAction.fulfilled, (state, action) => {
				state.loading = false;
				state.verify = true;
			})
			.addCase(verifyUserAction.rejected, (state, action) => {
				state.loading = false;
				state.verify = false;
				state.error = action.payload || "Errore sconosciuto";
			})

			.addCase(insertNewTokenAction.pending, (state) => {
				state.loading = true;
				state.send = false;
			})
			.addCase(insertNewTokenAction.fulfilled, (state, action) => {
				state.loading = false;
				state.send = true;
			})
			.addCase(insertNewTokenAction.rejected, (state, action) => {
				state.loading = false;
				state.send = false;
				state.error = action.payload || "Errore sconosciuto";
			});
	},
});

export const { setUser } = userSlices.actions;
export const selectUser = (state) => state.user.type;
export const selectIsVerify = (state) => state.user.verify;
export const selectUserData = (state) => state.user.data;

export const selectUserLoading = (state) => state.user.loading;
export const selectUserError = (state) => state.user.error;
export default userSlices.reducer;
