import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getServicesData } from "../../api/service";
import {
	getMe,
	updateMe,
	updatePassword,
	updateUserDataInvoice,
	getInvoiceData,
} from "../../api/user";
import { getMineAppointment } from "../../api/appointment";
import SweetAlert from "sweetalert2";
export const getProfile = createAsyncThunk(
	"profile/getMe",
	async (_, { rejectWithValue }) => {
		try {
			const response = await getMe(); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getProfileInvoiceAction = createAsyncThunk(
	"profile/getProfileInvoiceAction",
	async (_, { rejectWithValue }) => {
		try {
			const response = await getInvoiceData(); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getMineAppointmentAction = createAsyncThunk(
	"appointment/getMineAppointment",
	async (_, { rejectWithValue }) => {
		try {
			const response = await getMineAppointment(); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const updatePasswordAction = createAsyncThunk(
	"profile/updatePassword",
	async (body, { rejectWithValue }) => {
		try {
			const response = await updatePassword(body); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const updateProfile = createAsyncThunk(
	"profile/updateMe",
	async (body, { rejectWithValue }) => {
		try {
			const response = await updateMe(body); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const updateUserDataInvoiceAction = createAsyncThunk(
	"profile/updateUserDataInvoiceAction",
	async (body, { rejectWithValue }) => {
		try {
			const response = await updateUserDataInvoice(body); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getServicesAction = createAsyncThunk(
	"service/getServices",
	async (_, { rejectWithValue }) => {
		try {
			const response = await getServicesData(); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const profileSlice = createSlice({
	name: "profile",
	initialState: {
		data: {},
		loading: false,
		singleService: {},
		paymentMethod: [],
		stepService: [],
		consultant: {},
		invoiceData: {},
		appointment: [],
		services: [],
		error: null,
	},
	reducers: {
		setInvoiceData: (state, action) => {
			state.invoiceData = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getProfile.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getProfile.fulfilled, (state, action) => {
				state.data = action.payload.user;
				state.loading = false;
				state.error = null;
			})
			.addCase(getProfile.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
			})
			.addCase(updateProfile.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(updateProfile.fulfilled, (state, action) => {
				state.data = action.payload.user;
				state.loading = false;
				state.error = null;
				SweetAlert.fire({
					icon: "success",
					text: "Profilo aggiornato con successo",
				});
				state.loading = false;
				state.error = null;
			})
			.addCase(updateProfile.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				SweetAlert.fire({
					icon: "error",
					text: "Generic Error",
				});
			})
			.addCase(getMineAppointmentAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getMineAppointmentAction.fulfilled, (state, action) => {
				state.appointment = action.payload.data;
			})
			.addCase(getMineAppointmentAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				SweetAlert.fire({
					icon: "error",
					text: "Generic Error",
				});
			})

			.addCase(updatePasswordAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(updatePasswordAction.fulfilled, (state, action) => {
				SweetAlert.fire({
					icon: "success",
					text: "Password cambiata con successo",
				});
				state.loading = false;
				state.error = null;
			})
			.addCase(updatePasswordAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				SweetAlert.fire({
					icon: "error",
					text: "Generic Error",
				});
			})

			.addCase(updateUserDataInvoiceAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(updateUserDataInvoiceAction.fulfilled, (state, action) => {
				SweetAlert.fire({
					icon: "success",
					text: "Dati aggiornati con successo",
				});
				state.loading = false;
				state.error = null;
			})
			.addCase(updateUserDataInvoiceAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				SweetAlert.fire({
					icon: "error",
					text: "Generic Error",
				});
			})

			.addCase(getProfileInvoiceAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getProfileInvoiceAction.fulfilled, (state, action) => {
				state.invoiceData = action.payload;
				state.loading = false;
				state.error = null;
			})
			.addCase(getProfileInvoiceAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				SweetAlert.fire({
					icon: "error",
					text: "Generic Error",
				});
			});
	},
});
export const { setInvoiceData } = profileSlice.actions;
/* export const { setUser, saveAnswer, saveUserData, setChangeEmail } =
	surveySlices.actions; */
export const selectServiceData = (state) => state.services;
export const selectSingleServiceData = (state) => state.singleService;
export const selectServiceStep = (state) => state.stepService;

export const selectServiceLoading = (state) => state.loading;
export const selectServiceError = (state) => state.error;

export default profileSlice.reducer;
